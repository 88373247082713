import React from 'react';
import { Card, Image } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Slider from 'react-slick';
import product1 from 'assets/img/products/1.jpg';
import product12 from 'assets/img/products/1-2.jpg';
import product13 from 'assets/img/products/1-3.jpg';
import product14 from 'assets/img/products/1-4.jpg';
import product15 from 'assets/img/products/1-5.jpg';
import product16 from 'assets/img/products/1-6.jpg';

export default function ImageCarousel(props) {

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <Card>
      <FalconCardHeader title="My Pictures" />
      <Card.Body>
        <div className="position-relative h-sm-auto overflow-hidden">
          <Slider
            {...sliderSettings}
            className="slick-slider-arrow-inner slick-slider"
          >
            {props.images.map((img, index) => (
              <Image fluid rounded src={img['ImageUrl']} alt="" key={index} />
            ))}
          </Slider>
        </div>
      </Card.Body>
    </Card>
  );
}
