import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Row,
  Col,
  Carousel,
  Navbar,
  Nav,
  Container,
  Button,
  Image
} from 'react-bootstrap';
import facebook from '../../../assets/logo/Facebook.svg'
import instagram from '../../../assets/logo/Instagram.svg'
import tiktok from '../../../assets/logo/TikTok.svg'

import couple2 from '../../../assets/img/home/Mask-1.png';
import image from '../../../assets/img/home/Mask-2.png';
import logo from '../../../assets/img/illustrations/esobond-icon.png';
import couple1 from '../../../assets/img/home/Mask.png';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import './CustomCarousel.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {subscriptionType} from 'redux/actions/ProfileActions'
import { checkUserSubscribed } from 'routes/subscriptionGaud';
import { toast } from 'react-toastify';
import AppContext from 'context/Context';
const HomeScreen = props => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [marginTop, setMarginTop] = useState('40px');
  const {config: { isDark }} = useContext(AppContext);
  const carouselRef = useRef();
  const handleOnChange = selectedIndex => {
    setActiveIndex(selectedIndex);
  };





  useEffect(() => {
    getSubscription()
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setMarginTop('-100px');
    } else {
      setMarginTop('40px');
    }
  };


    const handleChatClick = () => {
      if (!checkUserSubscribed()) {
        toast.error('Please buy a subscription to access this feature.', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    };
  
    const chatLinkProps = {
      href: checkUserSubscribed() ? '/app/chat' : '#',
      style: {
        marginRight: '20px'
      },
      onClick: handleChatClick
    };

    const bondLinkProps = {
      href: checkUserSubscribed() ? '/user/bond' : '#',
      style: {
      marginRight: '20px'
      },
      onClick: handleChatClick
    };

    const compatibilityLinkProps = {
      href: checkUserSubscribed() ? '/social/re-assessment' : '#',
      style: {
      marginRight: '20px'
      },
      onClick: handleChatClick
    };

    const unAnsweredLinkProps = {
      href: checkUserSubscribed() ? '/user/un-answered-questionaire' : '#',
      style: {
      marginRight: '20px'
      },
      onClick: handleChatClick
    };


    



  const renderImages = () => {
    const images = [couple1, couple2, image];
    return images.map((image, index) => {
      const isActive = index === activeIndex;
      return (
        <img
          key={index}
          className={`carousel-item-image ${isActive ? 'active' : 'blur'}`}
          src={image}
          alt={`Image ${index + 1}`}
          style={{
            height: '70vh'
          }}
        />
      );
    });
  };

  const getSubscription = () => {
    props
      .subscriptionType()
      .then(response => {
        console.log(response?.data?.SubscriptionType)
        if (response.code === 200) {
          localStorage.setItem(
            'subscriptionType',
            response?.data?.SubscriptionType
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const navbarStyle = {
    backgroundColor: isDark?"#0b1727":'white',
    padding: '10px',
    display: 'flex',
    alignItems: 'center'
  };

  const logoStyle = {
    marginRight: 'auto',
    marginLeft: '30px'
  };

  const leftColumnStyle = {
    padding: '20px'
  };

  return (
    <div>
      <Navbar style={navbarStyle} expand="md">
        <Navbar.Brand style={logoStyle} href="/user/home">
          <Image src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center"
        >
          <div
            style={{
              marginLeft: 'auto',
              marginRight: '10%'
            }}
          >
            <Nav>
            <Nav.Link
             href="/user/bond"
             style={{
               marginRight: '20px'
             }}
                // {...bondLinkProps}
              >
                Esobonds
              </Nav.Link>
              <Nav.Link
              // href="/app/chat"
              // style={{
              //   marginRight: '20px'
              // }}
                {...chatLinkProps}
              >
                Chat
              </Nav.Link>
              <Nav.Link
              {...compatibilityLinkProps}
                // href="/social/re-assessment"
                // style={{
                //   marginRight: '20px'
                // }}
              >
                Assessment
              </Nav.Link>
              <Nav.Link
              {...unAnsweredLinkProps}
                // href="/user/un-answered-questionaire"
                // style={{
                //   marginRight: '20px'
                // }}
              >
                UnAnswered
              </Nav.Link>
              {/* <Nav.Link
              href="miscellaneous/privacy-policy"
              style={{
                marginRight: '20px'
              }}
              >
                Privacy Policy
              </Nav.Link>
              <Nav.Link
               href="TermsNCondition"
               style={{
                 marginRight: '20px'
               }}
              >
                Term & Conditions
              </Nav.Link> */}
              {/* <Nav.Link
                href="/AboutUs"
                style={{
                  marginRight: '20px'
                }}
              >
                About Us
              </Nav.Link> */}
              <Button
                href="/pricing/pricing-default"
                style={{
                  marginRight: '20px',
                  borderRadius: '8px',
                  alignContent: 'center',
                  justifyContent: 'center',
                  height: '40px'
                }}
              >
                Subscription
              </Button>

              <ProfileDropdown />
            </Nav>
          </div>
        </Navbar.Collapse>
      </Navbar>

      <Container
        fluid
        style={{
          backgroundColor: isDark?"#0b1727":'white'
        }}
      >
        <Row>
          <Col md={4} style={leftColumnStyle}>
            <div
              style={{
                marginLeft: '30px'
              }}
            >
              <p
                style={{
                  background:
                    'var(--gradient, linear-gradient(118deg, #BA59FB 6.93%, #7CB3F1 86.63%))',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}
              >
                WELCOME TO ESOBOND
              </p>

              <h1
                style={{
                  marginTop: '20px',
                  fontFamily: 'Public Sans, sans-serif',
                  fontSize: '40px',
                  fontWeight: 'bold'
                }}
              >
                Join the Esobond Movement
              </h1>
              <p
                style={{
                  marginTop: '20px',
                  fontSize: '16px',
                  color: '#9E9E9E'
                }}
              >
             Discover yourself and lead with intentionality. Committing to Esobond is a commitment to yourself!
              </p>

              <Button
                href="/user/bond"
                style={{
                  fontWeight: '500',
                  background:
                    'linear-gradient(155.23deg, #ba59fb 6.93%, #7cb3f1 86.63%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  marginTop: '20px'
                }}
              >
                See Esobonds
              </Button>

              <div style={{marginTop: "60px"}}>
              <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
        <img
          src={instagram}
          alt="Instagram"
          style={{ width: '80px', height: '80px', marginRight: '60px' }}
        />
      </a>
      <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
        <img
          src={facebook}
          alt="Facebook"
          style={{ width: '80px', height: '80px', marginRight: '60px' }}
        />
      </a>
      <a href="https://www.tiktok.com/" target="_blank" rel="noopener noreferrer">
        <img
          src={tiktok}
          alt="TikTok"
          style={{ width: '80px', height: '80px' }}
        />
      </a>
              </div>

     

              {/* <Row
                style={{
                  marginTop: '80px'
                }}
              >
                <Col>
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '30px',
                      color: 'black'
                    }}
                  >{`${10 + 'M'}+`}</span>
                  <p>User Active</p>
                </Col>
                <Col>
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '30px',
                      color: 'black'
                    }}
                  >{`${150 + 'M'}+`}</span>
                  <p>App Downloads</p>
                </Col>
              </Row> */}
            </div>
          </Col>
          <Col
            md={8}
            style={{
              padding: '0px'
            }}
          >
            <Row
              style={{
                marginLeft: '20px',
                marginTop: marginTop
              }}
            >
              <div className="custom-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  onSelect={handleOnChange}
                  interval={3000}
                  controls={false}
                  style={{
                    height: '80vh'
                  }}
                >
                  {renderImages()}
                </Carousel>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      subscriptionType
    },
    dispatch
  );
}
let HomeScreen2 = connect(mapStateToProps, mapDispatchToProps)(HomeScreen);

export default HomeScreen2;

// export default HomeScreen2;
