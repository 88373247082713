import ApiFetch from '../../apimanager/ApiFetch';
import { LIKE_UNLIKE_PROFILE, MATCHING_LIST } from '../../apimanager/ApiEndpoints';

export const getMatchingListData = (limit, pageNo, range, declineUserIds) => dispatch => {
  const parameters = {
    Limit: limit,
    PageNo: pageNo,
    Range: range,
    // DeclineUserIds: declineUserIds,
  }
  // return ApiFetch.fetchGet(MATCHING_LIST+"?Limit="+limit+"&PageNo="+pageNo+"&Range="+range, {})
  return ApiFetch.fetchGet(MATCHING_LIST + "?Limit=" + limit + "&PageNo=" + pageNo + "&DeclineUserIds=[" + declineUserIds + "]", {})
    // return ApiFetch.fetchGet(MATCHING_LIST+"?Limit="+limit+"&PageNo="+pageNo, {})
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror Matching List Action', err);
    });
};

export const likeUnlikeProfile = (userId, isLiked) => dispatch => {
  const parameters = {
    ProfileUserId: userId,
    IsLiked: isLiked
  }
  return ApiFetch.fetchPatch(LIKE_UNLIKE_PROFILE, JSON.stringify(parameters))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror Like Unlike Profile Action', err);
    });
}