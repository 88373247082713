import React, { useEffect, useState } from 'react';

import personality from '../../../assets/img/compatibility-categoty/personality.png';
import future from '../../../assets/img/compatibility-categoty/future.png';
import background from '../../../assets/img/compatibility-categoty/background.png';
import lifestyle from '../../../assets/img/compatibility-categoty/Plifestyle.png';
import { Card, Row, Col, Modal, Button, Table } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import ModalComponent from './compatibility-category-modal';
import { useParams } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getUserDetails, hitUserDetailAPI } from 'redux/actions/ProfileActions';
import { toast } from 'react-toastify';
import OverallResultModal from './overallResultModal';

const CompatibilityCategory = props => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [overallModal, setOverallModal] = useState(false);
  const [name, setName] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [userImage, setUserImage] = useState('');
  useEffect(() => {
    getUserData();
    getUserDetails(id);
  }, []);

  const [overallDetails, setOverallDetails] = useState({
    UserName: '',
    UserId: ''
  });

  const [details, setDetails] = useState({
    CategoryName: '',
    ImageType: '',
    UserName: '',
    UserId: ''
  });

  const getUserData = async () => {
    props.hitUserDetailAPI().then(data => {
      if (!data.error && data.code === 200) {
        setUserImage(data.data.ProfilePic);
      } else {
        toast.error(data.error);
      }
    });
  };

  const getUserDetails = async id => {
    props.getUserDetails(id).then(data => {
      if (!data.error && data.code === 200) {
        setName(data.data.First_Name + ' ' + data.data.Last_Name);
        setProfilePic(data.data.ProfilePic);
      } else {
        toast.error(data.error);
      }
    });
  };

  const handlePartClick = (Name, ImageType) => {
    setShowModal(true);
    setDetails({
      CategoryName: Name,
      ImageType: ImageType,
      UserName: name,
      UserId: id
    });
  };

  const handleOverallModal = () => {
    setOverallModal(true);
    setOverallDetails({
      UserName: name,
      UserId: id
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setDetails({
      Name: '',
      ImageType: '',
      UserName: '',
      UserId: ''
    });
  };

  const handleOverallCloseModal = () => {
    setOverallModal(false);
    setOverallDetails({
      UserName: name,
      UserId: id
    });
  };

  const boxStyle = {
    width: '150px',
    height: '150px',
    border: 'solid #BA59FB',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    boxSizing: 'border-box',
    borderRadius: '10px',
    marginRight: '8px'
  };

  const imageStyle = {
    height: '40px',
    width: '40px'
  };
  return (
    <>
      <Card>
        <Card.Body
          className="p-0"
          style={{
            height: '600px',
            width: 'auto'
          }}
        >
          <Row className="h-100 g-0">
            <Col as={Flex} alignItems="center" justifyContent="center">
              <div>
                <h4
                  style={{
                    textAlign: 'center',
                    marginTop: '3px'
                  }}
                >
                  Assessment Result
                </h4>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    textAlign: 'center',
                    marginTop: '20px'
                  }}
                >
                  <div>
                    <img
                      src={userImage}
                      alt="Image 1"
                      style={{
                        width: '70px',
                        height: '70px',
                        marginRight: '10px',
                        borderRadius: '50%'
                      }}
                    />
                    <p
                      style={{
                        marginTop: '5px',
                        fontWeight: 'bold'
                      }}
                    >
                      You
                    </p>
                  </div>
                  <div>
                    <img
                      src={profilePic}
                      style={{
                        width: '70px',
                        height: '70px',
                        marginRight: '20px',
                        borderRadius: '50%'
                      }}
                    />
                    <p
                      style={{
                        marginTop: '5px',
                        fontWeight: 'bold'
                      }}
                    >
                      {name}
                    </p>
                  </div>
                </div>

                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: '20px 0'
                    }}
                  >
                    <div
                      style={boxStyle}
                      onClick={() => handlePartClick('Background', 1)}
                    >
                      <img src={background} style={imageStyle} />
                      <p style={{ color: '#BA59FB' }}>Goals</p>
                    </div>

                    <div
                      style={boxStyle}
                      onClick={() => handlePartClick('Lifestyle', 2)}
                    >
                      <img src={lifestyle} style={imageStyle} />
                      <p style={{ color: '#BA59FB' }}>Lifestyle</p>
                    </div>
                  </div>

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div
                      style={boxStyle}
                      onClick={() => handlePartClick('Personality', 3)}
                    >
                      <img src={personality} style={imageStyle} />
                      <p style={{ color: '#BA59FB' }}>Business</p>
                    </div>
                    <div
                      style={boxStyle}
                      onClick={() => handlePartClick('Future', 4)}
                    >
                      <img src={future} style={imageStyle} />
                      <p style={{ color: '#BA59FB' }}>Future</p>
                    </div>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      color="primary"
                      className="mt-3 w-100"
                      onClick={() => handleOverallModal()}
                    >
                      Overall results
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {showModal && (
        <ModalComponent details={details} onClose={handleCloseModal} />
      )}

      {overallModal && (
        <OverallResultModal
          details={overallDetails}
          onClose={handleOverallCloseModal}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserDetails,
      hitUserDetailAPI
    },
    dispatch
  );
}
let CompatibilityReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompatibilityCategory);

export default CompatibilityReport;
