import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Row,
  Col,
  Carousel,
  Navbar,
  Nav,
  Container,
  Button,
  Image
} from 'react-bootstrap';
import facebook from '../../../../assets/logo/Facebook.svg';
import instagram from '../../../../assets/logo/Instagram.svg';
import tiktok from '../../../../assets/logo/TikTok.svg';

import couple2 from '../../../../assets/img/home/Mask-1.png';
import image from '../../../../assets/img/home/Mask-2.png';
import logo from '../../../../assets/img/illustrations/esobond-icon.png';
import couple1 from '../../../../assets/img/home/Mask.png';
import '../../../../components/pages/user/CustomCarousel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import AppContext from 'context/Context';
const HomeScreen2 = props => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [marginTop, setMarginTop] = useState('40px');
  const [marginLeft, setMarginLeft] = useState('30px');
  const {config: { isDark }} = useContext(AppContext);
  const carouselRef = useRef();
  const handleOnChange = selectedIndex => {
    setActiveIndex(selectedIndex);
  };

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setMarginTop('-200px');
      setMarginLeft('0px');
    } else {
      setMarginTop('40px');
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderImages = () => {
    const images = [couple1, couple2, image];
    return images.map((image, index) => {
      const isActive = index === activeIndex;
      return (
        <img
          key={index}
          className={`carousel-item-image ${isActive ? 'active' : 'blur'}`}
          src={image}
          alt={`Image ${index + 1}`}
          style={{
            height: '70vh'
          }}
        />
      );
    });
  };
  const navbarStyle = {
    backgroundColor: isDark?"#0b1727":'white',
    padding: '10px',
    display: 'flex',
    alignItems: 'center'
  };

  const logoStyle = {
    marginRight: 'auto',
    marginLeft: '30px'
  };
  const leftColumnStyle = {
    padding: '20px'
  };
  const downloadTextStyles = {
    fontSize: '12px',
    fontFamily: ' sans-serif',
    fontWeight: '500'
    // Adjust the font size as needed,
  };

  const appStoreTextStyles = {
    fontSize: '16px', // Adjust the font size as needed
    fontWeight: '700',
    fontFamily: ' sans-serif' // Apply bold style,
  };

  return (
    <div>
      <Navbar style={navbarStyle} expand="md">
        <Navbar.Brand style={logoStyle}>
          <Image src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center"
        >
          <div
            style={{
              marginLeft: 'auto',
              marginRight: '10%'
            }}
          >
            <Nav>
              <Nav.Link
                href="/TermsNCondition"
                style={{
                  marginRight: '20px'
                }}
              >
                Terms & Conditions
              </Nav.Link>
              <Nav.Link
                href="/AboutUs"
                style={{
                  marginRight: '20px',
                }}
              >
                About
              </Nav.Link>
                 <Nav.Link
                href="/PrivacyPolicy"
                style={{
                  marginRight: '20px'
                }}
              >
                Privacy Policy
              </Nav.Link>

              <a
                href="https://www.instagram.com/officialesobond/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={instagram}
                  alt="Instagram"
                  style={{ width: '40px', height: '40px', marginRight: '20px' }}
                />
              </a>
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebook}
                  alt="Facebook"
                  style={{ width: '40px', height: '40px', marginRight: '20px' }}
                />
              </a>
              <a
                href="https://www.tiktok.com/@esobond?is_from_webapp=1&sender_device=pc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={tiktok}
                  alt="TikTok"
                  style={{ width: '40px', height: '40px' }}
                />
              </a>

              <Button
                href="/authentication/card/login"
                style={{
                  marginRight: '20px',
                  borderRadius: '8px'
                }}
              >
                Login / Signup
              </Button>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Navbar>

      <Container
        fluid
        style={{
          backgroundColor: isDark?"#0b1727":'white'
        }}
      >
        <Row>
          <Col md={4} style={leftColumnStyle}>
            <div
              style={{
                marginLeft: marginLeft
              }}
            >
              <p
                style={{
                  background:
                    'var(--gradient, linear-gradient(118deg, #BA59FB 6.93%, #7CB3F1 86.63%))',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontFamily: 'sans-serif',
                  fontSize: '16px',
                  fontWeight: '600'
                }}
              >
                WELCOME TO ESOBOND
              </p>

              <h1
                style={{
                  marginTop: '20px',
                  fontFamily: ' sans-serif',
                  fontSize: '48px',
                  fontWeight: '700'
                }}
              >
                Join the Esobond Movement
              </h1>
              <p
                style={{
                  marginTop: '20px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Discover yourself and lead with intentionality. Committing to
                Esobond is a commitment to yourself! Join today!
              </p>

              <Row
                style={{
                  marginTop: '30px'
                }}
              >
                <div style={{ display: 'flex', zIndex: '20' }}>
                  {/* <Button
                    href="/authentication/waitlist"
                    style={{
                      display: 'flex',
                      alignItems: 'center', // Vertically center the content
                      justifyContent: 'center', // Horizontally center the content
                      margin: '5px',
                      padding: '10px',
                      background:
                        'linear-gradient(155.23deg, #ba59fb 6.93%, #7cb3f1 86.63%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      cursor: 'pointer',
                      flex: 1,
                      fontWeight: '700',
                      fontFamily: 'sans-serif',
                      fontSize: '25px'
                    }}
                  >
                    Join the waitlist
                  </Button> */}

                  <Button
                    href="/authentication/card/register"
                    style={{
                      display: 'flex',
                      alignItems: 'center', // Vertically center the content
                      justifyContent: 'center', // Horizontally center the content
                      margin: '5px',
                      padding: '10px',
                      background:
                        'linear-gradient(155.23deg, #ba59fb 6.93%, #7cb3f1 86.63%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      cursor: 'pointer',
                      flex: 1,
                      fontWeight: '700',
                      fontFamily: 'sans-serif',
                      fontSize: '25px'
                    }}
                  >
                    Register
                  </Button>
                </div>
              </Row>

              <Row
                style={{
                  marginTop: '80px'
                }}
              >
                {/* <Col>
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '30px',
                      color: 'black'
                    }}
                  >{`${10 + 'M'}+`}</span>
                  <p>User Active</p>
                </Col> */}
                {/* <Col>
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '30px',
                      color: 'black'
                    }}
                  >{`${10 + 'M'}+`}</span>
                  <p>User Active</p>
                </Col>
                <Col>
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: '30px',
                      color: 'black'
                    }}
                  >{`${150 + 'M'}+`}</span>
                  <p>App Downloads</p>
                </Col> */}
              </Row>
            </div>
          </Col>
          <Col
            md={8}
            style={{
              padding: '20px'
            }}
          >
            <Row
              style={{
                // marginLeft: '20px',
                marginTop: marginTop
              }}
            >
              <div className="custom-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  onSelect={handleOnChange}
                  interval={3000}
                  controls={false}
                  style={{
                    height: '80vh'
                  }}
                >
                  {renderImages()}
                </Carousel>
              </div>
              <div
                style={{
                  display: 'flex',
                  marginTop: marginTop
                }}
              >
                {/* <Button
                  className="rounded"
                  style={{
                    flex: 1,
                    margin: '5px',
                    padding: '5px',
                    backgroundColor: 'black',
                    cursor: 'pointer',
                    textAlign: 'center',
                    alignItems: 'center',
                    border: 'none'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      // padding: '10px'
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faApple}
                      size="3x"
                      style={{ marginRight: '0px', marginLeft: '0px' }}
                    />
                    <span style={downloadTextStyles}>
                      Download on the
                      <br />
                      <span style={appStoreTextStyles}>App Store</span>
                    </span>
                  </div>
                </Button> */}
                {/* <Button
                  className="rounded"
                  style={{
                    flex: 1,
                    margin: '5px',
                    padding: '5px',
                    backgroundColor: 'black',
                    cursor: 'pointer',
                    textAlign: 'center',
                    alignItems: 'center',
                    border: 'none'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      // paddingLeft: '0px'
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faGooglePlay}
                      size="3x"
                      style={{ marginRight: '0px', marginLeft: '20px' }}
                    />
                    <span style={downloadTextStyles}>
                      Download on the
                      <br />
                      <span style={appStoreTextStyles}>Google Play</span>
                    </span>
                  </div>
                </Button> */}
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomeScreen2;
