import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FeedContext } from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRulerVertical,
  faCalendarDay,
  faLocationArrow,
  faComment,
  faHeart
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { likeUnlikeProfile } from 'redux/actions/MatchingListActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

const FeedActionButtonsScreen = props => {
  const navigate = useNavigate();
  const date = new Date(Number(props.details.DOB)); // Multiply by 1000 to convert seconds to milliseconds
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const original = `${day}/${month}/${year}`;

  const [liked, setLiked] = useState(props.details.LikeedUserId ? true : false);
  const { feeds, feedDispatch } = useContext(FeedContext);
  let heartIconClass;
  useEffect(() => {
    console.log(liked);
    heartIconClass = liked ? 'me-2 text-danger' : 'me-2'; // Add 'text-danger' class when liked
  }, []);

  const toggleLiked = () => {
    setLiked(!liked);
    const feed = feeds.find(item => item.id === id);
    feed.details.reactions.like = !liked;
    feed.details.countLCS.like = !liked
      ? feed.details.countLCS.like + 1
      : feed.details.countLCS.like - 1;

    feedDispatch({
      type: 'UPDATE',
      payload: { id, feed }
    });
  };
  heartIconClass = liked ? 'me-2 text-danger' : 'me-2'; // Add 'text-danger' class when liked


  const likeUnlike = id => {
    console.log('I have been clicked');
    if (props.details.LikeedUserId === null) {
      props.details.LikeedUserId = props.details.UserId;
      setLiked(true);
    } else {
      props.details.LikeedUserId = null;
      setLiked(false);
    }
    props
      .likeUnlikeProfile(id, !liked).then(data => {
        if (data.code === 200) {
          console.log(data.data);
          console.log('inside');
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const getImageNameFromUrl = (imageUrl) => {
    const urlParts = imageUrl.split('/');
    const imageName = urlParts[urlParts.length - 1];
    return imageName;
  };

  const openUserChat = (id, name, profilePic) => {
    const imageName = getImageNameFromUrl(profilePic)
    navigate(`/app/chat/${id}/${name}/${imageName}`);
  };

  return (
    <Row className="g-0 fw-semi-bold text-center py-2 fs--1">
      <Col xs="auto">
        <Flex
          alignItems="center"
          className="rounded text-700 me-3 cursor-pointer"
        >
          <FontAwesomeIcon icon={faCalendarDay} className="me-2" />

          <span className="ms-1">{original}</span>
        </Flex>
      </Col>

      <Col xs="auto">
        <Flex
          alignItems="center"
          className="rounded text-700 me-3 cursor-pointer"
        >
          {/* <FontAwesomeIcon icon={faRulerVertical} className="me-2" /> */}
          {/* <span className="ms-1">{props.details.Height}</span> */}
        </Flex>
      </Col>
      <Col xs="auto">
        <Flex
          alignItems="center"
          className="rounded text-700 me-3 cursor-pointer"
        >
          {/* <FontAwesomeIcon icon={faLocationArrow} className="me-2" />
          <span className="ms-1">{props.details.Location}</span> */}
        </Flex>
      </Col>
      <Col
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Col xs="auto" className="ml-auto">
          <Flex
            alignItems="center"
            className="rounded text-700 me-3 cursor-pointer"
            onClick={() => openUserChat(props?.details?.UserId, props?.details?.Name, props?.details?.profilePic)}
          >
            <FontAwesomeIcon icon={faComment} className="me-2" />
            <span className="ms-1">Chat</span>
          </Flex>
        </Col>

        <Col xs="auto">
          <Flex
            alignItems="center"
            className="rounded text-700 me-3 cursor-pointer"
            onClick={() => likeUnlike(props.details.UserId)}
          >
            <FontAwesomeIcon icon={faHeart} className={heartIconClass} />
            <span className="ms-1">Favorite</span>
          </Flex>
        </Col>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      likeUnlikeProfile
    },
    dispatch
  );
}
let FeedActionButtons = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedActionButtonsScreen);

FeedActionButtons.propTypes = {
  id: PropTypes.string,
  reactions: PropTypes.object
};

export default FeedActionButtons;
