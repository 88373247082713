// withSubscriptionCheck.js
import SubscriptionError from 'components/errors/Subscription';
import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';




export const checkUserSubscribed = () => {
  try {
    const validation =  localStorage.getItem('subscriptionType');
    console.log(validation, 'hello', Number(validation) !== 0);
    return Number(validation) !== 0;
  } catch (error) {
    console.error('Error checking subscription:', error);
    return false;
  }
};

const withSubscriptionCheck = (ComponentToRender) => {
  const isUserSubscribed = () => {
    const validation =  checkUserSubscribed()
    console.log(!validation,"pppppiiiii");
    if(!validation){
      toast.error('Please buy a subscription to access this feature.', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    
    return validation;
  };

  return class extends Component {
    render() {
      
      if (isUserSubscribed()) {
        return <ComponentToRender {...this.props} />;
      } else {
        return <SubscriptionError/>;
      }
    }
  };
};


export default withSubscriptionCheck;
