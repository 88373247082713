/* eslint-disable react/prop-types */
import React from 'react';
import { Form } from 'react-bootstrap';

export default function RadioForm(props) {
  const handleDataChange = e => {
    props.addQuestion(props.data.QuestionId, e.target.value, props.style);
  };

  return (
    <Form>
      <p className="fs--1 mt-4">Q. {props.data.Description}</p>
      {props.data &&
        props.data.Options.map((data, index) => (
          <Form.Group className="mb-2" controlId={props.data.Description}>
            <Form.Check
              key={index + 1}
              inline
              type="radio"
              name={props.data.Description}
              id={props.data.QuestionId}
              value={data.AnswerId}
              className="me-2"
              onChange={handleDataChange}
            />
            <Form.Label className="mb-0">{data.Option}</Form.Label>
          </Form.Group>
        ))}
    </Form>
  );
}
