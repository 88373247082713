import React, { useContext, useState, useEffect } from 'react';
import { Card, Form, Nav, Spinner, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import AppContext, { AuthWizardContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import RadioForm from './RadioForm';
import MultiSelectForm from './MultiSelectForm';
import SingleSelectForm from './SingleSelectForm';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  postUnAnsweredQuestions,
  getUnAnsweredQuestions
} from '../../redux/actions/QuestionaireActions';
import { toast } from 'react-toastify';
import Success from './Success';
import TextForm from './TextForm';

const UnAnsweredWizardLayoutScreen = props => {
  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    getUnAnsweredQuestionsData();
  }, []);

  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const {
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors
  } = useForm();

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [quizResponses, setQuizResponses] = useState([]);

  const [map, setMap] = useState(new Map());

  const [isNextDisable, setIsNextDisbale] = useState(true);

  const arr = [];

  useEffect(() => {
    checkStep();
  }, [quizResponses]);

  const filterAnswerId = answerIds => {
    answerIds.forEach(val => {
      arr.push(val.AnswerId);
    });

    return arr;
  };

  const addQuestion = (questionId, answerIds, style) => {
    if (style === 4) {
      answerIds = filterAnswerId(answerIds);
    }

    setIsNextDisbale(false);

    setMap([questionId, answerIds, style]);
  };

  const getUnAnsweredQuestionsData = async () => {
    setLoading(true);
    props.getUnAnsweredQuestions().then(data => {
      if (!data.error && data.code === 200) {
        console.log("Inside to check");
        setLoading(false);
        setQuestions(data.data);
      } else {
        toast.error(data.error);
        setLoading(false);
      }
    });
  };

  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const updateQuizResponse = (questionId, updatedResponse) => {
    let i = 0;
    quizResponses.forEach((obj, index) => {
      if (
        obj.QuestionId === questionId &&
        obj.AnswerId !== updatedResponse.AnswerId
      ) {
        i = 1;
        quizResponses[index] = updatedResponse;
      }
    });
    if (i === 0) {
      setQuizResponses(prevArray => [...prevArray, updatedResponse]);
    }
  };

  const handleClick = () => {
    let objectResponse;

    if (typeof map[1] === 'string' && map[2] === 5) {
      objectResponse = {
        QuestionId: map[0],
        OtherOptions: map[1]
      };
    } else if (typeof map[1] === 'string') {
      objectResponse = {
        QuestionId: map[0],
        AnswerId: [+map[1]]
      };
    } else {
      objectResponse = {
        QuestionId: map[0],
        AnswerId: map[1]
      };
    }
    if (quizResponses.length === 0) {
      setQuizResponses([objectResponse]);
    } else {
      updateQuizResponse(objectResponse.QuestionId, objectResponse);
    }

    setIsNextDisbale(!isNextDisable);
  };

  const checkStep = data => {
    if (step === questions.length) {
      onSubmitData();
      setStep(step + 1);
    } else if (questions.length) {
      setStep(step + 1);
    }
  };

  const onSubmitData = data => {
    props
      .postUnAnsweredQuestions(quizResponses)
      .then(data => {
        if (data.code === 200) {
        }
      })
      .catch(error => {
        toast.error(error);
      });

    setUser({ ...user, ...data });
    setModal(true);
  };

  return (
    <>
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Body
          style={{
            width: '400px',
            height: '400px'
          }}
        >
          {loading && (
            <span style={{ display: 'block', textAlign: 'center' }}>
              <Spinner animation="border" role="status" className="flex-center">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </span>
          )}

          {!loading &&
            !modal &&
            questions &&
            questions.map((data, index) => (
              <div key={index}>
                {step === index + 1 && data.Style === 5 && (
                  <TextForm
                    data={data}
                    addQuestion={addQuestion}
                    style={data.Style}
                  />
                )}
              </div>
            ))}

          {!loading &&
            !modal &&
            questions &&
            questions.map((data, index) => (
              <div key={index}>
                {step === index + 1 && data.Style === 3 && (
                  <SingleSelectForm
                    data={data}
                    addQuestion={addQuestion}
                    style={data.Style}
                  />
                )}
              </div>
            ))}

          {!loading &&
            !modal &&
            questions &&
            questions.map((data, index) => (
              <div key={index}>
                {step === index + 1 && data.Style === 2 && (
                  <RadioForm
                    data={data}
                    addQuestion={addQuestion}
                    style={data.Style}
                  />
                )}
              </div>
            ))}

          {!loading &&
            !modal &&
            questions &&
            questions.map((data, index) => (
              <div key={index}>
                {step === index + 1 && data.Style === 1 && (
                  <RadioForm
                    data={data}
                    addQuestion={addQuestion}
                    style={data.Style}
                  />
                )}
              </div>
            ))}

          {!loading &&
            !modal &&
            questions &&
            questions.map((data, index) => (
              <div key={index}>
                {step === index + 1 && data.Style === 4 && (
                  <MultiSelectForm
                    data={data}
                    addQuestion={addQuestion}
                    style={data.Style}
                  />
                )}
              </div>
            ))}

          {step === questions.length + 1 && (
            <Success reset={reset} route={`/user/home`} />
          )}
        </Card.Body>

        {!loading && (
          <Card.Footer
            className={classNames('px-md-6 bg-light', {
              'd-none': step === questions.length + 1,
              ' d-flex': step < questions.length
            })}
          >
            <IconButton
              variant="link"
              icon={isRTL ? 'chevron-right' : 'chevron-left'}
              iconAlign="left"
              transform="down-1 shrink-4"
              className={classNames('px-0 fw-semi-bold', {
                'd-none': step === 1
              })}
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Prev
            </IconButton>

            <IconButton
              variant="primary"
              className="ms-auto px-5"
              type="submit"
              icon={isRTL ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
              onClick={handleClick}
              disabled={isNextDisable}
            >
              {step === questions.length ? 'Submit' : 'Next'}
            </IconButton>
          </Card.Footer>
        )}
      </Card>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUnAnsweredQuestions,
      postUnAnsweredQuestions
    },
    dispatch
  );
}

let UnAnsweredWizardLayoutForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnAnsweredWizardLayoutScreen);

export default UnAnsweredWizardLayoutForm;
