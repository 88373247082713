import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAL9EmwN7e11Y3cjtZ9qd4ZNZjHVYROWU4",
  authDomain: "esobond-6896d.firebaseapp.com",
  databaseURL: "https://esobond-6896d-default-rtdb.firebaseio.com",
  projectId: "esobond-6896d",
  storageBucket: "esobond-6896d.appspot.com",
  messagingSenderId: "124302662845",
  appId: "1:124302662845:web:4c2b703975894d40bdabd4",
  measurementId: "G-Q3N0C377TH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);