import Divider from 'components/common/Divider';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import SocialAuthButtons from './SocialAuthButtons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hitSendLoginData } from '../../redux/actions/LoginActions';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import AppUser from 'utils/AppUser';
import { db } from 'utils/firebase-config';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { Constant } from 'utils/Constant';

const LoginScreen = props => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const messageRef = collection(db, 'USERS');

  useEffect(() => {
    // const userLoggedIn = JSON.parse(localStorage.getItem('userLoggedIn'));
    // const checkName = JSON.parse(localStorage.getItem('userName'));
    // if (!checkName) {
    //   navigate('/authentication/create-user');
    // }
    // else
    // if (userLoggedIn) {
    //   navigate('/social/feed');
    // }
  }, []);

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();
    let appUsrObj = AppUser.getInstance();
    appUsrObj.token = null;
    appUsrObj.userId = null;
    props
      .hitSendLoginData(formData.email, formData.password)
      .then(data => {
        if (!data.error && data.code === 200) {
          setLoading(false);
          toast.success(data.message);
          appUsrObj.token = data.data.Token;
          appUsrObj.userId = data.data.UserId;
          localStorage.setItem('accessEsoToken', data.data.Token);
          localStorage.setItem('userName', data.data.Name);
          localStorage.setItem('userLoggedIn', true);
          localStorage.setItem('userData', data.data);
          localStorage.setItem('UserChatId', data?.data?.UserId);
          localStorage.setItem(
            'HasAnsweredTotalQuestion',
            data?.data?.HasAnsweredTotalQuestion
          );
          addToFirebase(data.data);
          if (data?.data?.Name === null) {
            navigate('/authentication/create-user');
          } else if (data?.data?.HasQuestionnaireAnswered === false) {
            navigate('/user/questionaire');
          } else {
            navigate('/user/home');
          }
        } else if (data?.error == 'Please verify your email.') {
          setLoading(false);
          navigate('/verify-otp');
        } else if (
          data?.error ==
          'Password must have 8-15 characters comprising of at least one capital, one small, one numeric and one special character'
        ) {
          setLoading(false);
          toast.error('Invalid Password');
        } else {
          toast.error(data.error);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const addToFirebase = async (data) => {
    await setDoc(doc(collection(db, Constant.USERS), data.UserId + ''), {
      username: data.Name,
      profilePic: data.ProfilePic,
      id: data.UserId
    }, { merge: true })
    .then(() => {
      console.log('Document successfully written!');
    })
    .catch((error) => {
      console.error('Error writing document: ', error);
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {props.hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!props.hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {props.hasLabel && <Form.Label>Password</Form.Label>}
        <InputGroup className="mb-3">
          <Form.Control
            placeholder={!props.hasLabel ? 'Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type={showPassword ? 'text' : 'password'}
          />
          <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
            {' '}
            {showPassword ? 'Hide' : 'Show'}
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        {/* <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col> */}

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/${props.layout}/forgot-password`}
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        {!loading && (
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={!formData.email || !formData.password}
          >
            Log in
          </Button>
        )}
        {loading && (
          <span style={{ display: 'block', textAlign: 'center' }}>
            <Spinner animation="border" role="status" className="flex-center">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </span>
        )}
      </Form.Group>

      <Divider className="mt-4">or log in with</Divider>

      <SocialAuthButtons />
    </Form>
  );
};

// LoginForm.propTypes = {
//   layout: PropTypes.string,
//   hasLabel: PropTypes.bool
// };

// LoginForm.defaultProps = {
//   layout: 'simple',
//   hasLabel: false
// };

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitSendLoginData
    },
    dispatch
  );
}
let LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginScreen);

export default LoginForm;
