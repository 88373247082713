import ApiFetch from '../../apimanager/ApiFetch';
import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD_ENDPOINT,
  LOGIN_ENDPOINT,
  POST_APPLEDATA_ENDPOINT,
  POST_FACEBOOK_ENDPOINT,
  POST_GOOGLEDATA_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  SEND_OTP_ENDPOINT,
  SEND_WAITLIST_FORM,
  SUPPORT_ENDPOINT
} from '../../apimanager/ApiEndpoints';
import { SAVE_USERDATA } from '../ActionType';
import { toast } from 'react-toastify';

export const hitSendLoginData = (email, password) => dispatch => {
  const parameters = {
    UserInput: email,
    Password: password
  };
  return ApiFetch.fetchPost(LOGIN_ENDPOINT, parameters)
    .then(response => {
      return response;
    })
    .catch(err => {
      toast.error(err.error);
    });
};

export const hitSendSupportData = (formData) => dispatch => {
  return ApiFetch.fetchPost(SUPPORT_ENDPOINT, formData)
    .then(response => {
      return response;
    })
    .catch(err => {
      toast.error(err.error);
    });
};

export const hitSendOTP = (userid, email, mode) => dispatch => {
  let parameters;
  if (mode === 2) {
    parameters = {
      UserId: userid,
      EmailOrPhone: email,
      OtpMode: mode
    };
  } else {
    parameters = {
      EmailOrPhone: email,
      OtpMode: mode
    };
  }
  return ApiFetch.fetchPost(SEND_OTP_ENDPOINT, parameters)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror hitSendOTPAPI Action', err);
    });
};

export const hitSendForgotPassword = email => dispatch => {
  const parameters = {
    Email: email
  };
  return ApiFetch.fetchPost(FORGOT_PASSWORD_ENDPOINT, parameters)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror hitSendforgotPasswordAPI Action', err);
    });
};

export const hitResetPassword = (password, token) => dispatch => {
  const parameters = {
    Password: password
  };
  return ApiFetch.fetchPost(RESET_PASSWORD_ENDPOINT, parameters, token)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror PosthitResetPassword Action', err);
    });
};

export const hitChangePassword = (oldPassword, newPassword) => dispatch => {
  const parameters = {
    oldPassword: oldPassword,
    newPassword: newPassword
  };
  return ApiFetch.fetchPut(CHANGE_PASSWORD, JSON.stringify(parameters))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror PosthitChangePassword Action', err);
    });
};

export const hitFacebookLogin = accesstoken => dispatch => {
  const parameters = {
    AccessToken: accesstoken
  };
  return ApiFetch.fetchPost(POST_FACEBOOK_ENDPOINT, parameters)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror PostHitFacebookLogin Action', err);
    });
};

export const hitGoogleLogin = accesstoken => dispatch => {
  const parameters = {
    AccessToken: accesstoken,
    AccessFrom:"Website"
  };
  return ApiFetch.fetchPost(POST_GOOGLEDATA_ENDPOINT, parameters)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror PosthitGoogleLogin Action', err);
    });
};

export const hitWaitlistAPI =
  (firstName, lastName, countryCode, phoneNumber, email, device) => dispatch => {
    const parameters = {
      FirstName: firstName,
      LastName: lastName,
      PhoneNumber: phoneNumber,
      CountryCode: countryCode,
      Email: email,
      MobileDevice: device,
    };
    return ApiFetch.fetchPost(SEND_WAITLIST_FORM, JSON.stringify(parameters))
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log('errror postCreateUserAPI Action', err);
      });
  };

export const hitAppleLogin = (appleid, email) => dispatch => {
  const parameters = {
    AppleId: appleid,
    Email: email
  };
  return ApiFetch.fetchPost(POST_APPLEDATA_ENDPOINT, JSON.stringify(parameters))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror PosthitAppleLogin Action', err);
    });
};

export const saveLoginUserData = value => {
  return {
    type: SAVE_USERDATA,
    payload: value
  };
};
