import ApiFetch from '../../apimanager/ApiFetch';
import {
  PROFILESETUP_ENDPOINT,
  REGISTERUSER_ENDPOINT,
  VERIFY_OTP_ENDPOINT,
} from '../../apimanager/ApiEndpoints';
import { SAVE_USERDATA} from '../ActionType';

export const hitSendSignupData = (email, password) => dispatch => {
  const parameters = {
    Email: email,
    Password: password,
  };
  return ApiFetch.fetchPost(REGISTERUSER_ENDPOINT, parameters)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror postHitSendSignupData Action', err);
    });
};

export const hitVerifyOtpAPI = (userid, email, otp, mode) => dispatch => {
  let parameters;
  if (mode === 2) {
    parameters = {OtpMode: mode, EmailOrPhone: email, Otp: otp, UserId: userid};
  }
  else { 
    parameters = {OtpMode: mode, EmailOrPhone: email, Otp: otp}; 
  }
  return ApiFetch.fetchPost(VERIFY_OTP_ENDPOINT, parameters)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror postVerifyUserAPI Action', err);
    });
};

export const hitCreateUserAPI =
  (firstName, lastName, countryCode, phoneNumber, gender, dob, picURL) => dispatch => {
    const parameters = {
      First_Name: firstName,
      Last_Name: lastName,
      CountryCode: countryCode,
      PhoneNumber: phoneNumber,
      Gender: gender,
      DateOfBirth: dob,
      ProfilePic: picURL,
    };
    return ApiFetch.fetchPost(PROFILESETUP_ENDPOINT, parameters)
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log('errror postCreateUserAPI Action', err);
      });
  };

export const saveSignUpUserData = value => {
  return {
    type: SAVE_USERDATA,
    payload: value,
  };
};
