import ApiFetch from '../../apimanager/ApiFetch';
import {
  ASSESSMENT_LISTING,
  ASSESSMENT_QUESTION_USER_LISTING,
  COMPATIBILITY_REPORT,
  OVERALL_COMPATIBILITY_REPORT
} from '../../apimanager/ApiEndpoints';

export const assessmentListing = category => dispatch => {
  return ApiFetch.fetchGet(ASSESSMENT_LISTING + `?&Category=${category}`)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror Assessment Listing Action', err);
    });
};

export const assessmentQuestionUserListing =
  (limit, pageNo, questionId) => dispatch => {
    return ApiFetch.fetchGet(
      ASSESSMENT_QUESTION_USER_LISTING +
        `?QuestionId=${questionId}&Limit=${limit}&PageNo=${pageNo}`,
      {}
    )
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log('error Assessment Question User Listing Actions', err);
      });
  };

export const compatibilityReport = (userId, category) => dispatch => {
  return ApiFetch.fetchGet(
    COMPATIBILITY_REPORT + `?UserId=${userId}&Category=${category}`,
    {}
  )
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error Compatibility Report Actions', err);
    });
};

export const overAllCompatibilityReport = loggedInUserId => dispatch => {
  return ApiFetch.fetchGet(
    OVERALL_COMPATIBILITY_REPORT + `?UserId=${loggedInUserId}`,
    {}
  )
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error Overall Compatibility Report Actions', err);
    });
};
