import React from 'react';

import AuthCardLayout from 'layouts/AuthCardLayout';
import SupportForm from '../SupportForm';

const SupportPage = () => {
  return (
    <AuthCardLayout>
      <h3>Support</h3>
      <SupportForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default SupportPage;
