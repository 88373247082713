import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import ChatThread from './ChatThread';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';
import {   query,
  collection,
  onSnapshot,
  where,
  or } from 'firebase/firestore';
import { db } from 'utils/firebase-config';

const ChatSidebar = ({ hideSidebar, userDetails, userid, selected, setSelected }) => {
  const userChatId = localStorage.getItem('UserChatId');
  let userId=parseInt(userChatId)
  const userData = localStorage.getItem('userData');
  const [chatData, setChatData] = useState([]);
  const messageRef = collection(db, 'CHAT_LIST');
  let mylistener;

  const getUserList = async () => {
    mylistener = query(
      messageRef,
      or(where('sender', '==', userId), where('receiver', '==', userId))
    );
    onSnapshot(mylistener, QuerySnapshot => {
      const chatList = [];
      QuerySnapshot.forEach(documentSnapshot => {
        chatList.push({
          lastMessage: documentSnapshot.data().lastMessage,
          createdAt: documentSnapshot.data().createdAt.toDate(),
          sender: documentSnapshot.data().sender,
          receiver: documentSnapshot.data().receiver,
          id: documentSnapshot.data().id,
          user: documentSnapshot.data().users
        });
      });
      getData(chatList);
    });
    
  };

  useEffect(() => {
    getUserList();
    return () => {
      try {
        mylistener(); //here you need to remove the listener
      } catch (error) {
        console.log(error);
      }
    };
  }, [userData]);

  const getData = async tempArray => {
    tempArray.sort(function compare(a, b) {
      let dateA = new Date(a.createdAt);
      let dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
    setChatData(tempArray);
  };

  const callDetails = (data) => {  
    userDetails(data, chatData);
  }
  
  return (
    <div className={classNames('chat-sidebar', { 'start-0': hideSidebar })}>
      <div className="contacts-list">
        <SimpleBarReact style={{ height: '100%', minWidth: '65px' }}>
          <Nav className="border-0">
            {chatData.map((thread, index) => (
              <ChatThread thread={thread} index={index} key={thread.id} callDetailsUser={callDetails} userid={userid} selected={selected} setSelected={setSelected} />
            ))}
          </Nav>
        </SimpleBarReact>
      </div>
    </div>
  );
};

ChatSidebar.propTypes = {
  hideSidebar: PropTypes.bool
};

export default ChatSidebar;
