import React from 'react';
import { Row, Col, Navbar, Nav, Container, Image } from 'react-bootstrap';

import logo from '../../../../assets/img/illustrations/esobond-icon.png';
import '../../../../components/pages/user/CustomCarousel.css';
const PrivacyPolicyLanding = props => {
  const navbarStyle = {
    backgroundColor: 'white',
    padding: '20px',
    display: 'flex',
    alignItems: 'center'
  };

  const logoStyle = {
    marginRight: 'auto',
    marginLeft: '30px'
  };
  const leftColumnStyle = {
    padding: '20px'
  };

  return (
    <div>
      <Navbar style={navbarStyle} expand="md">
        <Navbar.Brand style={logoStyle} href="/user/home">
          <Image src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center"
        >
          <div
            style={{
              marginLeft: 'auto',
              marginRight: '10%'
            }}
          >
            <Nav></Nav>
          </div>
        </Navbar.Collapse>
      </Navbar>

      <Container
        fluid
        style={{
          backgroundColor: 'white'
        }}
      >
        <Row>
          <Col md={11} style={leftColumnStyle}>
            <div
              style={{
                marginLeft: '30px'
              }}
            >
              <p
                style={{
                  background:
                    'var(--gradient, linear-gradient(118deg, #BA59FB 6.93%, #7CB3F1 86.63%))',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontFamily: 'sans-serif',
                  fontSize: '16px',
                  fontWeight: '600'
                }}
              >
                WELCOME TO ESOBOND
              </p>

              <h1
                style={{
                  marginTop: '20px',
                  fontFamily: ' sans-serif',
                  fontSize: '48px',
                  fontWeight: '700'
                }}
              >
                Privacy Policy
              </h1>
              <h2>Last updated: November 04, 2022</h2>

              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                This Privacy Policy describes Our policies and procedures on the
                collection, use and disclosure of Your information when You use
                the Service and tells You about Your privacy rights and how the
                law protects You.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                We use Your Personal data to provide and improve the Service. By
                using the Service, You agree to the collection and use of
                information in accordance with this Privacy Policy. Our services
                are restricted to individuals who are 18 years of age or older.
                We do not permit individuals under the age of 18 on our
                platform. If you suspect that a member is under the age of 18,
                please reach out to us at support@esobond.com
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Interpretation and Definitions
              </p>
              <h3
                style={{
                  marginTop: '20px'
                }}
              >
                Interpretation
              </h3>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>
              <h3
                style={{
                  marginTop: '20px'
                }}
              >
                Definitions
              </h3>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                For the purposes of this Privacy Policy:
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Account means a unique account created for You to access our
                Service or parts of our Service.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Affiliate means an entity that controls, is controlled by or is
                under common control with a party, where "control" means
                ownership of 50% or more of the shares, equity interest or other
                securities entitled to vote for election of directors or other
                managing authority.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Application means the software program provided by the Company
                downloaded by You on any electronic device, named Esobond
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Business, for the purpose of the CCPA (California Consumer
                Privacy Act), refers to the Company as the legal entity that
                collects Consumers' personal information and determines the
                purposes and means of the processing of Consumers' personal
                information, or on behalf of which such information is collected
                and that alone, or jointly with others, determines the purposes
                and means of the processing of consumers' personal information,
                that does business in the State of California.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Company (referred to as either "the Company", "We", "Us" or
                "Our" in this Agreement) refers to Esobond, P.O. Box 94871
                Atlanta, GA 30377.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                For the purpose of the GDPR, the Company is the Data Controller.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Consumer, for the purpose of the CCPA (California Consumer
                Privacy Act), means a natural person who is a California
                resident. A resident, as defined in the law, includes (1) every
                individual who is in the USA for other than a temporary or
                transitory purpose, and (2) every individual who is domiciled in
                the USA who is outside the USA for a temporary or transitory
                purpose.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Cookies are small files that are placed on Your computer, mobile
                device or any other device by a website, containing the details
                of Your browsing history on that website among its many uses.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Country refers to: Georgia, United States
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Data Controller, for the purposes of the GDPR (General Data
                Protection Regulation), refers to the Company as the legal
                person which alone or jointly with others determines the
                purposes and means of the processing of Personal Data.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Device means any device that can access the Service such as a
                computer, a cellphone or a digital tablet.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Do Not Track (DNT) is a concept that has been promoted by US
                regulatory authorities, in particular the U.S. Federal Trade
                Commission (FTC), for the Internet industry to develop and
                implement a mechanism for allowing internet users to control the
                tracking of their online activities across websites.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Personal Data is any information that relates to an identified
                or identifiable individual.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                For the purposes of GDPR, Personal Data means any information
                relating to You such as a name, an identification number,
                location data, online identifier or to one or more factors
                specific to the physical, physiological, genetic, mental,
                economic, cultural or social identity.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                For the purposes of the CCPA, Personal Data means any
                information that identifies, relates to, describes or is capable
                of being associated with, or could reasonably be linked,
                directly or indirectly, with You.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Sale, for the purpose of the CCPA (California Consumer Privacy
                Act), means selling, renting, releasing, disclosing,
                disseminating, making available, transferring, or otherwise
                communicating orally, in writing, or by electronic or other
                means, a Consumer's personal information to another business or
                a third party for monetary or other valuable consideration.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Service refers to the Application or the Website or both.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Service Provider means any natural or legal person who processes
                the data on behalf of the Company. It refers to third-party
                companies or individuals employed by the Company to facilitate
                the Service, to provide the Service on behalf of the Company, to
                perform services related to the Service or to assist the Company
                in analyzing how the Service is used. For the purpose of the
                GDPR, Service Providers are considered Data Processors.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Third-party Social Media Service refers to any website or any
                social network website through which a User can log in or create
                an account to use the Service.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Usage Data refers to data collected automatically, either
                generated by the use of the Service or from the Service
                infrastructure itself (for example, the duration of a page
                visit).
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Website refers to Esobond, accessible from www.esobond.com
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                You means the individual accessing or using the Service, or the
                company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Under GDPR (General Data Protection Regulation), You can be
                referred to as the Data Subject or as the User as you are the
                individual using the Service.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Collecting and Using Your Personal Data
              </p>
              <h3
                style={{
                  marginTop: '20px'
                }}
              >
                Types of Data Collected
              </h3>
              <h4
                style={{
                  marginTop: '20px'
                }}
              >
                Personal Data
              </h4>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                While using Our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may
                include, but is not limited to:
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Email address
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                First name and last name
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Phone number
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Address, State, Province, ZIP/Postal code, City
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Bank account information in order to pay for products and/or
                services within the Service
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Usage Data
              </p>

              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                When You pay for a product and/or a service via bank transfer,
                We may ask You to provide information to facilitate this
                transaction and to verify Your identity. Such information may
                include, without limitation:
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · Date of birth
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · Passport or National ID card
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · Bank card statement
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · Other information linking You to an address
              </p>
              <h3
                style={{
                  marginTop: '20px'
                }}
              >
                Usage Data
              </h3>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Usage Data is collected automatically when using the Service.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Usage Data may include information such as Your Device's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that You visit, the
                time and date of Your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                When You access the Service by or through a mobile device, We
                may collect certain information automatically, including, but
                not limited to, the type of mobile device You use, Your mobile
                device unique ID, the IP address of Your mobile device, Your
                mobile operating system, the type of mobile Internet browser You
                use, unique device identifiers and other diagnostic data.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                We may also collect information that Your browser sends whenever
                You visit our Service or when You access the Service by or
                through a mobile device.
              </p>
              <h3
                style={{
                  marginTop: '20px'
                }}
              >
                Information from Third-Party Social Media Services
              </h3>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                The Company allows You to create an account and log in to use
                the Service through the following Third-party Social Media
                Services:
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · Google
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · Facebook
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · Twitter
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · LinkedIn
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                If You decide to register through or otherwise grant us access
                to a Third-Party Social Media Service, We may collect Personal
                data that is already associated with Your Third-Party Social
                Media Service's account, such as Your name, Your email address,
                Your activities or Your contact list associated with that
                account.{' '}
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                You may also have the option of sharing additional information
                with the Company through Your Third-Party Social Media Service's
                account. If You choose to provide such information and Personal
                Data, during registration or otherwise, You are giving the
                Company permission to use, share, and store it in a manner
                consistent with this Privacy Policy.{' '}
              </p>
              <h3
                style={{
                  marginTop: '20px'
                }}
              >
                Information Collected while Using the Application
              </h3>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                While using Our Application, in order to provide features of Our
                Application, We may collect, with Your prior permission:
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Information regarding your location
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Pictures and other information from your Device's camera and
                photo library
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                We use this information to provide features of Our Service, to
                improve and customize Our Service. The information may be
                uploaded to the Company's servers and/or a Service Provider's
                server or it may be simply stored on Your device.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                You can enable or disable access to this information at any
                time, through Your Device settings.
              </p>
              <h3
                style={{
                  marginTop: '20px'
                }}
              >
                Tracking Technologies and Cookies
              </h3>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                We use Cookies and similar tracking technologies to track the
                activity on Our Service and store certain information. Tracking
                technologies used are beacons, tags, and scripts to collect and
                track information and to improve and analyze Our Service. The
                technologies We use may include:
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · Cookies or Browser Cookies. A cookie is a small file placed on
                Your Device. You can instruct Your browser to refuse all Cookies
                or to indicate when a Cookie is being sent. However, if You do
                not accept Cookies, You may not be able to use some parts of our
                Service. Unless you have adjusted Your browser setting so that
                it will refuse Cookies, our Service may use Cookies.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · Web Beacons. Certain sections of our Service and our emails
                may contain small electronic files known as web beacons (also
                referred to as clear gifs, pixel tags, and single-pixel gifs)
                that permit the Company, for example, to count users who have
                visited those pages or opened an email and for other related
                website statistics (for example, recording the popularity of a
                certain section and verifying system and server integrity).
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Cookies can be "Persistent" or "Session" Cookies. Persistent
                Cookies remain on Your personal computer or mobile device when
                You go offline, while Session Cookies are deleted as soon as You
                close Your web browser. Learn more about cookies on the Free
                Privacy Policy website article.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                We use both Session and Persistent Cookies for the purposes set
                out below:
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Necessary / Essential Cookies
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Type: Session Cookies
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Administered by: Us
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Cookies Policy / Notice Acceptance Cookies
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Type: Persistent Cookies
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Administered by: Us
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Purpose: These Cookies identify if users have accepted the use
                of cookies on the Website.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Functionality Cookies
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Type: Persistent Cookies
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Administered by: Us
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Purpose: These Cookies allow us to remember choices You make
                when You use the Website, such as remembering your login details
                or language preference. The purpose of these Cookies is to
                provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the
                Website.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Tracking and Performance Cookies
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Type: Persistent Cookies
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Administered by: Third-Parties
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Purpose: These Cookies are used to track information about
                traffic to the Website and how users use the Website. The
                information gathered via these Cookies may directly or
                indirectly identify you as an individual visitor. This is
                because the information collected is typically linked to a
                pseudonymous identifier associated with the device you use to
                access the Website. We may also use these Cookies to test new
                pages, features or new functionality of the Website to see how
                our users react to them.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                For more information about the cookies we use and your choices
                regarding cookies, please visit our Cookies Policy or the
                Cookies section of our Privacy Policy.
              </p>
              <h3
                style={{
                  marginTop: '20px'
                }}
              >
                Use of Your Personal Data
              </h3>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                The Company may use Personal Data for the following purposes:
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                To provide and maintain our Service, including to monitor the
                usage of our Service.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                To manage Your Account: to manage Your registration as a user of
                the Service. The Personal Data You provide can give You access
                to different functionalities of the Service that are available
                to You as a registered user.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                For the performance of a contract: the development, compliance
                and undertaking of the purchase contract for the products, items
                or services You have purchased or of any other contract with Us
                through the Service.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                To contact You: To contact You by email, telephone calls, SMS,
                or other equivalent forms of electronic communication, such as a
                mobile application's push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                To provide You with news, special offers and general information
                about other goods, services and events which we offer that are
                similar to those that you have already purchased or enquired
                about unless You have opted not to receive such information.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                To manage Your requests: To attend and manage Your requests to
                Us.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                For business transfers: We may use Your information to evaluate
                or conduct a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of Our
                assets, whether as a going concern or as part of bankruptcy,
                liquidation, or similar proceeding, in which Personal Data held
                by Us about our Service users is among the assets transferred.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                For other purposes: We may use Your information for other
                purposes, such as data analysis, identifying usage trends,
                determining the effectiveness of our promotional campaigns and
                to evaluate and improve our Service, products, services,
                marketing and your experience.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                We may share Your personal information in the following
                situations:
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · With Service Providers: We may share Your personal information
                with Service Providers to monitor and analyze the use of our
                Service, for payment processing, to contact You.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · For business transfers: We may share or transfer Your personal
                information in connection with, or during negotiations of, any
                merger, sale of Company assets, financing, or acquisition of all
                or a portion of Our business to another company.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · With Affiliates: We may share Your information with Our
                affiliates, in which case we will require those affiliates to
                honor this Privacy Policy. Affiliates include Our parent company
                and any other subsidiaries, joint venture partners or other
                companies that We control or that are under common control with
                Us.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · With business partners: We may share Your information with Our
                business partners to offer You certain products, services or
                promotions.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · With other users: when You share personal information or
                otherwise interact in the public areas with other users, such
                information may be viewed by all users and may be publicly
                distributed outside. If You interact with other users or
                register through a Third-Party Social Media Service, Your
                contacts on the Third-Party Social Media Service may see Your
                name, profile, pictures and description of Your activity.
                Similarly, other users will be able to view descriptions of Your
                activity, communicate with You and view Your profile.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                · With Your consent: We may disclose Your personal information
                for any other purpose with Your consent.
              </p>
              <h3
                style={{
                  marginTop: '20px'
                }}
              >
                Retention of Your Personal Data
              </h3>

              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of Our Service, or We
                are legally obligated to retain this data for longer time
                periods.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyPolicyLanding;
