import { collection, doc, Firestore, getDoc, getDocFromCache } from 'firebase/firestore';
import AppUser from './AppUser';
import { Constant } from './Constant';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from './firebase-config';
import { getFirestore } from 'firebase/firestore';

export function getHeader() {
  // let appUsrObj = AppUser.getInstance();
  let headers = {};
  let token = localStorage.getItem('accessEsoToken');
  if (token && token.length > 0) {
    headers = {
      Authorization:'Bearer'+ ' ' + token,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
  } else {
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
  }
  return headers;
}

export const getUserNode = chatNode => {
  // console.log(chatNode,"chatNode")
  const executor = async (resolve, reject) => {
    try {
      const query = doc(db, Constant.USERS, chatNode);
      // console.log(query,'query')
      const snapshot = await getDoc(query);
      if (snapshot.empty) {
        resolve({});
        return;
      }
      resolve(snapshot.data());
    } catch (error) {
      reject(error);
    }
  };
  return new Promise(executor);
};

export function getPutHeader() {
  let appUsrObj = AppUser.getInstance();
  let headers = {};
  let token = appUsrObj.token;
  if (token && token.length > 0) {
    headers = {
      Authorization: 'Bearer ' + token,
      Accept: 'application/json',
      'Content-type': 'application/json'
    };
  } else {
    headers = {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    };
  }
  return headers;
}

// Tue Oct 10 2023 14:34:28 GMT+0530 (India Standard Time), Hour

export function dayDate(date) {
  let d = date.toString().split(" ")[0];
  let mm = date.toString().split(" ")[1];
  let dd = date.toString().split(" ")[2];
  let yy = date.toString().split(" ")[3];
  return d + ", " + mm + " " + dd + ", " + yy; 
}