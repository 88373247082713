import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { hitCreateUserAPI, hitVerifyOtpAPI } from 'redux/actions/SignupActions';
import { hitSendOTP,hitWaitlistAPI } from 'redux/actions/LoginActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import WaitlistLayout from 'layouts/WaitlistLayout';

const WaitlistComp = props => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    countryCode: '',
    phoneNumber: '',
    email: '',
    device: '',
  });

  const handlePhoneChange = (value, data) => {
    setFormData({
      ...formData,
      phoneNumber: value.slice(data.dialCode.length),
      countryCode: '+' + data.dialCode
    });
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === 'picURL' ? e && e.target.files[0] : e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log(formData)
    let deviceType=''
    if(formData.device=='1'){
      deviceType='Android'
    }
    else if(formData.device=='2'){
      deviceType='IOS'
    }
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.countryCode ||
      !formData.phoneNumber ||
      !formData.email ||
      !formData.device  ||
      !formData.isAccepted
    ) {
      toast.error('Form is not completed');
    } else {
      console.log(formData)
      props
        .hitWaitlistAPI(
          formData.firstName,
          formData.lastName,
          formData.countryCode,
          formData.phoneNumber,
          formData.email,
          deviceType
        )
        .then(data => {
          console.log(data)
          if (!data.error && data.code === 200) {
            navigate('/');
            toast.success(data.message, {
              theme: 'colored'
            });
          }
          if (
            data.error ||
            data.statusCode === 400 ||
            data.statusCode === 404 ||
            data.statusCode === 403 ||
            data.statusCode === 401
          ) {
            toast.error(data.message);
          } else {
            toast.error(data.error);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <WaitlistLayout>
      <Card>
        <FalconCardHeader title="Waitlist Form" />
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={formData.firstName}
                  name="firstName"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={formData.lastName}
                  name="lastName"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={12} controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email Address"
                  value={formData.email}
                  name="email"
                  onChange={handleChange}
                />
              </Form.Group>
              </Row>

              <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={12} controlId="phone">
                <Form.Label>Contact Number</Form.Label>
                <PhoneInput
                  placeholder="Enter phone number"
                  country={'us'}
                  value={''}
                  onChange={handlePhoneChange}
                />
              </Form.Group>
              </Row>

          
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="device">
                <Form.Label>Mobile device</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  name="device"
                  aria-label="Default select example"
                >
                  <option>Select Device</option>
                  <option value="1">Android</option>
                  <option value="2">Iphone</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            checked={formData.isAccepted}
            onChange={e =>
              setFormData({
                ...formData,
                isAccepted: e.target.checked
              })
            }
          />
          <Form.Check.Label className="form-label">
          I have read, understand, and agree to the website Terms and Conditions.
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
            <div className="text-end mt-2">
              <Button variant="primary" type="submit">
                Unlock Meaningful Connections
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </WaitlistLayout>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitCreateUserAPI,
      hitSendOTP,
      hitVerifyOtpAPI,
      hitWaitlistAPI
    },
    dispatch
  );
}
let Waitlist = connect(
  mapStateToProps,
  mapDispatchToProps
)(WaitlistComp);

export default Waitlist;
