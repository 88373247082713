import ApiFetch from '../../apimanager/ApiFetch';
import {
  QUESTIONAIRE, QUESTIONAIRE_BY_QUESTION_ID, QUESTIONAIRE_POST, UNANSWERED_QUESTIONS, UPDATE_QUESTIONAIRE_ANSWERES,
} from '../../apimanager/ApiEndpoints';

export const hitQuestionaireData = () => dispatch => {
  return ApiFetch.fetchGet(QUESTIONAIRE, {})
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror hitQuestionaire Action', err);
    });
};

export const postQuestionaireData = (data) => dispatch => {
  const parameters = {
    QuestionnaireList: data,
  };
  return ApiFetch.fetchPost(QUESTIONAIRE_POST, JSON.stringify(parameters))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror Questionaire Post Response Action', err);
    });
}

export const getQuestionaireById = (questionId) => dispatch => {
  return ApiFetch.fetchGet(QUESTIONAIRE_BY_QUESTION_ID + `?QuestionId=${questionId}`)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error Questionaire by question id Action', err);
    })
}

export const updateQuestionaireAnswers = (questionId, responseIds) => dispatch => {
  const parameters = {
    QuestionId: questionId,
    ResponseIds: responseIds
  }
  return ApiFetch.fetchPatch(UPDATE_QUESTIONAIRE_ANSWERES, JSON.stringify(parameters))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror Update Questionaire Answers Action', err);
    });
}

export const getUnAnsweredQuestions = () => dispatch => {
  const parameters = {}
  return ApiFetch.fetchGet(UNANSWERED_QUESTIONS, {})
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error Un-Answered Questions Action', err);
    })
}

export const postUnAnsweredQuestions = (data) => dispatch => {
  const parameters = {
    QuestionnaireList: data,
  };

  console.log(data);
  return ApiFetch.fetchPost(UNANSWERED_QUESTIONS, JSON.stringify(parameters))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror Questionaire Un-Answered Post Response Action', err);
    });
}