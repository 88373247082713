import React, { useEffect, useState } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import '../re-assessment/scrollBar.css';
import {
  assessmentListing,
  assessmentQuestionUserListing
} from '../../../../redux/actions/AssessmentActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import arrow from '../../../../assets/logo/arrow-right-solid.svg'

const ScrollUserImage = props => {
  const [arr, setArr] = useState([]);

  useEffect(() => {
    for (let i = 1; i <= 4; i++) {
      props
        .assessmentListing(i)
        .then(data => {
          if (!data.error && data.code === 200) {
            data.data[0].QuestiondDetails.map(value => {
              props
                .assessmentQuestionUserListing(10, 1, value.QuestionId)
                .then(data2 => {
                  setArr(prevArray => [
                    ...prevArray,
                    {
                      QuestionId: value.QuestionId,
                      question: `${
                        data2?.data?.Percentage ? data2.data?.Percentage : 0
                      } % of people prefers  ${value.Description}`,
                      userDetials: data2.data?.UserDetails
                    }
                  ]);
                })
                .catch(error => {
                  toast.error(error);
                });
            });
          } else {
            toast.error(data.error);
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }
  }, []);

  return (
    <div>
      <div>
        <h2 className=" mb-5 d-flex justify-content-center">Assessment</h2>
      </div>
      {arr?.map((data, index) => (
        <div key={index}>
          <h3 className="mb-4 mt-3">{data.question}</h3>
          <div style={{
              display: 'flex'}}>
          <div
            className="image-scroll-container"
            style={{
              display: 'flex',
              overflowX: 'auto',
              scrollBehavior: 'smooth',
              whiteSpace: 'nowrap',
              scrollbarWidth: 'none'
            }}
          >
            <Container className="d-flex flex-nowrap">
              {data?.userDetials?.map((imageUrl, imageIndex) => (
                <div key={imageIndex}>
                  <Image
                    style={{
                      width: '100px',
                      height: '100px',
                      marginRight: '20px',
                      borderRadius: '50%'
                    }}
                    resizeMode='contain'
                    src={imageUrl.ProfilePicture}
                    alt={`Image ${imageIndex}`}
                  />
                </div>
              
              ))}
            </Container>
            
          </div>
        <img
          src={arrow}
          style={{ marginTop:'3%', width: '40px', height: '40px', padding: '5px' }}
        />
          </div>
          <div className="d-flex justify-content-center mt-4 mb-4">
            {
              <Link to={`/questionaire/ReAnswer/${data.QuestionId}`}>
                <Button variant="primary">Re-Answer</Button>
              </Link>
            }
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      assessmentListing,
      assessmentQuestionUserListing
    },
    dispatch
  );
}
let ImageScroll = connect(mapStateToProps, mapDispatchToProps)(ScrollUserImage);

export default ImageScroll;
