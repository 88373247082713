import React from 'react';
import ReAnswerWizard from './Re-AnswerWizard';

export default function ReAnswerAssessment() {
  return (
    <>
      <ReAnswerWizard variant="pills" progressBar={true} />
    </>
  );
}
