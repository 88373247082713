import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import FeedProvider from './FeedProvider';
import FeedContent from './FeedContent';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getMatchingListData} from '../../../../redux/actions/MatchingListActions';

const FeedScreen = (props) => {

  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [range, setRange] = useState(3);
  const [declineUserIds, setDeclineUserIds] = useState([]);

  useEffect(() => {
    getMatchingList();
  }, []);

  const getMatchingList = () => {
    props
      .getMatchingListData(limit, pageNo, range, declineUserIds)
      .then(response => {
        if (response?.code === 200) {
          console.log(response)
          // setTotalMatches(response?.data.length);
          // setUserMatched(response?.data);
        }
      });
  };

  return (
    <FeedProvider>
      <Row className="g-0 g-lg-3">
        <Col lg={11}>
          <FeedContent />
        </Col>
        {/* <Col lg={4}>
          <BirthdayNotice
            name="Emma Watson"
            profileLink="/user/profile"
            className="mb-3"
          />
          <AddToFeed />
          <Events cardTitle="You may interested" events={events.slice(2, 5)} />
        </Col> */}
      </Row>
    </FeedProvider>
  );
};

const mapStateToProps = state => {
  return {};
};


function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMatchingListData,
    },
    dispatch,
  );
}

let Feed = connect(mapStateToProps, mapDispatchToProps)(FeedScreen);

export default Feed;

