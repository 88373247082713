import { SAVE_USERDATA } from '../ActionType'

// initial state for login
const INITIAL_STATE = {
   userData:{}
}

const homeReducer = (state, action) => {
    if (typeof state === 'undefined') {
        return INITIAL_STATE;
    }
    switch (action.type) {
        case SAVE_USERDATA:
            return {
                ...state,
                userData: action.payload
            }
      
        default:
            return state;
    }
}
export default homeReducer;