import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Carousel, Button } from 'react-bootstrap';
import FalconComponentCard from 'components/common/FalconComponentCard';
import FalconReactRange from 'components/doc-components/FalconReactRange';
import FeedActionButtons from 'components/app/social/feed/FeedActionButtons';
import { faHandPointUp } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { getMatchingListData } from 'redux/actions/MatchingListActions';
import {subscriptionType } from 'redux/actions/ProfileActions'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import RefreshError from 'components/errors/pageRefresh';

const HomeScreen = props => {
  const [loading, setLoading] = useState(true)
  const [matchListData, setMatchListData] = useState([]);
  const [values, setValues] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [decDisbale, setDecDisable] = useState(true);
  const [incDisbale, setIncDisable] = useState(false);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    console.log(matchListData,"llll");
    getSubscription()
    fetchData(props);
    if (pageNumber === 1) {
      setDecDisable(true);
    }
  }, [pageNumber]);

  const getSubscription = () => {
    props
      .subscriptionType()
      .then(response => {
        console.log(response?.data?.SubscriptionType,"ppppppppppp")
        if (response.code === 200) {
          localStorage.setItem(
            'subscriptionType',
            response?.data?.SubscriptionType
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const fetchData = async props => {
    setLoading(true);
    props
      .getMatchingListData(limit, pageNumber, '', 0)
      .then(data => {
        if (data.code === 200) {
          if (data.data.length === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1);
            setIncDisable(true);
          }
          setMatchListData(data.data.result);
          setLoading(false);
        }
      })
      .catch(error => {
        toast.error(error);
        setLoading(false);
      });
  };
  const decPage = () => {
    setPageNumber(pageNumber - 1);
    setIncDisable(false);
  };

  const incPage = () => {
    setPageNumber(pageNumber + 1);
    setDecDisable(false);
  };

  const navigate = useNavigate();
  const handleClick = id => {
    navigate(`/user/compatibility-category/${id}`);
  };
  const handleClick2 = id => {
    navigate(`/user-profile/${id}`);
  };

  return (
   
    <>
     {loading ? <>loading...</> : 
     matchListData.length > 0  ? <>
     <Row className="justify-content-between">
              <Col>
                <FalconComponentCard>
                  <FalconComponentCard.Body>
                    <span
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                    Your community refreshes daily. Remember, it’s about quality, not quantity!
                    </span>
                  </FalconComponentCard.Body>
                </FalconComponentCard>
              </Col>
            </Row>
      <Card.Header className="bg-light">
        {matchListData?.map(data => (
          <div>
            <div
              style={{
                boxShadow: '0 4px 4px rgba(1, 2, 4, 5.0)'
              }}
            ></div>
            <Row className="justify-content-between">
              <Col>
                <FalconComponentCard>
                  <FalconComponentCard.Body>
                    <FalconReactRange
                      
                      values={[data.Percentage]}
                      variant="primary"
                      onChange={val => setValues(data.Percentage)}
                    />
                    <div
                      style={{
                        display: 'inline-block',
                        position: 'relative',
                        cursor: 'pointer',
                       

                      }}
                      onClick={() => handleClick(data.Id)}
                    >
                      <div
                        className="oval-box"
                        style={{
                          width: 'auto',
                          height: 'auto',
                          backgroundColor: 'white',
                          borderRadius: '40px',
                          padding: '10px',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                          position: 'relative',
                          display: 'inline-block',
                          marginLeft: '25px'
                        }}
                      >
                        <span
                          style={{ whiteSpace: 'nowrap',  }}
                          className="slider-text"
                        >
                          {data.Percentage}% See Assessment Result
                        </span>
                      </div>

                      <div
                        style={{
                          content: '',
                          position: 'absolute',
                          width: 0,
                          height: 0,
                          borderBottom: '15px solid white',
                          borderLeft: '10px solid transparent',
                          borderRight: '10px solid transparent',
                          top: '-20px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                        }}
                      ></div>
                    </div>
                  </FalconComponentCard.Body>
                  <FalconComponentCard.Body>
                    <FontAwesomeIcon icon={faHandPointUp} className="me-2" />
                    <span
                      style={{
                        cursor: 'pointer'
                      }}
                      onClick={() => handleClick2(data.Id)}
                    >
                      You are Hit! Checkout <b>{data.Name}</b>!
                    </span>
                  </FalconComponentCard.Body>
                </FalconComponentCard>
              </Col>
            </Row>
            <Row className="justify-content-between">
              <Col>
                <FalconComponentCard>
                  <FalconComponentCard.Body>
                    {data.ProfilePics.map(items => (
                      <Carousel>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src={items}
                            alt="First slide"
                          />
                        </Carousel.Item>
                      </Carousel>
                    ))}
                  </FalconComponentCard.Body>
                </FalconComponentCard>
              </Col>
            </Row>
            <Row>
              <Col>
                <FalconComponentCard>
                  <FalconComponentCard.Body>
                    {console.log(data)}
                    <FeedActionButtons
                      id={'1'}
                      reactions={{}}
                      details={{
                        DOB: data?.DateOfBirth,
                        Height: data?.Height,
                        Location: data?.Location,
                        UserId: data?.Id,
                        LikeedUserId: data?.LikeedUserId,
                        Name: data?.Name,
                        profilePic: data?.ProfilePics[0]
                      }}
                    />
                  </FalconComponentCard.Body>
                </FalconComponentCard>
              </Col>
            </Row>
          </div>
        ))}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            disabled={decDisbale}
            onClick={decPage}
            style={{
              marginRight: '5px'
            }}
          >
            Prev
          </Button>

          <Button disabled={incDisbale} onClick={incPage}>
            Next
          </Button>
        </div>
      </Card.Header>
      </> : <>
      <RefreshError></RefreshError>
      </>}
    
     
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMatchingListData,
      subscriptionType
    },
    dispatch
  );
}
let Home = connect(mapStateToProps, mapDispatchToProps)(HomeScreen);

export default Home;
