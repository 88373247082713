import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import { Nav } from 'react-bootstrap';
import moment from 'moment';

const ChatThread = ({ thread, index, callDetailsUser, userid, selected, setSelected }) => {
  // console.log(thread, index, 'jhgfdfghjkjhgf876543w');

  const callDetails = () => {
    callDetailsUser(thread);
    // console.log(thread);
    // console.log(index);
    setSelected(true);
  }

  // console.log(thread);

  return (
    <Nav.Link
      eventKey={index}
      className={thread.sender === userid || thread.receiver === userid || selected ? classNames(`chat-contact hover-actions-trigger p-3`) : classNames(`chat-contact1 hover-actions-trigger p-3`)}
      onClick={() => callDetails()}
    >
      <div className="d-md-none d-lg-block">
        {/* <ChatSidebarDropdownAction /> */}
      </div>
      <Flex>
        <Avatar src={thread?.user?.profilePic} size="xl" />
        <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
          <Flex justifyContent="between">
            <h6 className="mb-0 chat-contact-title">{thread?.user?.username}</h6>
            <span className="message-time fs--2">
              {' '}
              {moment
                .utc(thread?.createdAt)
                .local()
                .startOf('seconds')
                .fromNow()}{' '}
            </span>
          </Flex>
          <div className="min-w-0">
            <div className="chat-contact-content pe-3">
              <h6 className="mb-0 chat-contact-title" style={{color: "gray", marginTop: '1%'}}>{thread?.lastMessage}</h6>
            </div>
          </div>
        </div>
      </Flex>
    </Nav.Link>
  );
};

ChatThread.propTypes = {
  thread: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default ChatThread;
