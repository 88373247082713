import React, { useEffect, useState } from 'react';
import ProfileBanner from '../ProfileBanner';
import coverSrc from 'assets/img/generic/4.jpg';
import avatar from 'assets/img/team/2.jpg';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from './ProfileSettings';
import DangerZone from './DangerZone';
import ImageCarousel from './ImageCarousel';
import {
  hitUserDetailAPI,
  hitGetUserPicturesAPI
} from 'redux/actions/ProfileActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import FeedOnProfile from './FeedOnProfile';

const SettingsScreen = props => {
  const [imageURLS, setImageURLS] = useState([]);
  const [formData, setFormData] = useState({
    profilePic: ''
  });

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    props.hitUserDetailAPI().then(data => {
      if (!data.error && data.code === 200) {
        const date = new Date(Number(data.data.DateOfBirth));
        const day = date.getDate().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        });
        const month = (date.getMonth() + 1).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        });
        const year = date.getFullYear();

        const formattedDate = `${year}-${month}-${day}`;
        data.data.DateOfBirth = formattedDate;
        props.hitGetUserPicturesAPI(data.data.UserId).then(data => {
          if (!data.error && data.code === 200) {
            setImageURLS(data.data);
          } else {
            toast.error(data.error);
          }
        });

        setFormData({
          profilePic: data.data.ProfilePic
        });
      } else {
        toast.error(data.error);
      }
    });
  };

  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={coverSrc}
          avatar={formData.profilePic}
          className="mb-8"
        />
      </ProfileBanner>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <ImageCarousel images={imageURLS} />
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col lg={12}>
          <FeedOnProfile />
        </Col>
      </Row>
      
      <Row className="g-3">
        <Col lg={8}>
          <ProfileSettings />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <DangerZone />
          </div>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitUserDetailAPI,
      hitGetUserPicturesAPI
    },
    dispatch
  );
}
let Settings = connect(mapStateToProps, mapDispatchToProps)(SettingsScreen);

export default Settings;
