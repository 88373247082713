import React from 'react';
import { Button } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';

import Flex from 'components/common/Flex';
import { useState } from 'react';

import { Link } from 'react-router-dom';
import { Card, Col, Row, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import sad from 'assets/img/emoji/emoji1.png';
import confused from 'assets/img/emoji/emoji2.png';
import neutal from 'assets/img/emoji/emoji3.png';
import happy from 'assets/img/emoji/emoji4.png';
import vhappy from 'assets/img/emoji/emoji5.png';
import ysad from 'assets/img/emoji/ysad.png';
import yconfused from 'assets/img/emoji/yconfused.png';
import yneutral from 'assets/img/emoji/yneutral.png';
import ysmile from 'assets/img/emoji/ysmile.png';
import ylaugh from 'assets/img/emoji/ylaugh.png';
import { useLongPress } from 'react-use';

import { userFeedback } from '../../redux/actions/SidebarActions';

import { toast } from 'react-toastify';
import { Alert } from 'bootstrap';

const EmojiFeedback = props => {
  const [comment, setCommentValue] = useState('');
  const [hoveredImage, setHoveredImage] = useState(null);

  const handleChange = e => {
    setCommentValue(e.target.value);
  };

  const charCount = comment.length;
  const maxChars = 200;

  const handleTap = index => {
    setHoveredImage(prevSelectedImage => {
      if (prevSelectedImage === index) {
        return null;
      } else {
        return index;
      }
    });
  };

  const handleMouseEnter = index => {
    setHoveredImage(index);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const feedbackEmoji = [
    {
      title: 'Sad',
      image: sad
    },
    {
      title: 'Confused',
      image: confused
    },
    {
      title: 'Neutal',
      image: neutal
    },
    {
      title: 'Happy',
      image: happy
    },
    {
      title: 'Laugh',
      image: vhappy
    }
  ];

  const replacedEmoji = [
    {
      title: 'Sad',
      image: ysad
    },
    {
      title: 'Confused',
      image: yconfused
    },
    {
      title: 'Neutal',
      image: yneutral
    },
    {
      title: 'Happy',
      image: ysmile
    },
    {
      title: 'Laugh',
      image: ylaugh
    }
  ];

  const longPressEvent = useLongPress(handleTap);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    if (hoveredImage == null) {
      toast.error('Please select any feedback');
    } else if (comment == '') {
      toast.error('Please enter the feedback');
    } else {
      props
        .userFeedback(hoveredImage + 1, comment)
        .then(data => {
          if (!data.error && data.code === 200) {
            toast.success(data.message, {
              theme: 'colored'
            });
            setHoveredImage(null)
            setCommentValue('')
          }
          if (
            data.error ||
            data.statusCode === 400 ||
            data.statusCode === 404 ||
            data.statusCode === 403
          ) {
            toast.error(data.message);
          } else {
            toast.error(data.error);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <PageHeader title="Feedback" description="" className="mb-3"></PageHeader>

      <Card className="mb-3">
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="g-0">
              <Col xs={12} className="text-center">
                {feedbackEmoji.map((item, index) => (
                  <Link to="#!" key={index}>
                    <Flex alignItems="center" inline="d-inline-flex">
                      <div
                        className="image-container"
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        {...longPressEvent}
                      >
                        <img
                          className="image me-4 fw-4"
                          src={
                            hoveredImage === index
                              ? replacedEmoji[index].image
                              : item.image
                          }
                          alt={replacedEmoji[index].image}
                          width={80}
                        />
                      </div>
                    </Flex>
                  </Link>
                ))}

                <div className="mt-3 fw-bold ">
                  Tell us about your experience!
                </div>

                <Form.Group>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    className="mb-2 mt-3"
                    placeholder="Enter your suggestion or feedback here..."
                    maxLength={200}
                    value={comment}
                    onChange={handleChange}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      right: '1.0rem'
                    }}
                  >
                    {charCount}/{maxChars}
                  </div>
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-3">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      userFeedback
    },
    dispatch
  );
}
let EmojiMartExample = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmojiFeedback);
export default EmojiMartExample;
