import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import ChatContentHeader from './ChatContentHeader';
import threads from 'data/chat/threads';
import ChatContentBody from './ChatContentBody';
import MessageTextArea from './MessageTextArea';


const ChatContent = ({ setHideSidebar, message, onSend, userChatId, details }) => {

  const onSendMessage = (sentMessage) => {
    onSend(sentMessage);
  }

  return (
    <Tab.Content className="card-chat-content">
      {threads.map((thread, index) => (
        <Tab.Pane key={index} eventKey={index} className="card-chat-pane">
          <ChatContentHeader user={details} setHideSidebar={setHideSidebar} />
          <ChatContentBody thread={thread} userDetails={details} id={12} message={message} userChatId={userChatId}  />
        </Tab.Pane>
      ))}
      <MessageTextArea onSendMessage={onSendMessage}/>
    </Tab.Content>
  );
};

ChatContent.propTypes = {
  setHideSidebar: PropTypes.func.isRequired
};

export default ChatContent;
