import React, { useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hitSendSupportData } from '../../redux/actions/LoginActions';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

const SupportScreen = props => {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    reason: ''
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();
    const supportForm = {
      name: formData.firstName,
      email: formData.email,
      reason: formData.reason
    };
    props
      .hitSendSupportData(supportForm)
      .then(data => {
        if (!data.error && data.code === 200) {
          setLoading(false);
          toast.success(data.message);
          setFormData({
            email: '',
            firstName: '',
            reason: ''
          });
        } else {
          toast.error(data.error);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group as={Col} lg={12} controlId="firstName" className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Name"
          value={formData.firstName}
          name="firstName"
          onChange={handleFieldChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {props.hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
          placeholder="Email"
        />
      </Form.Group>

      <Form.Group as={Col} lg={12} controlId="reason" className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          placeholder="Description"
          value={formData.reason}
          name="reason"
          onChange={handleFieldChange}
        />
      </Form.Group>

      <Form.Group>
        {!loading && (
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={
              !formData.email || !formData.reason || !formData.firstName
            }
          >
            Submit
          </Button>
        )}
        {loading && (
          <span style={{ display: 'block', textAlign: 'center' }}>
            <Spinner animation="border" role="status" className="flex-center">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </span>
        )}
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitSendSupportData
    },
    dispatch
  );
}
let SupportForm = connect(mapStateToProps, mapDispatchToProps)(SupportScreen);

export default SupportForm;
