/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import MultiSelect from 'components/common/MultiSelect';

export default function MultiSelectForm(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const organizerOptions = props.data.Options;

  const modifiedData = organizerOptions.map(element => {
    return { ...element, value: element.AnswerId, label: element.Option };
  });

  const handleSelectChange = e => {
    console.log(e);
    setSelectedOptions(e);
    const questionId = props.data.QuestionId;
    props.addQuestion(questionId, e, props.style);
  };

  return (
    <Form.Group className="mb-3">
      <Flex className="flex-between-center">
        <Form.Label>{props.data.Description}</Form.Label>
      </Flex>
      <MultiSelect
        closeMenuOnSelect={false}
        isMulti
        options={modifiedData}
        placeholder="Select your preferences"
        onChange={handleSelectChange}
        value={selectedOptions}
      />
    </Form.Group>
  );
}
