import Flex from 'components/common/Flex';
import { ChatContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Card, Tab } from 'react-bootstrap';
import ChatProvider from './ChatProvider';
import ChatContent from './content/ChatContent';
import ChatSidebar from './sidebar/ChatSidebar';
import { getUserDetails } from 'redux/actions/ProfileActions';
import {
  query,
  collection,
  onSnapshot,
  where,
  or,
  doc,
  addDoc,
  setDoc
} from 'firebase/firestore';
import { db } from '../../../utils/firebase-config';
import { useParams } from 'react-router-dom';
import { Constant } from 'utils/Constant';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendNotificationForChat } from '../../../redux/actions/ProfileActions';

const ChatTab = props => {
  const {
    setIsOpenThreadInfo,
    threadsDispatch,
    threads,
    setCurrentThread,
    setScrollToBottom
  } = useContext(ChatContext);
  const [hideSidebar, setHideSidebar] = useState(false);
  const userChatId = localStorage.getItem('UserChatId');
  const userData = localStorage.getItem('userData');
  const [chatData, setChatData] = useState([]);
  const messageRef = collection(db, 'CHAT_LIST');
  let mylistener;
  const [details, setDetails] = useState('');
  const { id, name, profilePic } = useParams();
  const [selected, setSelected] = useState(false);
  const [list, setList] = useState([]);
  const [treadId, setThreadId] = useState([]);
  const { sendNotification } = props;

 

  const whomToTalk = id
    ? parseInt(id)
    : parseInt(localStorage.getItem('whomToTalk'));
  let userId = parseInt(userChatId);

  const getUserList = async () => {
    mylistener = query(
      messageRef,
      or(where('sender', '==', userId), where('receiver', '==', userId))
    );
    onSnapshot(mylistener, QuerySnapshot => {
      const chatList = [];
      QuerySnapshot.forEach(documentSnapshot => {
        chatList.push({
          lastMessage: documentSnapshot.data().lastMessage,
          createdAt: documentSnapshot.data().createdAt.toDate(),
          sender: documentSnapshot.data().sender,
          receiver: documentSnapshot.data().receiver,
          id: documentSnapshot.data().id,
          user: documentSnapshot.data().users
        });
      });
      getData(chatList);
    });
  };

  useEffect(() => {
    setReceiverId(parseInt(id));
    setDetails({
      id: parseInt(id),
      username: name,
      profilePic: `https://esobond-prod.s3.amazonaws.com/${profilePic}`
    });

    getUserList();
    if (id) {
      const mId = Number(id) > userId ? userId + '-' + id : id + '-' + userId;
      const unsubscribe = getMessagesList(mId, userId);
      return () => unsubscribe();
    }
    return () => {
      try {
        mylistener(); //here you need to remove the listener
      } catch (error) {
        console.log(error);
      }
    };
  }, [userData]);

  const [lastMsg, setLastMsg] = useState([]);
  const getData = async tempArray => {
    setChatData(tempArray);
  };

  const handleSelect = e => {
    setHideSidebar(false);
    setIsOpenThreadInfo(false);
    const thread = threads.find(thread => thread.id === parseInt(e));
    setCurrentThread(thread);
    threadsDispatch({
      type: 'EDIT',
      id: thread.id,
      payload: { ...thread, read: true }
    });
    setScrollToBottom(true);
  };

  const [receiverId, setReceiverId] = useState(0); //problem
  const [one, setOne] = useState(0);
  const [two, setTwo] = useState(1);

  const userDetails = (data1, data2) => {
    list.length = 0;
    let data2Id;
    data2.map(item => {
      if (item?.user?.id === data1?.user?.id) {
        data2Id = item?.user?.id;
      }
    });
    setDetails(data1?.user);
    setOne(data1?.id);
    setTwo(data1?.sender);
    getMessagesList(data1?.id, data1?.sender);
  };

  const getMessagesList = (userID, senderId) => {
    setThreadId(userID);
    const messageCollection = collection(
      doc(db, Constant.CHAT_LIST, userID),
      Constant.MESSAGES
    );
    const unsubscribe = onSnapshot(
      messageCollection,
      snapshot => {
        let messageList = [];
        snapshot.forEach(doc => {
          messageList.push({
            text: doc.data()?.lastMessage
              ? doc?.data()?.lastMessage
              : doc?.data()?.messages?.text,
            createdAt: doc?.data()?.createdAt
              ? doc?.data()?.createdAt?.toDate()
              : doc?.data()?.messages?.createdAt?.toDate(),
            user: doc?.data()?.users ? doc?.data()?.users : '--',
            _id: doc?.data()?.id ? doc?.data()?.id : '--',
            senderId: doc?.data().sender ? parseInt(doc?.data().sender) : '--',
            docId: doc?.id ? doc?.id : '--'
          });
        });

        messageList.sort((a, b) => {
          let dateA = new Date(a?.createdAt);
          let dateB = new Date(b?.createdAt);
          return dateA - dateB;
        });
        setList(messageList);
      },
      error => {
        console.error('Error fetching messages:', error);
      }
    );

    return unsubscribe;
  };

  const onSend = async message => {
    const userIdmain = id ? id : two;
    setReceiverId(parseInt(id));
    let mId = 0;
    if (treadId === 0) {
      mId =
        userIdmain > userChatId
          ? userChatId + '-' + userIdmain
          : userIdmain + '-' + userChatId;
    } else {
      mId = treadId;
    }

    try {
      await setDoc(doc(db, Constant.CHAT_LIST, mId), {
        createdAt: new Date(),
        id: mId,
        lastMessage: message,
        receiver: Number(whomToTalk),
        sender: Number(userId),
        users: details
      });
      await addDoc(
        collection(doc(db, Constant.CHAT_LIST, mId), Constant.MESSAGES),
        {
          createdAt: new Date(),
          id: mId,
          lastMessage: message,
          receiver: Number(whomToTalk),
          sender: Number(userId)
        }
      );

      const notificationMessage = `${name} have sent you a message.`
      const notificationTitle = 'New Message'
      const DeviceType = 'Website'


      if (sendNotification) {
    
        sendNotification(notificationMessage,notificationTitle, Number(whomToTalk), DeviceType);
    }
      // 

    } catch (e) {
      console.log('Error sending message: ', e);
    }
  };

  useEffect(() => {
    if (!id) {
      setHideSidebar(true);
    }

    getUserList();
    return () => {
      try {
        mylistener(); //here you need to remove the listener
      } catch (error) {
        console.log(error);
      }
    };
  }, []);

  // const sendNotification = (
  //   NotificationTitle,
  //   NotificationMessage,
  //   UserId,
  //   DeviceType
  // ) => {
  //   props
  //     .sendNotificationForChat(
  //       NotificationTitle,
  //       NotificationMessage,
  //       UserId,
  //       DeviceType
  //     )
  //     .then(response => {
  //       console.log("2323232");
  //       if (response?.code === 200) {
  //         console.log(response, 'pppppp');
  //       }
  //     });
  // };

  useEffect(() => {
    getUserDetails();
    return () => {
      try {
        mylistener();
      } catch (error) {
        console.log(error);
      }
    };
  }, []);

  return (
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey="0"
      onSelect={handleSelect}
    >
      <Card className="card-chat">
        <Card.Body as={Flex} className="p-0 h-100">
          <ChatSidebar
            hideSidebar={hideSidebar}
            userDetails={userDetails}
            userid={id}
            selected={selected}
            setSelected={setSelected}
          />
          {id || selected ? (
            <ChatContent
              setHideSidebar={setHideSidebar}
              message={list}
              onSend={onSend}
              userChatId={userId}
              details={details}
            />
          ) : (
            <p>{'Please select from list'}</p>
          )}
        </Card.Body>
      </Card>
    </Tab.Container>
  );
};

const ChatUserScreen = props => {



  const sendNotification = (
    NotificationTitle,
    NotificationMessage,
    UserId,
    DeviceType
  ) => {
    props
      .sendNotificationForChat(
        NotificationTitle,
        NotificationMessage,
        UserId,
        DeviceType
      )
      .then(response => {
        console.log("2323232");
        if (response?.code === 200) {
          console.log(response, 'pppppp');
        }
      });
  };

  return (
    <ChatProvider>
      <ChatTab sendNotification={sendNotification}/>
    </ChatProvider>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserDetails,
      sendNotificationForChat
    },
    dispatch
  );
}
let Chat = connect(mapStateToProps, mapDispatchToProps)(ChatUserScreen);

export default Chat;
