import freeIcon from 'assets/img/icons/free.svg';
import proIcon from 'assets/img/icons/pro.svg';

export const pricingData = [
  {
    id: 1,
    nameId: 'Platinum',
    title: 'Platinum Plan',
    subTitle: '1 Month',
    subscriptionTime: "Monthly",
    price: 49.99,
    subText:'$ 49.99 Total',
    url: '/pricing/payment',
    buttonText: 'Subscribe',
    isFeatured: false,
    featureTitle: 'Track team projects with free:',
    features: [
      {id: 1, title:"Up to 70% alignment with all identified Esobonds "},
      {id: 2, title:"Like user feeds  "},
      {id: 3, title: "Comment on user feeds "},
      {id: 4, title: "Chat  "},
      {id: 5, title: "Modify assessment preferences. "},
      {id: 6, title: "Report users "},
      {id: 7, title: "Block users"},
    ]

 
  },
  {
    id: 2,
    nameId: 'Platinum',
    title: 'Platinum Plan',
    subTitle: '3 Months',
    subscriptionTime: "Every-3-months",
    price: 40.0,
    url: '/pricing/payment',
    subText:'$ 120 Total',
    buttonText: 'Subscribe',
    isFeatured: false,
    featureTitle: 'Track team projects with free:',
    features: [
      {id: 1, title:"Up to 70% alignment with all identified Esobonds "},
      {id: 2, title:"Like user feeds  "},
      {id: 3, title: "Comment on user feeds "},
      {id: 4, title: "Chat  "},
      {id: 5, title: "Modify assessment preferences. "},
      {id: 6, title: "Report users "},
      {id: 7, title: "Block users"},
  ]
  },
  {
    id: 3,
    nameId: 'Platinum',
    title: 'Platinum Plan',
    subTitle: '6 Months',
    subscriptionTime: "Every-6-months",
    price: 35.0,
    url: '/pricing/payment',
    subText:'$ 210 Total',
    buttonText: 'Subscribe',
    isFeatured: false,
    featureTitle: 'Track team projects with free:',
    features: [
      {id: 1, title:"Up to 70% alignment with all identified Esobonds "},
      {id: 2, title:"Like user feeds  "},
      {id: 3, title: "Comment on user feeds "},
      {id: 4, title: "Chat  "},
      {id: 5, title: "Modify assessment preferences. "},
      {id: 6, title: "Report users "},
      {id: 7, title: "Block users"},
  ]
  },
  {
    id: 4,
    nameId: 'Gold',
    title: 'Gold Plan',
    subTitle: '1 Month',
    price: 69.99,
    subscriptionTime: "Monthly",
    subText:'$ 69.99 Total',
    url: '/pricing/payment',
    buttonText: 'Subscribe',
    isFeatured: true,
    featureTitle: 'Everything in Premium, plus:',
    features: [
      { id: 1, title: 'Enjoy all features in Platinum Plan ' },
      { id: 2, title: 'Up to 80% alignment with all identified Esobonds ' },
      { id: 3, title: 'See compatibility results for all identified Esobonds  ' },
      { id: 4, title: 'Be the first to know about new features.' },
    ]
    
  },
  {
    id: 5,
    nameId: 'Gold',
    title: 'Gold Plan',
    subTitle: '3 Months',
    subscriptionTime: "Every-3-months",
    price:60.0,
    url: '/pricing/payment',
    subText:'$  180 Total',
    buttonText: 'Subscribe',
    isFeatured: true,
    featureTitle: 'Everything in Premium, plus:',
    features: [
       { id: 1, title: 'Enjoy all features in Platinum Plan ' },
      { id: 2, title: 'Up to 80% alignment with all identified Esobonds ' },
      { id: 3, title: 'See compatibility results for all identified Esobonds  ' },
      { id: 4, title: 'Be the first to know about new features.' },
    ]
  },
  {
    id: 6,
    nameId: 'Gold',
    title: 'Gold Plan',
    subTitle: '6 Months',
    subscriptionTime: "Every-6-months",
    price: 55.0,
    url: '/pricing/payment',
    subText:'$ 330 Total',
    buttonText: 'Subscribe',
    isFeatured: true,
    featureTitle: 'Everything in Premium, plus:',
    features: [
       { id: 1, title: 'Enjoy all features in Platinum Plan ' },
      { id: 2, title: 'Up to 80% alignment with all identified Esobonds ' },
      { id: 3, title: 'See compatibility results for all identified Esobonds  ' },
      { id: 4, title: 'Be the first to know about new features.' },
    ]
  },
  {
    id: 7,
    nameId: 'Elite',
    title: 'Elite Plan',
    subTitle: '1 Month',
    price: 99.99,
    subscriptionTime: "Monthly",
    subText:'$ 99.99 Total',
    url: '/pricing/payment',
    buttonText: 'Subscribe',
    isFeatured: false,
    featureTitle: 'Everything in Business, plus:',
    features: [
      { id: 1, title: 'Enjoy all features in the Gold Plan  ' },
      { id: 2, title: 'Up to 90% - 100% alignment with all identified Esobonds ' },
      { id: 3, title: 'Become a featured user on our websites and social media platforms ' },
      { id: 4, title: 'Receive special offers and discounts' },
      { id: 5, title: 'Have the opportunity to participate in private Esobond hosted events ' },
    ]
  },
  {
    id: 8,
    nameId: 'Elite',
    title: 'Elite Plan',
    subTitle: '3 Months',
    subscriptionTime: "Every-3-months",
    price: 90.0,
    subText:'$ 270 Total',
    url: '/pricing/payment',
    buttonText: 'Subscribe',
    isFeatured: false,
    featureTitle: 'Everything in Business, plus:',
    features: [
      { id: 1, title: 'Enjoy all features in the Gold Plan  ' },
      { id: 2, title: 'Up to 90% - 100% alignment with all identified Esobonds ' },
      { id: 3, title: 'Become a featured user on our websites and social media platforms ' },
      { id: 4, title: 'Receive special offers and discounts' },
      { id: 5, title: 'Have the opportunity to participate in private Esobond hosted events ' },
    ]
  },
  {
    id: 9,
    nameId: 'Elite',
    title: 'Elite Plan',
    subTitle: '6 Months',
    price: 85.0,
    subscriptionTime: "Every-6-months",
    subText:'$ 510 Total',
    url: '/pricing/payment',
    buttonText: 'Subscribe',
    isFeatured: false,
    featureTitle: 'Everything in Business, plus:',
    features: [
      { id: 1, title: 'Enjoy all features in the Gold Plan  ' },
      { id: 2, title: 'Up to 90% - 100% alignment with all identified Esobonds ' },
      { id: 3, title: 'Become a featured user on our websites and social media platforms ' },
      { id: 4, title: 'Receive special offers and discounts' },
      { id: 5, title: 'Have the opportunity to participate in private Esobond hosted events ' },
    ]
  }
];

export const pricingAltData = [
  {
    id: 0,
    title: 'Free',
    price: 0,
    period: 'm',
    icon: freeIcon,
    url: '#!',
    buttonText: 'Start free trial',
    isFeatured: false,
    features: [
      { id: 0, title: 'Unlimited Broadcasts', isActive: true },
      { id: 1, title: 'Unlimited Sequences', isActive: true },
      { id: 2, title: 'Advanced marketing', isActive: true },
      { id: 3, title: 'Api & Developer Tools', isActive: true },
      { id: 4, title: 'Integrations', isActive: false },
      { id: 5, title: 'Payments', isActive: false },
      { id: 6, title: 'Unlimted Tags', isActive: false },
      { id: 7, title: 'Custom Fields', isActive: false }
    ]
  },
  {
    id: 1,
    title: 'Pro',
    price: 99,
    period: 'm',
    icon: proIcon,
    url: '#!',
    buttonText: 'Purchase Now',
    isFeatured: true,
    features: [
      { id: 0, title: 'Unlimited Broadcasts', isActive: true },
      { id: 1, title: 'Unlimited Sequences', isActive: true },
      { id: 2, title: 'Advanced marketing', isActive: true },
      { id: 3, title: 'Api & Developer Tools', isActive: true },
      { id: 4, title: 'Integrations', isActive: true },
      { id: 5, title: 'Payments', isActive: true },
      { id: 6, title: 'Unlimted Tags', isActive: true },
      { id: 7, title: 'Custom Fields', isActive: true }
    ]
  }
];
