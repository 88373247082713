import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ChatContentBodyIntro from './ChatContentBodyIntro';
import Message from './Message';
import SimpleBarReact from 'simplebar-react';
import ThreadInfo from './ThreadInfo';
import { ChatContext } from 'context/Context';
import {
  query,
  collection,
  onSnapshot,
  where,
  or
} from 'firebase/firestore';
import { db } from 'utils/firebase-config';
import { dayDate } from 'utils/Utils';

const ChatContentBody = ({ thread, userDetails, id, message, userChatId }) => {
  let lastDate = null;
  const messagesEndRef = useRef();

  const { getUser, messages, scrollToBottom, setScrollToBottom } =
    useContext(ChatContext);
  const user = getUser(thread);
  const { content } = messages.find(({ id }) => id === thread.messagesId);

  useEffect(() => {
    if (scrollToBottom) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
      setScrollToBottom(false);
    }
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight, // Scroll to the bottom of the page
        behavior: 'smooth',
      });
    }, 500);
    setScrollToBottom(false);
  }, [scrollToBottom]);

  return (
    <div className="chat-content-body" style={{ display: 'inherit' }}>
      {/* <ThreadInfo thread={thread} isOpenThreadInfo={true} /> */}
      <SimpleBarReact style={{ height: '100%' }}>
        <div className="chat-content-scroll-area">
          <ChatContentBodyIntro user={user} userDetails={userDetails} />
          {message?.map((item, index) => (
            <div key={index}>
              {/*lastDate !== item?.createdAt && (*/}
              <div className="text-center fs--2 text-500">{`${dayDate(item?.createdAt)}`}</div>
              {/*)*/}
              {/*(() => {
                lastDate = item?.createdAt;
              })()*/}
              <Message
                key={item?._id}
                message={item?.text}
                senderUserId={item?.user?._id ? item?.user?._id : item?.senderId}
                userChatId={userChatId}
              // time={time}
              // status={status}
              // isGroup={null}
              />
            </div>
          ))}
        </div>
        <div ref={messagesEndRef} />
      </SimpleBarReact>
    </div>
  );
};

ChatContentBody.propTypes = {
  thread: PropTypes.object.isRequired
};

export default ChatContentBody;
