import {SAVE_USERINFO} from '../ActionType';

// initial state for login

const INITIAL_STATE = {
  userInfo: {},
};

const profileReducer = (state, action) => {
  if (typeof state === 'undefined') {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case SAVE_USERINFO:
      return {
        ...state,

        userInfo: action.payload,
      };

    default:
      return state;
  }
};

export default profileReducer;
