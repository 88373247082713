import React, { useEffect, useState } from 'react';
import ProfileBanner from '../ProfileBanner';

import coverSrc from 'assets/img/generic/4.jpg';
import avatar from 'assets/img/team/2.jpg';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from '../settings/ProfileSettings';
import ImageCarousel from '../settings/ImageCarousel';
import FellowUserDetails from '../settings/FellowUserDetails';
import { useParams } from 'react-router-dom';
import {
  getUserDetails,
  hitGetUserPicturesAPI
} from 'redux/actions/ProfileActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import FeedUserProfile from '../settings/FeedUserProfile';

const FellowUserProfileScreen = props => {
  const [imageURLS, setImageURLS] = useState([]);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    height: '',
    dateOfBirth: ''
  });
  const { id } = useParams();
  useEffect(() => {
    getUserDetails(id);
    getUserPicturesAPI(id);
  }, []);

  const getUserDetails = async id => {
    props.getUserDetails(id).then(data => {
      if (!data.error && data.code === 200) {
        const date = new Date(Number(data.data.DateOfBirth));
        const day = date.getDate().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        });
        const month = (date.getMonth() + 1).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        });
        const year = date.getFullYear();

        const formattedDate = `${year}-${month}-${day}`;
        data.data.DateOfBirth = formattedDate;

        setFormData({
          firstName: data.data.First_Name,
          lastName: data.data.Last_Name,
          height: data.data.Height,
          dateOfBirth: formattedDate,
          profilePic: data.data.ProfilePic
        });
      } else {
        toast.error(data.error);
      }
    });
  };
  const getUserPicturesAPI = id => {
    props.hitGetUserPicturesAPI(id).then(data => {
      console.log(data);
      if (!data.error && data.code === 200) {
        setImageURLS(data.data);
      } else {
        toast.error(data.error);
      }
    });
  };

  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={coverSrc}
          avatar={formData.profilePic}
          className="mb-8"
        />
      </ProfileBanner>

      <Row className="g-3">
        <Col lg={8}>
          <FellowUserDetails
            firstName={formData.firstName}
            lastName={formData.lastName}
            height={formData.height}
            dateOfBirth={formData.dateOfBirth}
          />
        </Col>
      </Row>

      <Row className="g-3 mt-2">
        <Col lg={8}>
          <FeedUserProfile userId={id} />
        </Col>
      </Row>

      <Row>
        <Row className="g-3 mb-3">
          <Col lg={12}>
            <ImageCarousel images={imageURLS} />
          </Col>
        </Row>
      </Row>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserDetails,
      hitGetUserPicturesAPI
    },
    dispatch
  );
}
let FellowUserProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(FellowUserProfileScreen);

export default FellowUserProfile;
