import React, { useEffect, useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Accordion, Card } from 'react-bootstrap';
import { faqDoc } from 'data/faqs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FaqAccordionItem from 'components/pages/faq/faq-accordion/FaqAccordionItem';
import { hitFAQAPI } from '../../redux/actions/SidebarActions';

const FAQScreen = (props) => {
  // const [faqs] = useState(faqDoc);
  const [faqs, setFaqData] = useState([]);
  const [loader, setLoader] = useState(true);
  let Faqs = [];

  useEffect(() => {
    getFAQData()
  }, []);


  const getFAQData = () => {
    setLoader(true);
    props
      .hitFAQAPI()
      .then(resp => {
        if (resp.code == 200) {
          resp.data.map((item, index) => {
            Faqs.push({
              id: index,
              question: item.Question,
              answer: item.Answer,
            });
          });
          setLoader(false);
        } else {
          setLoader(false);
        }
        setFaqData(Faqs);
      })
      .catch(err => {
        console.log('FAQs error', err);
      });
  };

  return (
    <>
      <PageHeader
        title="Frequently Asked Questions (FAQ) "
        description="Below you'll find answers to the questions we get asked the most about Esobond"
        className="mb-3"
      />
      <Card>
        <Card.Body>
          <Accordion
            id="accordionFaq"
            className="border rounded overflow-hidden"
          >
            {faqs.map((faq, index) => (
              <FaqAccordionItem
                key={faq.id}
                faq={faq}
                isFirst={index === 0}
                isLast={index === faqs.length - 1}
              />
            ))}
          </Accordion>
        </Card.Body>
      </Card>
    </>
  );
};

const mapStateToProps = state => {
  return {
    profileReducer: state.profileReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitFAQAPI,
    },
    dispatch,
  );
}
let Faq = connect(mapStateToProps, mapDispatchToProps)(FAQScreen);

export default Faq;
