import React from 'react';
import { Form } from 'react-bootstrap';

export default function SingleSelectForm(props) {
  const handleChange = (e) => {
    props.addQuestion(props.data.QuestionId, e.target.value)
  }

  return (
    <Form>
      <Form.Group className="mb-3" controlId="formGroupGender">
        <Form.Label>Q. {props.data.Description}</Form.Label>
        <Form.Select onChange={handleChange} name={props.data.QuestionId}>
        <option>Select</option>
          {props.data && props.data.Options.map((data, index) => (
            <option value={data.AnswerId} 
            key={index} 
            name={props.AnswerId} 
            id={props.AnswerId}>
              {data.Option}
              </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Form>
  )
}
