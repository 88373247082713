import { Navigate, Outlet } from "react-router";

const useAuth = () => {
    const user = {loggedIn: JSON.parse(localStorage.getItem('userLoggedIn'))};
    return user && user.loggedIn;
}

const ProtectedRoutes = () =>  {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoutes;