import React from 'react';
import WizardForm from 'components/wizard/Wizard';
import AuthCardLayout from 'layouts/AuthCardLayout';
import AssessmentCardLayout from 'layouts/AssessmentCardLayout';

export default function Assessment() {
  return (
    <>
      <AssessmentCardLayout>
        <WizardForm variant="pills" progressBar={true} />
      </AssessmentCardLayout>
    </>
  );
}
