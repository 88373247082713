/* eslint-disable no-unused-vars */
import { AuthWizardContext } from 'context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from './lottie/celebration.json';
import { useNavigate } from 'react-router-dom';

const Success = ({ reset, route }) => {
  console.log('route', route);
  const navigate = useNavigate();
  const { setStep, setUser } = useContext(AuthWizardContext);

  const emptyData = () => {
    navigate(route);
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">Your Preferences are all set!</h4>
          <p className="fs-0">Now you can explore more people</p>
          <Button
            color="primary"
            className="px-5 my-3 mt-5"
            onClick={emptyData}
          >
            Done
          </Button>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired
};

export default Success;
