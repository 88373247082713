import ApiFetch from '../../apimanager/ApiFetch';
import {
  CONTACT_US,
  REPORT_USER,
  USER_FEEDBACK,
  TNC_AND_PRIVACY_POLICY,
  CONTACT_US_OPTIONS,
  FAQS,
  NOTIFICATION_STATUS,
  NOTIFICATION_LISTING,
  NOTIFICATION_SEEN_STATUS
} from '../../apimanager/ApiEndpoints';

export const userFeedback = (rate, description) => dispatch => {
  const parameters = {
    Emoji: rate,
    Comment: description
  };

  return ApiFetch.fetchPost(USER_FEEDBACK, parameters)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('Error Feedback Action', err);
    });
};

export const contactUs = (reason, description) => dispatch => {
  const parameters = {
    Reason: reason,
    Comment: description
  };
  return ApiFetch.fetchPost(CONTACT_US, JSON.stringify(parameters))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('Error Contact Us', err);
    });
};

export const reportUser = (reportedBy, reportedTo, reason) => dispatch => {
  const parameters = {
    ReportedBy: reportedBy,
    ReportedTo: reportedTo,
    Reason: reason
  };
  return ApiFetch.fetchPost(REPORT_USER, JSON.stringify(parameters))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('Error Report User', err);
    });
};

export const terms_and_privacyPolicy = url => dispatch => {
  return ApiFetch.fetchGet(TNC_AND_PRIVACY_POLICY + url)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('TNC and Privacy Policy', err);
    });
};
export const contactUs_options = () => dispatch => {
  return ApiFetch.fetchGet(CONTACT_US_OPTIONS)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('Contact Us Option', err);
    });
};
export const hitFAQAPI = () => dispatch => {
  return ApiFetch.fetchGet(FAQS)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitFAQAPI Action', err);
    });
};

export const notificationPatch =
  (allNotification, likeNotification, matchNotification) => dispatch => {
    const parameters = {
      NotificationEnabled: allNotification,
      IsLikeNotificationEnabled: likeNotification,
      IsMatchNotificationEnabled: matchNotification
    };
    return ApiFetch.fetchPatch(NOTIFICATION_STATUS, JSON.stringify(parameters))
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log('error Notification Patch action', err);
      });
  };

export const getNotifications = (limit, pageNo) => dispatch => {
  return ApiFetch.fetchGet(
    NOTIFICATION_LISTING + `?Limit=${limit}&PageNo=${pageNo}`
  )
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error Notification Listing Action', err);
    });
};

export const notificationSeenStatus = notificationId => dispatch => {
  const parameters = {
    NotificationId: notificationId
  };
  return ApiFetch.fetchPatch(
    NOTIFICATION_SEEN_STATUS,
    JSON.stringify(parameters)
  )
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error Notification Patch action', err);
    });
};
