import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { ChatContext } from 'context/Context';
import Avatar from 'components/common/Avatar';

const ChatContentHeader = ({ user, setHideSidebar }) => {

  const { getUser, isOpenThreadInfo, setIsOpenThreadInfo } =
    useContext(ChatContext);
  return (
    <div className="chat-content-header">
      <Row className="flex-between-center" style={{ marginBottom: '0.55%' }}>
        <Col xs={6} md={8} as={Flex} alignItems="center">
          <div
            className="pe-3 text-700 d-md-none contacts-list-show cursor-pointer"
            onClick={() => setHideSidebar(true)}
          >
            <FontAwesomeIcon icon="chevron-left" />
          </div>
          <Avatar src={user?.profilePic} /> 
          <div className="min-w-0" style={{ marginLeft: '2%' }}>
            <h5 className="mb-0 text-truncate fs-0">{user?.username}</h5>
          </div>
        </Col>
      </Row>
    </div>
  );
};

ChatContentHeader.propTypes = {
  // thread: PropTypes.object.isRequired,
  // setHideSidebar: PropTypes.func.isRequired
};

export default ChatContentHeader;
