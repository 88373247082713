import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Spinner } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hitGoogleLogin, hitFacebookLogin } from 'redux/actions/LoginActions';
import { toast } from 'react-toastify';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';
import { useNavigate } from 'react-router-dom';
import AppUser from 'utils/AppUser';
import { db } from 'utils/firebase-config';
import {  collection, doc, setDoc } from 'firebase/firestore';
import { Constant } from 'utils/Constant';

const SocialAuthScreen = props => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    google.accounts.id.initialize({
      client_id:
        '123434980649-8aurtpukpvn0hl79i96ihoh97t4in3o1.apps.googleusercontent.com',
      callback: handleGoogleLogin
    });

    google.accounts.id.renderButton(document.getElementById('signInDiv'), {
      theme: 'outline',
      size: 'large'
    });
    google.accounts.id.prompt();
  }, []);

  const handleGoogleLogin = response => {
    setLoading(true);
    let appUsrObj = AppUser.getInstance();
    appUsrObj.token = null;
    appUsrObj.userId = null;
    props
      .hitGoogleLogin(response.credential)
      .then(data => {
        setLoading(false);
        if (!data.error && data.code === 200) {
          setLoading(false);
          toast.success(data.message);
          appUsrObj.token = data.data.Token;
          appUsrObj.userId = data.data.UserId;
          localStorage.setItem('accessEsoToken', data.data.Token);
          localStorage.setItem('userName', data.data.Name);
          localStorage.setItem('userLoggedIn', true);
          localStorage.setItem('userData', data.data);
          localStorage.setItem('UserChatId', data?.data?.UserId);
          localStorage.setItem(
            'HasAnsweredTotalQuestion',
            data?.data?.HasAnsweredTotalQuestion
          );
          addToFirebase(data.data);
          if (data?.data?.ProfilePic === null) {
            navigate('/authentication/create-user');
          } else if (data?.data?.HasQuestionnaireAnswered === false) {
            navigate('/user/questionaire');
          } else {
            navigate('/user/home');
          }
        } else {
          toast.error(data?.message);
        }
      })
      .catch(error => {
        console.log(error);
        toast.error(data?.message);
      });
  };

  const handleFbLogin = () => {
    setLoading(true);
    let appUsrObj = AppUser.getInstance();
    appUsrObj.token = null;
    appUsrObj.userId = null;
    props
      .hitFacebookLogin('abcd')
      .then(data => {
        setLoading(false);
        if (!data.error && data.code === 200) {
          setLoading(false);
          toast.success(data.message);
          appUsrObj.token = data.data.Token;
          appUsrObj.userId = data.data.UserId;
          localStorage.setItem('accessEsoToken', data.data.Token);
          localStorage.setItem('userName', data.data.Name);
          localStorage.setItem('userLoggedIn', true);
          localStorage.setItem('userData', data.data);
          localStorage.setItem('UserChatId', data?.data?.UserId);
          localStorage.setItem(
            'HasAnsweredTotalQuestion',
            data?.data?.HasAnsweredTotalQuestion
          );
          addToFirebase(data.data);
          if (data?.data?.ProfilePic === null) {
            navigate('/authentication/create-user');
          } else if (data?.data?.HasQuestionnaireAnswered === false) {
            navigate('/user/questionaire');
          } else {
            navigate('/user/home');
          }
        } else {
          toast.error(data?.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const addToFirebase = async data => {
    await setDoc(
      doc(collection(db, Constant.USERS), data.UserId + ''),
      {
        username: data.Name,
        profilePic: data.ProfilePic,
        id: data.UserId
      },
      { merge: true }
    )
      .then(() => {
        console.log('Document successfully written!');
      })
      .catch(error => {
        console.error('Error writing document: ', error);
      });
  };

  return (
    <>
      {loading && (
        <span style={{ display: 'block', textAlign: 'center' }}>
          <Spinner animation="border" role="status" className="flex-center">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </span>
      )}
      {!loading && (
        <Form.Group className="mb-0">
          <Row>
            <Col sm={6} className="pe-sm-1">
              <div id="signInDiv"></div>
            </Col>
            <Col sm={6} className="ps-sm-1">
              <LoginSocialFacebook
                appId="1574024693065185"
                onResolve={response => {
                  console.log(response);
                }}
                onReject={error => {
                  console.log(error);
                }}
              >
                {/* <FacebookLoginButton
                  style={{ margin: '0px', fontSize: '15px', height: '39px' }}
                  onClick={handleFbLogin}
                /> */}
              </LoginSocialFacebook>
            </Col>
          </Row>
        </Form.Group>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitGoogleLogin,
      hitFacebookLogin
    },
    dispatch
  );
}
let SocialAuthButtons = connect(
  mapStateToProps,
  mapDispatchToProps
)(SocialAuthScreen);

export default SocialAuthButtons;
