import React, { useEffect, useState } from 'react';
import Pbackground from '../../../assets/img/compatibility-categoty/Pbackground.png';
import Ppersonality from '../../../assets/img/compatibility-categoty/personality.png';

import Pfuture from '../../../assets/img/compatibility-categoty/future.png';

import Plifestyle from '../../../assets/img/compatibility-categoty/Plifestyle.png';
import { Modal, Table } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compatibilityReport } from 'redux/actions/AssessmentActions';
import { toast } from 'react-toastify';
import { getUserDetails } from 'redux/actions/ProfileActions';
import { getQuestionaireById } from 'redux/actions/QuestionaireActions';

const CompatibilityModal = props => {
  console.log(props.details.UserId);
  let keysArray;
  useEffect(() => {
    compatibilityReport(Number(props.details.UserId), props.details.ImageType);
  }, []);

  const [percentage, setPercentage] = useState([]);
  const compatibilityReport = async (userId, category) => {
    props.compatibilityReport(userId, category).then(async value => {
      if (!value.error && value.code === 200) {
        keysArray = value.data.map(item => Object.keys(item));

        value.data.forEach(items => {
          props.getQuestionaireById(items.QuestionId).then(data => {
            if (!data.error && data.code === 200) {
              setPercentage(prevArray => [
                ...prevArray,
                {
                  QuestionId: items.QuestionId,
                  Description: data.data.Description,
                  Percentage1: items[keysArray[0][1]],
                  Percentage2: items[keysArray[0][2]]
                }
              ]);
            } else {
              toast.error(data.error);
            }
          });
        });
      } else {
        toast.error(value.error);
      }
    });
  };

  let image = '';
  if (props.details.ImageType === 1) {
    image = Pbackground;
  } else if (props.details.ImageType === 2) {
    image = Plifestyle;
  } else if (props.details.ImageType === 3) {
    image = Ppersonality;
  } else if (props.details.ImageType === 4) {
    image = Pfuture;
  }

  return (
    <Modal
      show={true}
      onHide={props.onClose}
      centered
      scrollable={true}
      style={{
        position: 'absolute',

        transform: 'translateX(-50%)',
        maxWidth: '90%',
        padding: '1rem',
        maxHeight: '65%',
        top: '15%',
        left: '60%'
      }}
    >
      <Modal.Header
        style={{ position: 'sticky', top: '0', zIndex: '1' }}
        closeButton
      >
        <Modal.Title className="w-100 text-center">
          {' '}
          <img
            className="d-block mx-auto img-fluid "
            src={image}
            style={{
              height: '40px',
              width: '40px'
            }}
          />
          <p
            style={{
              marginTop: '1px',
              marginBottom: '1px',
              textAlign: 'center',

              color: 'linear-gradient(#BA59FB, #7CB3F1)'
            }}
          >
            {props.details.Name}
          </p>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Question</th>
              <th>You</th>
              <th>{props.details.UserName}</th>
            </tr>
          </thead>
          <tbody>
            {percentage?.map(data => (
              <tr key={data.id}>
                <td>{data['Description']}</td>
                <td>{data.Percentage1}</td>
                <td>{data.Percentage2}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      compatibilityReport,
      getUserDetails,
      getQuestionaireById
    },
    dispatch
  );
}
let ModalComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompatibilityModal);

export default ModalComponent;
