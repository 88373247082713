import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import SocialAuthButtons from './SocialAuthButtons';
import { hitSendSignupData } from 'redux/actions/SignupActions';
import { hitSendOTP } from 'redux/actions/LoginActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';

const RegistrationFormScreen = props => {
  const navigate = useNavigate();
  // State
  const [formData, setFormData] = useState({
    // name: '',
    email: '',
    password: '',
    confirmPassword: '',
    isAccepted: false
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [passRegex, setPassRegex] = useState(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,15}$/
  );
  const [emailregex, setEmailRegex] = useState(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/
  );

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    const isValid = passRegex.test(formData.password);
    const emailValid = emailregex.test(formData.email);
    const id = '1';
    if (formData.password === formData.confirmPassword) {
      if (!emailValid) {
        toast.error('Enter a correct email address.');
      } else if (!isValid) {
        toast.error(
          'Enter a password with 8-15 characters long that includes alphanumeric characters, special characters, digits, and Uppercase characters.'
        );
      } else {
        props
          .hitSendSignupData(formData.email, formData.password)
          .then(data => {
            // console.log(data.data.UserId)
            // _sendOtp(data.data.UserId,data.data.Email)
            if (
              data.message == 'Another account with this email already exists'
            ) {
              toast.error(data.message);
            } else if (!data.error && data.code === 200) {
              localStorage.setItem('esoUserEmail', formData.email);
              localStorage.setItem('UserId', data.data.UserId);
              localStorage.setItem(
                'esoUserPass',
                window.btoa(formData.password)
              );
              localStorage.setItem('esoProcess', 'registration');
              navigate(`/verify-otp`);
              toast.success(data.message, {
                theme: 'colored'
              });
            }
            if (data.error) {
              toast.error(data.message);
            } else {
              toast.error(data.error);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    } else toast.error('Both password and confirm password should be same');
  };

  // const _sendOtp = (userId,email) => {
  //   // Send OTP
  //   props.hitSendOTP(userId, email, 1).then(response => {
  //     if (response.code === 200) {
  //      console.log('OtpSent')
  //     }
  //   });
  // };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form validated={validated} onSubmit={handleSubmit}>
      {/* <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Name</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Name' : ''}
          value={formData.name}
          name="name"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group> */}

      <Form.Group className="mb-3">
        {props.hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!props.hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {props.hasLabel && <Form.Label>Password</Form.Label>}
          <InputGroup>
            <Form.Control
              required
              placeholder={!props.hasLabel ? 'Password' : ''}
              value={formData.password}
              name="password"
              onChange={handleFieldChange}
              type={showPassword ? 'text' : 'password'}
            />
            <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
              {' '}
              {showPassword ? 'Hide' : 'Show'}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {props.hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <InputGroup>
            <Form.Control
              placeholder={!props.hasLabel ? 'Confirm Password' : ''}
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleFieldChange}
              type={showCnfPassword ? 'text' : 'password'}
            />
            <InputGroup.Text
              onClick={() => setShowCnfPassword(!showCnfPassword)}
            >
              {' '}
              {showCnfPassword ? 'Hide' : 'Show'}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="isAccepted"
            checked={formData.isAccepted}
            onChange={e =>
              setFormData({
                ...formData,
                isAccepted: e.target.checked
              })
            }
          />
          <Form.Check.Label className="form-label">
            I accept the <Link to="#!">terms</Link> and{' '}
            <Link to="#!">privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <Form.Group className="mb-4">
        <Button
          className="w-100"
          type="submit"
          disabled={
            // !formData.name ||
            !formData.email ||
            !formData.password ||
            !formData.confirmPassword ||
            !formData.isAccepted
          }
        >
          Register
        </Button>
      </Form.Group>
      <Divider>or register with</Divider>

      <SocialAuthButtons />
    </Form>
  );
};

// RegistrationForm.propTypes = {
//   hasLabel: PropTypes.bool
// };

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitSendSignupData,
      hitSendOTP
    },
    dispatch
  );
}
let RegistrationForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationFormScreen);

export default RegistrationForm;
