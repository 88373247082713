import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../../../redux/actions/ProfileActions';

const FellowUserDetails = props => {
  return (
    <Card>
      <FalconCardHeader title="User Details" />
      <Card.Body className="bg-light">
        <Form>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="firstName">
              <Form.Label>First Name</Form.Label>

              <Form.Control
                placeholder="First Name"
                value={props.firstName}
                name="firstName"
                readOnly
                style={{
                  cursor: 'pointer'
                }}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="lastName">
              <Form.Label>Last Name</Form.Label>

              <Form.Control
                placeholder="Last Name"
                value={props.lastName}
                name="lastName"
                readOnly
                style={{
                  cursor: 'pointer'
                }}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="height">
              <Form.Label>Height (in Feet)</Form.Label>

              <Form.Control
                placeholder="Height"
                value={props.height}
                name="height"
                readOnly
                style={{
                  cursor: 'pointer'
                }}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="dateOfBirth">
              <Form.Label>Date Of Birth</Form.Label>

              <Form.Control
                placeholder="Date Of Birth"
                value={props.dateOfBirth}
                name="dateOfBirth"
                style={{
                  cursor: 'pointer'
                }}
              />
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default FellowUserDetails;
