import React, { useEffect, useState } from 'react';
import PageHeader from 'components/common/PageHeader';
import { faqs as faqsData } from 'data/faqs';
import FaqBasicCard from './FaqBasicCard';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {terms_and_privacyPolicy} from '../../../../redux/actions/SidebarActions';
// import { Document, Page } from 'react-pdf';

const TermsAndConditionScreen = (props) => {
  const [faqs] = useState(faqsData);
  const [content, setContent] = useState('');
  const [loading,setLoading]=useState(false)

  useEffect(() => {
    setLoading(true)
    let url='?type=1'
    props.terms_and_privacyPolicy(url).then(response => {
      setContent(response.data.Content);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }).catch(err=>{
      console.log("Terms and Privacy Policy Error",err)
    });
  }, []);

  return (
    <>
      <PageHeader
        title="Terms and Conditions"
        description="Below you'll find terms and conditions"
        className="mb-3"
      />
       {/* <Document file={content}/>  */}
      {/* <FaqBasicCard faqs={faqs} /> */}
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      terms_and_privacyPolicy,
    },
    dispatch,
  );
}
let FaqBasic = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TermsAndConditionScreen);

export default FaqBasic;

