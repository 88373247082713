import React, { Suspense, useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import PricingDefaultHeader from './PricingDefaultHeader';
import PricingDefaultCard from './PricingDefaultCard';
import useFakeFetch from 'hooks/useFakeFetch';
import { pricingData } from 'data/pricing';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cancelSubscription } from '../../../../redux/actions/SubscriptionAction';
import { Button } from 'react-bootstrap';
import { subscriptionType } from 'redux/actions/ProfileActions';
import Iframe from 'react-iframe';

const PricingScreen = props => {
  const { loading: priceLoading, data: pricing } = useFakeFetch(
    pricingData,
    1000
  );
  const [loading, setLoading] = useState(false);
  const subscription = localStorage.getItem('subscriptionType');
  console.log(subscription, 'ddddd');

  useEffect(() => {
    getSubscription();
  }, []);

  const cancelSubscription = () => {
    setLoading(true);
    props
      .cancelSubscription()
      .then(response => {
        setLoading(false);
        console.log(response);
        getSubscription();
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getSubscription = () => {
    props
      .subscriptionType()
      .then(response => {
        console.log(response?.data, '976789');
        if (response.code === 200) {
          localStorage.setItem(
            'subscriptionType',
            response?.data?.SubscriptionType
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const upgrade = () => {
    window.open('https://esobond.chargebeeportal.com');
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          {subscription < '1' ? (
            <Row className="g-0">
              <PricingDefaultHeader />
              {priceLoading ? (
                <Col xs={12} className="py-4">
                  <Spinner
                    className="position-absolute start-50"
                    animation="grow"
                  />
                </Col>
              ) : (
                pricing.map(pricing => (
                  <PricingDefaultCard key={pricing.id} pricing={pricing} />
                ))
              )}
            </Row>
          ) : (
            <div>
              {/* <div>
              <h5 className="d-inline-block">
                Do you want to cancel subscription?
              </h5>
              <div>
                <Button onClick={cancelSubscription}>
                  cancel Subscription
                </Button>
         
              </div>
              <hr className="border-bottom-0 m-2" />
              <div>
              <h5 className="d-inline-block">
              Are You want to upgrade the subscription?
              </h5>
              <div>
              <Button onClick={upgrade}>
            Upgrade Subscription
           </Button>
           </div>
              </div>
            </div> */}
              <h5 className="d-inline-block">
                Your subscription is sucessfull
              </h5>
              <p className="mt-3">
                If you want to Cancel/Upgrade your plan please login with the
                registered email id and get your details
              </p>
              <Iframe
                url={'https://esobond.chargebeeportal.com'}
                width="640px"
                height="660px"
                display="block"
                position="relative"
              />
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      cancelSubscription,
      subscriptionType
    },
    dispatch
  );
}

let PricingDefault = connect(
  mapStateToProps,
  mapDispatchToProps
)(PricingScreen);

export default PricingDefault;
