import React from 'react';
import { Row, Col, Navbar, Nav, Container, Image } from 'react-bootstrap';

import logo from '../../../../assets/img/illustrations/esobond-icon.png';
import '../../../../components/pages/user/CustomCarousel.css';
const AboutUs = props => {
  const navbarStyle = {
    backgroundColor: 'white',
    padding: '20px',
    display: 'flex',
    alignItems: 'center'
  };

  const logoStyle = {
    marginRight: 'auto',
    marginLeft: '30px'
  };
  const leftColumnStyle = {
    padding: '20px'
  };

  return (
    <div>
      <Navbar style={navbarStyle} expand="md">
        <Navbar.Brand style={logoStyle} href="/user/home">
          <Image src={logo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center"
        >
          <div
            style={{
              marginLeft: 'auto',
              marginRight: '10%'
            }}
          >
            <Nav></Nav>
          </div>
        </Navbar.Collapse>
      </Navbar>

      <Container
        fluid
        style={{
          backgroundColor: 'white'
        }}
      >
        <Row>
          <Col md={11} style={leftColumnStyle}>
            <div
              style={{
                marginLeft: '30px'
              }}
            >
              <p
                style={{
                  background:
                    'var(--gradient, linear-gradient(118deg, #BA59FB 6.93%, #7CB3F1 86.63%))',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontFamily: 'sans-serif',
                  fontSize: '16px',
                  fontWeight: '600'
                }}
              >
                WELCOME TO ESOBOND
              </p>

              <h1
                style={{
                  marginTop: '20px',
                  fontFamily: ' sans-serif',
                  fontSize: '48px',
                  fontWeight: '700'
                }}
              >
                About
              </h1>
              <h2>Embracing Authenticity and Leading with Purpose</h2>

              <h3
                style={{
                  marginTop: '20px'
                }}
              >
                Our Mission
              </h3>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                What’s your purpose? Self-awareness is the key when making major
                life decisions. Spending time reflecting on your personal goals
                allows for enhanced self-awareness, which research has found
                leads to improved communication, decision-making, planning, and
                happiness.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Our mission is to empower individuals like you to break free
                from the superficial to create happier people who lead with
                purpose all over the world!
              </p>
              <h3
                style={{
                  marginTop: '20px'
                }}
              >
                Our Approach
              </h3>
              <h5
                style={{
                  marginTop: '20px'
                }}
              >
                Leave your fears behind!
              </h5>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                At Esobond, we aim to redefine the social landscape, rejecting
                the notion that connecting can be reduced to swipes and scores.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                We strive to create an environment where authenticity thrives,
                where individuals can look within to identify what is most
                important and lead with that purpose in mind.
              </p>
              <h3
                style={{
                  marginTop: '20px'
                }}
              >
                Why Choose Esobond?
              </h3>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                We are unlike any application in the market today. With our
                platform, you can stop scrolling and searching aimlessly. Join a
                community of people who care about their future and want to take
                action.
              </p>
              <p
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                  color: '#9E9E9E',
                  fontFamily: ' sans-serif',
                  fontWeight: '400'
                }}
              >
                Our focus on shared goals ensures that every experience is
                aligned with what you truly need. Choose Esobond and embark on a
                new journey where authenticity and honesty take center stage.
              </p>
              <h2
                style={{
                  display: 'flex',
                  marginTop: '20px',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                Ready to Experience Esobond?
              </h2>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;
