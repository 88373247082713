import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hitSendOTP } from '../../../../redux/actions/LoginActions';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Avatar from 'components/common/Avatar';
import like from '../../../../assets/img/postFeed/like.png';
import liked from '../../../../assets/img/postFeed/liked.png';
import comment from '../../../../assets/img/postFeed/comment.png';
import {
  hitGetPostFeed,
  hitPostcomment,
  hitPostLike,
  hitGetPostcommentList
} from 'redux/actions/ProfileActions';
import deleteIcon from '../../../../assets/img/postFeed/delete.png';
import { hitCreatePost } from '../../../../redux/actions/ProfileActions';

const FeedOnProfileScreen = props => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showCommentListModal, setShowCommentListModal] = useState(false);
  const [commentId, setcommentId] = useState();
  const [comments, setComments] = useState();
  const [feedPost, setFeedPost] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [postMessageText, setPostMessageText] = useState();
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    getFeedPosts();
  }, []);

  const handleLike = async (postId, isLiked) => {
    props.hitPostLike(postId, !isLiked).then(async data => {
      if (!data.error && data.code === 200) {
        setLoading(false);
        await getFeedPosts();
      }
    });
  };

  const handleComment = async (postId, comment) => {
    props.hitPostcomment(postId, comment).then(async data => {
      if (!data.error && data.code === 200) {
        setLoading(false);
        handleClose();
        await getFeedPosts();
      }
    });
  };

  const getFeedPosts = async () => {
    props.hitGetPostFeed().then(data => {
      if (!data.error && data.code === 200) {
        setLoading(false);
        setFeedPost(data.data);
        // setComments(data.data.TotalComment);
        // setLikes(data.data.TotalLikes);
      }
    });
  };

  const handleCommentChange = e => {
    const inputComment = e.target.value;
    if (inputComment.length <= 200) {
      setComments(inputComment);
    }
  };

  const handlePostMessageChange = e => {
    const inputMessage = e.target.value;
    if (inputMessage.length <= 200) {
      setPostMessageText(inputMessage);
    }
  };

  const handleModal = id => {
    setcommentId(id);
    setShow(!show);
  };

  const handleClose = () => {
    setShow(false);
    setShowCommentListModal(false);
  };

  const handleCommentLis = postId => {
    props.hitGetPostcommentList(postId).then(async data => {
      if (!data.error && data.code === 200) {
        setLoading(false);
        setCommentList(data.data);
      }
    });

    setShowCommentListModal(true);
  };

  const formatDate = createdOn => {
    const commentDate = new Date(parseInt(createdOn));
    const today = new Date();

    // Check if the comment was created today
    if (
      commentDate.getDate() === today.getDate() &&
      commentDate.getMonth() === today.getMonth() &&
      commentDate.getFullYear() === today.getFullYear()
    ) {
      // If created today, show the time
      return commentDate.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });
    } else {
      // If not created today, show the full date
      return commentDate.toLocaleString('en-US', {
        // year: 'numeric',

        day: 'numeric',
        month: 'short'
        // hour: false,
        // minute: false,
        // hour12: true,
      });
    }
  };

  const handleImageChange = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');
      setSelectedImage(base64String);
    };

    // console.log(base64String, "imageTest");

    // console.log(e.target.files[0],"wefjbwefikbfk");
    // const file = e.target.files[0];
    // setSelectedImage(file);
  };

  const handlePost = () => {
    setLoading(true);
    props.hitCreatePost(postMessageText, selectedImage).then(async data => {
      if (!data.error && data.code === 200) {
        setLoading(false);
        getFeedPosts();
      }
    });
  };

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Card>
          <FalconCardHeader title="Edit Feed" />
          <Card.Body className="bg-light">
            {
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <textarea
                    style={{ width: '600px' }}
                    value={postMessageText}
                    onChange={handlePostMessageChange}
                    placeholder="Type Something here..."
                    rows="5"
                    cols="60"
                    maxLength="200"
                  />

                  <div>
                    <Form.Group
                      controlId="picURL"
                      className="mb-2"
                      style={{ margin: '0px 0px 0px 10px' }}
                    >
                      <Form.Label>Upload Image</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="picURL"
                        onChange={handleImageChange}
                      />
                    </Form.Group>
                    <Button
                      disabled={!selectedImage}
                      style={{
                        maxWidth: '400px',
                        width: '200px',
                        height: '60px',
                        marginTop: '4%',
                        marginLeft: '10px',
                        // marginRight: '10px',
                        cursor: 'pointer'
                      }}
                      variant="primary"
                      onClick={() => handlePost()}
                    >
                      Post
                    </Button>
                  </div>
                </div>
              </div>
            }
            {feedPost.map((data, index) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {/* parrent Component */}
                <div
                  className="post-box"
                  style={{
                    height: '620px',
                    width: '400px',
                    borderRadius: '5%',
                    background: '',
                    boxShadow: '0px 0px 5px 5px #ccc',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    position: 'relative',
                    marginTop: '40px'
                  }}
                >
                  {/* avtar Component */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div
                      className="avtar mx-2 "
                      style={{
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start'
                      }}
                    >
                      <Avatar src={data.ProfilePic} />
                      <div className="min-w-0 mx-2">
                        <h5
                          className="text-truncate fs-0"
                          style={{ marginBottom: '-4px' }}
                        >
                          {data.UserName}
                        </h5>
                        <p
                          className="text-truncate "
                          style={{ fontSize: '14px' }}
                        >
                          {formatDate(parseInt(data.CreatedOn))}
                        </p>
                      </div>
                    </div>

                    <div
                      className="icon"
                      style={{
                        cursor: 'pointer',
                        marginRight: '20px',
                        marginTop: '14px'
                      }}
                      onClick={handleLike}
                    >
                      <img src={deleteIcon} alt="Delete Icon" />
                    </div>
                  </div>

                  {/* post message component  */}
                  <div
                    className="min-w-0 mx-2"
                    style={{ height: '50px', overflow: 'hidden' }}
                  >
                    <h5 style={{ fontSize: '14px' }}>{data.Description}</h5>
                  </div>

                  {/* Post Image */}
                  <img
                    className="post-image"
                    src={data.ImageUrl} // Replace with your actual image source
                    alt="Post"
                  />

                  {/* Icons */}
                  <div
                    className="m-2"
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <div
                      className="icons-container"
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      {/* Heart Icon for Likes */}
                      <div
                        className="icon"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          cursor: 'pointer'
                        }}
                        onClick={() => handleLike(data.Id, data.IsLiked)}
                      >
                        <img
                          src={data.IsLiked ? liked : like}
                          alt="Heart Icon"
                          style={{ marginRight: '5px' }}
                        />
                        <span style={{ marginLeft: '20px' }}>
                          {data.TotalLikes}
                        </span>
                      </div>

                      {/* Comment Icon */}
                      <div
                        className="icon"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          cursor: 'pointer'
                        }}
                      >
                        <img
                          src={comment}
                          alt="Comment Icon"
                          style={{ marginRight: '5px' }}
                          onClick={() => handleModal(data.Id)}
                        />
                        <span
                          style={{ marginLeft: '20px' }}
                          onClick={() => handleCommentLis(data.Id)}
                        >
                          {data.TotalComment}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Card.Body>
        </Card>
      )}

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Comment</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <textarea
              value={comments}
              onChange={handleCommentChange}
              placeholder="Type your comment..."
              rows="4"
              cols="50"
              maxLength="200"
            />
            {/* <div>{comment.length} / 200 characters remaining</div> */}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleComment(commentId, comments)}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCommentListModal} onHide={handleClose} size="lg">
        {/* <Modal.Dialog> */}
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            {loading ? (
              <div>Loading...</div>
            ) : (
              commentList.map((comment, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: '#e0e0e0', // Alternate background colors
                    padding: '8px',
                    marginBottom: '8px',
                    position: 'relative'
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      borderBottom: 'solid 1px #fff',
                      backgroundColor: 'rgba(255, 255, 255, 0.7)', // Slightly faded background
                      padding: '4px',
                      fontSize: '12px'
                    }}
                  >
                    {comment.UserName}
                  </div>
                  <div style={{ paddingTop: '20px' }}>
                    <strong>{comment.Comment}</strong>
                  </div>
                </div>
              ))
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={() => handleModal(commentId) } >Save changes</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitGetPostFeed,
      hitSendOTP,
      hitPostLike,
      hitPostcomment,
      hitGetPostcommentList,
      hitCreatePost
    },
    dispatch
  );
}
let FeedOnProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedOnProfileScreen);

export default FeedOnProfile;
