import FalconCloseButton from 'components/common/FalconCloseButton';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hitDeleteAccount } from '../../../../redux/actions/ProfileActions';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const DangerZone = props => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteAccount = () => {
    props
      .hitDeleteAccount().then(data => {
        if (!data.error && data.code === 200) {
          navigate('/authentication/card/logout');
        } else {
          toast.error(data.error);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <>
      <Card>
        <FalconCardHeader title="" />
        <Card.Body className="bg-light">
          <h5 className="mb-0">Delete this account</h5>
          <p className="fs--1">
            Once you delete a account, there is no going back. Please be
            certain.
          </p>
          <Button
            onClick={handleShow}
            variant="falcon-danger"
            className="w-100"
          >
            Delete Account
          </Button>
        </Card.Body>
      </Card>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Oops!</Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          Deleting your account will delete all your data from the app. Do you
          still want to delete it?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={deleteAccount}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitDeleteAccount
    },
    dispatch
  );
}
let DeleteAccount = connect(mapStateToProps, mapDispatchToProps)(DangerZone);

export default DeleteAccount;
