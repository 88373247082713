import React from 'react';
import UnAnsweredWizardForm from './UnAnsweredWizard';

export default function UnAnsweredAssessment() {
  return (
    <>
      <UnAnsweredWizardForm variant="pills" progressBar={true} />
    </>
  );
}
