import React from 'react';
import PropTypes from 'prop-types';
import AuthWizardProvider from './AuthWizardProvider';
import ReAnswerForm from './Re-Answer';

const ReAnswerWizard = ({ variant, validation, progressBar }) => {
  return (
    <AuthWizardProvider>
      <ReAnswerForm
        variant={variant}
        validation={validation}
        progressBar={progressBar}
      />
    </AuthWizardProvider>
  );
};

ReAnswerWizard.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

export default ReAnswerWizard;
