import {combineReducers} from 'redux';
import homeReducer from './HomeReducer';
import profileReducer from './ProfileReducer';

const appReducer = combineReducers({
  homeReducer: homeReducer,
  profileReducer:profileReducer
});

let rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
