import Notification from 'components/notification/Notification';
import { notifications } from 'data/notification/notification';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {getNotifications} from '../../../../redux/actions/SidebarActions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

const _NotificationScreen = () => {

  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    notificationsData()
  }, [])

  const notificationsData = () => {
    props.getNotifications(limit, pageNo)
      .then(response => {
        console.log(response.data);
        if (response?.code === 200) {
          setNotificationData(response?.data);
        }
        // setLoader(false);
      })
  }

  return (
    <Card>
      <Card.Header className="bg-light">
        <Row className="flex-between-center">
          <Col sm="auto">
            <h5 className="mb-1 mb-md-0">Your Notifications</h5>
          </Col>
          <Col sm="auto" className="fs--1">
            <Link to="#!" className="font-sans-serif ms-2 ms-sm-3">
              Mark all as read
            </Link>
            <div className="font-sans-serif ms-2 ms-sm-3"></div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        {notifications.map(notification => (
          <Notification {...notification} key={notification.id} />
        ))}
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getNotifications
  }, dispatch);
}

let Notifications = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_NotificationScreen);


export default Notifications;
