import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Spinner } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  hitClientToken,
  hitSendPaymentData
} from '../../../../redux/actions/SubscriptionAction';
import PageHeader from 'components/common/PageHeader';
import Iframe from 'react-iframe';

const PaymentScreen = props => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState();
  const [planName, setPlanName] = useState('');
  const [bilingPeriod, setBilingPeriod] = useState();
  const [url, seturl] = useState('');
  const params = useParams();
  const subscription = localStorage.getItem('subscriptionType');

  useEffect(() => {
    setParamsData();
  }, []);

  useEffect(() => {
    sendPaymentData();
  }, [bilingPeriod]);

  const setParamsData = () => {
    setAmount(params.amount);
    setPlanName(params.subscriptionName);
    setBilingPeriod(params.type);
  };

  const sendPaymentData = () => {
    setLoading(true);
    props
      .hitSendPaymentData(planName, bilingPeriod)
      .then(response => {
        setLoading(false);
        if (response.code === 200) {
          seturl(response?.data?.url);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      <PageHeader
        preTitle={'$' + amount}
        title={'Esobond ' + planName + ' Plan'}
        className="mb-3"
        titleTag="h2"
      />
      <Card className="mb-3">
        {loading ? (
          <Col xs={12} className="py-4">
            <Spinner className="position-absolute start-50" animation="grow" />
          </Col>
        ) : (
          <Iframe
            url={url}
            width="640px"
            height="660px"
            display="block"
            position="relative"
          />
        )}
      </Card>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitClientToken,
      hitSendPaymentData
    },
    dispatch
  );
}

let PaymentPage = connect(mapStateToProps, mapDispatchToProps)(PaymentScreen);

export default PaymentPage;
