import { checkUserSubscribed } from "./subscriptionGaud";
import { Redirect } from 'react-router-dom';

var currentLocation = window.location.href;

export const appRoutes = {
  label: 'Esobond',
  children: [
    {
      name: 'Home',
      to: '/user/bond',
      active: true,
      icon: 'globe'
    },
    {
      name: 'Chat',
      icon: 'comments',
      to: '/app/chat',
      active: checkUserSubscribed(),
      Redirect: false
    },
    {
      name: 'Assessment',
      icon: 'chart-pie',
      to: '/social/re-assessment',
      active: checkUserSubscribed()
    },
    {
      name: 'Settings',
      icon: 'user',
      active: true,
      children: [
        {
          name: 'Edit Feed',
          to: '/user/settings',
          active: true
        },
        {
          name: 'Unanswered Questions',
          to: checkUserSubscribed ? '/user/un-answered-questionaire': undefined,
          active: checkUserSubscribed()
        },
        {
          name: 'Invite a Friend',
          to: '/miscellaneous/invite-people',
          active: true
        },
        {
          name: 'Change Password',
          to: '/authentication/simple/reset-password',
          active: true
        },
        {
          name: 'Subscription',
          to: '/pricing/pricing-default',
          active: true
        },
        {
          name: 'Feedback',
          to: '/forms/advance/emoji-button',
          active: true
        },
        // {
        //   name: 'Contact Us',
        //   to: '/support-desk/contacts',
        //   active: true
        // },
        {
          name: 'FAQs',
          to: '/documentation/faq',
          active: true
        },
        // {
        //   name: 'Terms and Conditions',
        //   to: '/faq/faq-basic',
        //   active: true
        // }
      ]
    }
  ]
};

export default [appRoutes];
