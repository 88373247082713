import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { hitCreateUserAPI, hitVerifyOtpAPI } from 'redux/actions/SignupActions';
import { hitSendOTP } from 'redux/actions/LoginActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { db } from 'utils/firebase-config';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { Constant } from 'utils/Constant';


const CreateUserScreen = props => {
  const navigate = useNavigate();
  const userId = localStorage.getItem('UserId');
  const [isHidden, setIsHidden] = useState(false);
  const [otp, setOtp] = useState();
  const messageRef = collection(db, 'USERS');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    countryCode: '',
    phoneNumber: '',
    gender: '',
    dob: '',
    picURL: ''
  });
  let startdate = moment();
  startdate = startdate.subtract(18, 'years');
  startdate = startdate.format('YYYY-MM-DD');

  const handlePhoneChange = (value, data) => {
    setFormData({
      ...formData,
      phoneNumber: value.slice(data.dialCode.length),
      countryCode: '+' + data.dialCode
    });
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === 'picURL' ? e && e.target.files[0] : e.target.value
    });
  };
  const handleOtp = e => {
    setOtp(e.target.value);
  };

  const imageChange = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');
      setFormData({
        ...formData,
        [e.target.name]:
          e.target.name === 'picURL' ? base64String : e.target.value
      });
    };
  };

  const handleSubmit = e => {
    e.preventDefault();
    const id = '2';
    if (
      !formData.countryCode ||
      !formData.dob ||
      !formData.firstName ||
      !formData.gender ||
      !formData.lastName ||
      !formData.phoneNumber ||
      !formData.picURL 
      // || !otp
    ) {
      toast.error('Form is not completed');
    } else {
      props
        .hitCreateUserAPI(
          formData.firstName,
          formData.lastName,
          formData.countryCode,
          formData.phoneNumber,
          formData.gender,
          formData.dob,
          formData.picURL,
        )
        .then(data => {
          if (data.message === 'Phone number is already in use.') {
            toast.error(data.message);
          }
          if (!data.error && data.code === 200) {
            localStorage.setItem('esoProcess', 'createUser');
            localStorage.setItem(
              'userPhone',
              data.data.CountryCode + data.data.PhoneNumber
            );
            localStorage.setItem('userName', data.data.Name);
            localStorage.setItem('userLoggedIn', true);
            localStorage.setItem('userData',data.data)
            localStorage.setItem('UserChatId',data?.data?.UserId)
            navigate('/user/questionaire');
            addToFirebase(data.data);
            toast.success(data.message, {
              theme: 'colored'
            });
          }
          if (
            data.error ||
            data.statusCode === 400 ||
            data.statusCode === 404 ||
            data.statusCode === 403 ||
            data.statusCode === 401
          ) {
            toast.error(data.message);
          } else {
            toast.error(data.error);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const addToFirebase = async (data) => {

    console.log((data, data.userId));

    await setDoc(doc(collection(db, Constant.USERS), data.UserId + ''), {
      username: data.Name,
      profilePic: data.ProfilePic,
      id: data.UserId
    }, { merge: true })

    // await addDoc(collection(doc(db, Constant.USERS, data.UserId + ''), Constant.USERS), 
    // {
    //   username: data.Name,
    //   profilePic: data.ProfilePic,
    //   id: data.UserId
    // })
    .then(() => {
      console.log('Document successfully written!');
    })
    .catch((error) => {
      console.error('Error writing document: ', error);
    });
  };


  const _sendOtp = () => {
    if (formData.countryCode + formData.phoneNumber === '') {
      toast.error('Please Enter Phone Number');
    } else {
      props
        .hitSendOTP(userId, formData.countryCode + formData.phoneNumber, 2)
        .then(response => {
          if (response.message === 'Phone number is already in use.') {
            toast.error(response.message);
          } else if (response.code === 200) {
            toast.success(response.message, {
              theme: 'colored'
            });
            setIsHidden(true);
          } else {
            toast.error(response.message);
          }
        });
    }
  };

  const _verifyOtp = () => {
    // Verify OTP
    props
      .hitVerifyOtpAPI(
        userId,
        formData.countryCode + formData.phoneNumber,
        otp,
        2
      )
      .then(response => {
        if (response.code === 200) {
          setIsHidden(false);
        } else {
          toast.error(response.message);
        }
      });
  };

  return (
    <AuthCardLayout>
      <Card>
        <FalconCardHeader title="Create Profile" />
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={formData.firstName}
                  name="firstName"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={formData.lastName}
                  name="lastName"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 g-3">
              {/* <Form.Group as={Col} lg={6} controlId="height">
                <Form.Label>Height (in feet)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Height"
                  min="3.0"
                  max="9.11"
                  value={formData.height}
                  name="height"
                  onChange={handleChange}
                />
              </Form.Group> */}

              <Form.Group as={Col} lg={6} controlId="dob">
                <Form.Label>Date Of Birth</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Date Of Birth"
                  value={formData.dob}
                  name="dob"
                  max={startdate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Form.Label>Contact Number</Form.Label>
            <Row>
              <Form.Group as={Col} lg={11} controlId="phone">
                <PhoneInput
                  placeholder="Enter phone number"
                  country={'us'}
                  value={''}
                  onChange={handlePhoneChange}
                />
              </Form.Group>
              {/* <Form.Group as={Col} lg={3} controlId="otp">
                <Button variant="primary" onClick={_sendOtp}>
                  OTP
                </Button>
              </Form.Group> */}
            </Row>
            {/* {isHidden ? (
              <Row>
                <Form.Label>Verification Code</Form.Label>
                <Form.Group as={Col} lg={6} controlId="OTP">
                  <Form.Control
                    type="text"
                    placeholder="Verification Code"
                    value={otp}
                    onChange={handleOtp}
                  />
                </Form.Group>
                <Form.Group as={Col} lg={3} controlId="otp">
                  <Button variant="primary" onClick={_verifyOtp}>
                    Verify
                  </Button>
                </Form.Group>
                <Form.Group as={Col} lg={3} controlId="otp">
                  <Button variant="primary" onClick={_sendOtp}>
                    Resend
                  </Button>
                </Form.Group>
              </Row>
            ) : null} */}
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="gender">
                <Form.Label>Gender</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  name="gender"
                  aria-label="Default select example"
                >
                  <option>Select Gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Others</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Form.Group controlId="picURL" className="mb-3">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                name="picURL"
                onChange={imageChange}
              />
            </Form.Group>
            <div className="text-end mt-2">
              <Button variant="primary" type="submit">
                Proceed
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </AuthCardLayout>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitCreateUserAPI,
      hitSendOTP,
      hitVerifyOtpAPI
    },
    dispatch
  );
}
let CreateUserForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserScreen);

export default CreateUserForm;
