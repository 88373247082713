import ApiFetch from '../../apimanager/ApiFetch';
import { CANCEL_SUBSCRIPTION, CLIENT_TOKEN, SEND_PAYMENT_DATA, SUBSCRIPTION_ANDROID, SUBSCRIPTION_IOS } from '../../apimanager/ApiEndpoints';


export const hitSubscriptionIos = (receipt, subscriptionType, duration, price,subscriptionProductId) => dispatch => {
  const parameters = {
    Receipt: receipt,
    SubscriptionType: subscriptionType,
    Duration: duration,
    Amount: price,
    ProductId:subscriptionProductId
  };
  return ApiFetch.fetchPost(SUBSCRIPTION_IOS, JSON.stringify(parameters))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitSubscriptionIos Action', err);
    });
};

export const hitSubscriptionAndroid = (purchaseToken, purchasedSubscriptionId, subscriptionType, duration) => dispatch => {
  const parameters = {
    PurchaseToken: purchaseToken,
    PurchasedSubscriptionId: purchasedSubscriptionId,
    Duration: duration,
    SubscriptionType: subscriptionType,
  };
  return ApiFetch.fetchPost(SUBSCRIPTION_ANDROID, JSON.stringify(parameters))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitSubscriptionAndroid Action', err);
    });
};

export const hitClientToken = id => dispatch => {
  return ApiFetch.fetchGet(CLIENT_TOKEN)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitClientToken Action', err);
    });
};

export const hitSendPaymentData = (PlanName,bilingPeriod) => dispatch => {
  const nounce = {
    PlanName:PlanName,
    BillingPeriod:bilingPeriod,
  }
  console.log(nounce,'jhghjkl')
  return ApiFetch.fetchPost(SEND_PAYMENT_DATA, JSON.stringify(nounce))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitSubscriptionIos Action', err);
    });
};

export const cancelSubscription = (userId, isLiked) => dispatch => {
  return ApiFetch.fetchPatch(CANCEL_SUBSCRIPTION)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror cancelling subscrption', err);
    });
}
