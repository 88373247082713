import React, { useState } from 'react';
import {
    Row,
    Col,
    Navbar,
    Nav,
    Container,
    Image
} from 'react-bootstrap';

import logo from '../../../../assets/img/illustrations/esobond-icon.png';
import '../../../../components/pages/user/CustomCarousel.css';
const TermsNCondition = props => {
    const [rightPost] = useState(
        ['Esobond grants you a personal, worldwide, royalty-free, non-assignable, non-exclusive, revocable, and non-sublicensable license to access and use the Service. This license is for the sole purpose of letting you use and enjoy the Service’s benefits as intended by Esobond and permitted by this Agreement. You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness.',
            'By posting Content to the Service, You grant Us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of Your rights to any Content You submit, post or display on or through the Service and You are responsible for protecting those rights. You agree that this license includes the right for Us to make Your Content available to other users of the Service, who may also use Your Content subject to these Terms.',
            'You represent and warrant that: (i) the Content is Yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of Your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.'
        ]);
    const [ContentRestrictions] = useState(
        [
            'The Company is not responsible for the content of the Services users. You expressly understand and agree that You are solely responsible for the Content and for all activity that occurs under your account, whether done so by You or any third person using Your account.',
            'You may not transmit any Content that is unlawful, offensive, upsetting, intended to disgust, threatening, libelous, defamatory, obscene or otherwise objectionable. Examples of such objectionable Content include, but are not limited to, the following:',
            '·       Unlawful or promoting unlawful activity.',
            '·       Defamatory, discriminatory, or mean-spirited content, including references or commentary about religion, race, sexual orientation, gender, national/ethnic origin, or other targeted groups.',
            '·       Spam, machine – or randomly – generated, constituting unauthorized or unsolicited advertising, chain letters, any other form of unauthorized solicitation, or any form of lottery or gambling.',
            '·       Containing or installing any viruses, worms, malware, trojan horses, or other content that is designed or intended to disrupt, damage, or limit the functioning of any software, hardware or telecommunications equipment or to damage or obtain unauthorized access to any data or other information of a third person.',
            '·       Infringing on any proprietary rights of any party, including patent, trademark, trade secret, copyright, right of publicity or other rights.',
            '·       Impersonating any person or entity including the Company and its employees or representatives.',
            '·       Violating the privacy of any third person.',
            '·       False information and features.',
            'The Company reserves the right, but not the obligation, to, in its sole discretion, determine whether or not any Content is appropriate and complies with this Terms, refuse or remove this Content. The Company further reserves the right to make formatting and edits and change the manner of any Content. The Company can also limit or revoke the use of the Service if You post such objectionable Content. As the Company cannot control all content posted by users and/or third parties on the Service, you agree to use the Service at your own risk. You understand that by using the Service You may be exposed to content that You may find offensive, indecent, incorrect or objectionable, and You agree that under no circumstances will the Company be liable in any way for any content, including any errors or omissions in any content, or any loss or damage of any kind incurred as a result of your use of any content.',
            'This license is for the sole purpose of letting you use and enjoy the Service’s benefits as intended by Esobond and permitted by this Agreement. This license and any authorization to access the Service are automatically revoked in the event that you do any of the following:',
            '• Use the Service or any content contained in the Service for any commercial purposes without our written consent.',
            '• Copy, modify, transmit, create any derivative works from, make use of, or reproduce in any way any copyrighted material, images, trademarks, trade names, service marks, or other intellectual property, content or proprietary information accessible through the Service without Esobond’s prior written consent.',
            '• Conduct business on behalf of Esobond mobile application, product, or services that have not been documented in a formal Agreement. ',
            '• Express or imply that any statements you make are endorsed by Esobond.',
            '• Use any robot, bot, spider, crawler, scraper, site search/retrieval application, proxy or other manual or automatic device, method or process to access, retrieve, index, “data mine,” or in any way reproduce or circumvent the navigational structure or presentation of the Service or its contents.',
            '• Use the Service in any way that could interfere with, disrupt or negatively affect the Service or the servers or networks connected to the Service.',
            '• Upload viruses or other malicious code or otherwise compromise the security of the Service.',
            '• Forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted to or through the Service.',
            '• “frame” or “mirror” any part of the Service without Esobond’s prior written authorization.',
            '• Use meta tags or code or other devices containing any reference to Esobond or the Service (or any trademark, trade name, service mark, logo or slogan of Esobond) to direct any person to any other website or application for any purpose.',
            '• Modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble any portion of the Service, or cause others to do so.',
            '• Use or develop any third-party applications that interact with the Service or other members’ Content or information without our written consent.',
            '• Use, access, or publish the Esobond application programming interface without our written consent.',
            '• Probe, scan or test the vulnerability of our Service or any system or network.',
            '• Encourage, promote, or agree to engage in any activity that violates this Agreement.'
        ]
    )
    const [      ContentBackups]=useState([
        'Although regular backups of Content are performed, the Company does not guarantee there will be no loss or corruption of data.',
        'Corrupt or invalid backup points may be caused by, without limitation, Content that is corrupted prior to being backed up or that changes during the time a backup is performed.',
        'The Company will provide support and attempt to troubleshoot any known or discovered issues that may affect the backups of Content. But You acknowledge that the Company has no liability related to the integrity of Content or the failure to successfully restore Content to a usable state.',
        'You agree to maintain a complete and accurate copy of any Content in a location independent of the Service.',
        'Copyright Policy'
    ])
    const [ IntellectualProperty]=useState([
        'We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes a copyright or other intellectual property infringement of any person.',
        'If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing to the attention of our copyright agent via email at support@esobond.com and include in Your notice a detailed description of the alleged infringement.',
        'You may be held accountable for damages (including costs and attorneys fees) for misrepresenting that any Content is infringing Your copyright.'
    ])


    const navbarStyle = {
        backgroundColor: 'white',
        padding: '20px',
        display: 'flex',
        alignItems: 'center'
    };

    const logoStyle = {
        marginRight: 'auto',
        marginLeft: '30px'
    };
    const leftColumnStyle = {
        padding: '20px'
    };

    return (
        <div>
            <Navbar style={navbarStyle} expand="md">
                <Navbar.Brand style={logoStyle} href="/user/home">
                    <Image src={logo} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse
                    id="basic-navbar-nav"
                    className="justify-content-center"
                >
                    <div
                        style={{
                            marginLeft: 'auto',
                            marginRight: '10%'
                        }}
                    >
                        <Nav>
                        </Nav>
                    </div>
                </Navbar.Collapse>
            </Navbar>

            <Container
                fluid
                style={{
                    backgroundColor: 'white'
                }}
            >
                <Row>
                    <Col md={11} style={leftColumnStyle}>
                        <div
                            style={{
                                marginLeft: '30px'
                            }}
                        >
                            <p
                                style={{
                                    background:
                                        'var(--gradient, linear-gradient(118deg, #BA59FB 6.93%, #7CB3F1 86.63%))',
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    fontFamily: 'sans-serif',
                                    fontSize: '16px',
                                    fontWeight: '600'
                                }}
                            >
                                WELCOME TO ESOBOND
                            </p>

                            <h1
                                style={{
                                    marginTop: '20px',
                                    fontFamily: ' sans-serif',
                                    fontSize: '48px',
                                    fontWeight: '700'
                                }}
                            >
                                Terms and Conditions
                            </h1>
                            <h5>
                                Last updated: June 04, 2023
                            </h5>
                            <h5>
                                Please read these Terms and Conditions carefully before using Our Service.
                            </h5>
                            <h5>
                                Interpretation and Definitions
                            </h5>
                            <h3 style={{
                                marginTop: '20px',

                            }}>
                                Interpretation
                            </h3>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                            </p>
                            <h3 style={{
                                marginTop: '10px',

                            }}>
                                Definitions
                            </h3>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                For the purposes of these Terms and Conditions:
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Application means the software program provided by the Company downloaded by You on any electronic device, named Esobond
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Application Store means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Account means a unique account created for You to access our Service or parts of our Service.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Country refers to: Georgia, United States
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Esobond, LLC, P.O. Box 94871 .
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Content refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Feedback means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Free Trial refers to a limited period of time that may be free when purchasing a Subscription.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                In-app Purchase refers to the purchase of a product, item, service or Subscription made through the Application and subject to these Terms and Conditions and/or the Application Store's own terms and conditions.              </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Promotions refer to contests, sweepstakes or other promotions offered through the Service.              </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Service refers to the Application or the Website or both.              </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Subscriptions refer to the services or access to the Service offered on a subscription basis by the Company to You.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Terms and Conditions (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Third-party Social Media Service means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Website refers to Esobond, accessible from www.esobond.com
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                            </p>
                            <h3 style={{
                                marginTop: '10px',

                            }}>
                                Acknowledgment
                            </h3>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                By creating a Esobond account or by using any Esobond service, you agree to be bound by (i) these Terms and Conditions, (ii) our Privacy Policy, Cookie Policy, Community Guidelines, and Intellectual Property, each of which is incorporated by reference into this Agreement, and (iii) any terms disclosed to you if you purchase or have purchased additional features, products or services we offer on the Service. If you do not accept and agree to be bound by all of the terms of this Agreement, you should not access or use the Service. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                We may make changes to this Agreement and to the Service from time to time. We may do this for a variety of reasons including to reflect changes in or requirements of the law, new features, or changes in business practices. The most recent version is the version that applies.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                You agree that this Agreement shall supersede any prior agreements (except as specifically stated herein), and shall govern your entire relationship with Esobond, including but not limited to events, agreements, and conduct preceding your acceptance of this Agreement.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Subscriptions
                            </p>
                            <h3 style={{
                                marginTop: '10px',

                            }}>
                                Eligibility
                            </h3>

                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                By signing up to use Esobond, you represent and warrant that:
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                · You are not an individual who is barred from using the Service under the laws of the United States or any other applicable jurisdiction.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                · You will comply with the Terms and Conditions mentioned in this Agreement and all applicable state, national, and international laws and regulations.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                · You have not been convicted of, or pled no contest to a sex crime or felony involving violence or a threat of violence against another individual.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                · You are not registered as a sex offender with any state, federal, or local sex offender registry.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                · You do not have more than one account on the Service.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                · You have not been previously removed from the Service by Esobond, unless You have written permission from a member of Esobond to create a new account.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                If at any time you do not meet these requirements, all authorization to access our Service or systems is automatically revoked, and your Esobond account must immediately be deleted.
                            </p>
                            <h3 style={{
                                marginTop: '10px',

                            }}>
                                Subscription period
                            </h3>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                The Service or some parts of the Service are available only with a paid Subscription. You will be billed in advance on a recurring and periodic basis (such as monthly or annually), depending on the type of Subscription plan you select when purchasing the Subscription.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                At the end of each period, Your Subscription will automatically renew under the exact same conditions unless You cancel it or the Company cancels it.
                            </p>
                            <h3 style={{
                                marginTop: '10px',

                            }}>
                                Subscription cancellations
                            </h3>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                You may cancel Your Subscription renewal either through Your Account settings page or by contacting the Company. You will not receive a refund for the fees You already paid for Your current Subscription period and You will be able to access the Service until the end of Your current Subscription period.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                If the Subscription has been made through an In-app Purchase, You can cancel the renewal of Your Subscription with the Application Store.
                            </p>
                            <h3 style={{
                                marginTop: '10px',

                            }}>
                                Billing
                            </h3>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                You shall provide the Company with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Should automatic billing fail to occur for any reason, the Company will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                If the Subscription has been made through an In-app Purchase, all billing is handled by the Application Store and is governed by the Application Store's own terms and conditions.
                            </p>
                            <h3 style={{
                                marginTop: '10px',

                            }}>
                                Fee Changes
                            </h3>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                The Company, in its sole discretion and at any time, may modify the Subscription fees. Any Subscription fee change will become effective at the end of the then-current Subscription period.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                The Company will provide You with reasonable prior notice of any change in Subscription fees to give You an opportunity to terminate Your Subscription before such change becomes effective.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Your continued use of the Service after the Subscription fee change comes into effect constitutes Your agreement to pay the modified Subscription fee amount.
                            </p>
                            <h3 style={{
                                marginTop: '10px',

                            }}>
                                Refunds
                            </h3>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Except when required by law, paid Subscription fees are non-refundable.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Certain refund requests for Subscriptions may be considered by the Company on a case-by-case basis and granted at the sole discretion of the Company.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                If the Subscription has been made through an In-app purchase, the Application Store's refund policy will apply. If You wish to request a refund, You may do so by contacting the Application Store directly.
                            </p>
                            <h3 style={{
                                marginTop: '10px',

                            }}>
                                Free Trial
                            </h3>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                The Company may, at its sole discretion, offer a Subscription with a Free Trial for a limited period of time.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                You may be required to enter Your billing information in order to sign up for the Free Trial.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                If You do enter Your billing information when signing up for a Free Trial, You will not be charged by the Company until the Free Trial has expired. On the last day of the Free Trial period, unless You cancelled Your Subscription, You will be automatically charged the applicable Subscription fees for the type of Subscription You have selected.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                At any time and without notice, the Company reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free Trial offer.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                In-app Purchases
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                The Application may include In-app Purchases that allow you to buy products, services or Subscriptions.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                More information about how you may be able to manage In-app Purchases using your Device may be set out in the Application Store's own terms and conditions or in your Device's Help settings.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                In-app Purchases can only be consumed within the Application. If you make a In-app Purchase, that In-app Purchase cannot be cancelled after you have initiated its download. In-app Purchases cannot be redeemed for cash or other consideration or otherwise transferred.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                If any In-app Purchase is not successfully downloaded or does not work once it has been successfully downloaded, we will, after becoming aware of the fault or being notified to the fault by You, investigate the reason for the fault. We will act reasonably in deciding whether to provide You with a replacement In-app Purchase or issue You with a patch to repair the fault. In no event will We charge You to replace or repair the In-app Purchase. In the unlikely event that we are unable to replace or repair the relevant In-app Purchase or are unable to do so within a reasonable period of time and without significant inconvenience to You, We will authorize the Application Store to refund You an amount up to the cost of the relevant In-app Purchase. Alternatively, if You wish to request a refund, You may do so by contacting the Application Store directly.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                You acknowledge and agree that all billing and transaction processes are handled by the Application Store from where you downloaded the Application and are governed by that Application Store's own terms and conditions.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                If you have any payment related issues with In-app Purchases, then you need to contact the Application Store directly.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Promotions
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Any Promotions made available through the Service may be governed by rules that are separate from these Terms.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                If You participate in any Promotions, please review the applicable rules as well as our Privacy policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                You agree to receive email and other forms of marketing to the methods of communication provided to Esobond.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                User Accounts
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                In order to use Esobond, you may sign in using a number of ways including by telephone number, email, or Apple login.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                You agree to treat other users in a courteous and respectful manner, both on and off our Services and to be respectful when communicating with any of our customer service representatives or other affiliates. Though Esobond strives to encourage a respectful member experience, Esobond is not responsible for the conduct of any member on or off of the Service.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                You agree to express caution in all interactions with other members, particularly if you decide to communicate off the Service or meet in person. We strongly advise meeting individuals the first time in a public setting. In addition, you agree to review and follow Esobond’s Safety Rules prior to using the Service. You agree that you will not provide your financial information or wire or otherwise send money to other members.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                YOU ARE SOLELY RESPONSIBLE FOR YOUR PERSONAL NTERACTIONS WITH OTHER MEMBERS. YOU UNDERSTAND THAT ESOBOND DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON MEMBERS OR OTHERWISE INQUIRE INTO THE BACKGROUND OF ITS MEMBERS. WHILE ESOBOND MAKES RECOMMENDATIONS FOR COMPATIBILITY, ESOBOND MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF MEMBERS.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                ESOBOND RESERVES THE RIGHT TO CONDUCT – AND YOU AUTHORIZE ESOBOND TO CONDUCT – ANY CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTER SEARCHES) AT ANY TIME USING AVAILABLE PUBLIC RECORDS OBTAINED BY IT OR WITH THE ASSISTANCE OF A CONSUMER REPORTING AGENCY, AND YOU AGREE THAT ANY INFORMATION YOU PROVIDE MAY BE USED FOR THAT PURPOSE.
                            </p>
                            <p
                                style={{
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    color: '#9E9E9E',
                                    fontFamily: ' sans-serif',
                                    fontWeight: '400'
                                }}
                            >
                                Content
                            </p>
                            <h3 style={{
                                marginTop: '10px',

                            }}>
                                Your Right to Post Content
                            </h3>
                            {rightPost.map((item, index) => (
                                <p
                                    style={{
                                        marginTop: '10px',
                                        fontSize: '16px',
                                        color: '#9E9E9E',
                                        fontFamily: ' sans-serif',
                                        fontWeight: '400'
                                    }}
                                >
                                    {item}
                                </p>
                            ))}
                            <h3 style={{
                                marginTop: '10px'
                            }}>
                                Content Restrictions
                            </h3>
                            {ContentRestrictions.map((item, index) => (
                                <p
                                    style={{
                                        marginTop: '10px',
                                        fontSize: '16px',
                                        color: '#9E9E9E',
                                        fontFamily: ' sans-serif',
                                        fontWeight: '400'
                                    }}
                                >
                                    {item}
                                </p>
                            ))}
                            <h3 style={{
                                marginTop: '10px',

                            }}>
                     Content Backups
                            </h3>
                            {ContentBackups.map((item, index) => (
                                <p
                                    style={{
                                        marginTop: '10px',
                                        fontSize: '16px',
                                        color: '#9E9E9E',
                                        fontFamily: ' sans-serif',
                                        fontWeight: '400'
                                    }}
                                >
                                    {item}
                                </p>
                            ))}
                            <h3 style={{
                                marginTop: '10px',

                            }}>
                   Intellectual Property Infringement
                            </h3>
                            {IntellectualProperty.map((item, index) => (
                                <p
                                    style={{
                                        marginTop: '10px',
                                        fontSize: '16px',
                                        color: '#9E9E9E',
                                        fontFamily: ' sans-serif',
                                        fontWeight: '400'
                                    }}
                                >
                                    {item}
                                </p>
                            ))}
                            
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default TermsNCondition;
