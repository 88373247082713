import {getHeader, getPutHeader} from '../utils/Utils';
const BASE_URL = process.env.REACT_APP_DEV_SERVER;

const ApiFetch = {
  logoutFromApp: async function () {
    console.log('logout from app')
  },
  /// For Api get use this
  fetchGet: function (url) {
    let headers = getHeader();
    return (
      fetch(BASE_URL + url, {
        method: 'GET',
        headers: headers,
      })
        .then(response =>
          response.status == 401 ? ApiFetch.logoutFromApp() : response.json(),
        )
        .then(responseJson => {
          return responseJson;
        })
        .catch(error => {
          console.log('error=>>>', error);
        })
    );
  },

  // for Api Post use this
fetchPost: function (url, body) {
  
    let headers = getHeader();
    console.log(
      'url ==>>',
      BASE_URL + url,
      ' body==',
      body,
      'headers=>>>',
      headers,
    );
    return (
      fetch(BASE_URL + url, {
        method: 'POST',
        headers: headers,
        body: body? JSON.stringify(body): '',
      })
        .then(response =>
          response.status === 401 ? response.json() : response.json(),
        )
        .then(responseJson => {
          console.log(responseJson);
          return responseJson;
        })
        .catch(error => {
          return error;
        })
    );
  },

  fetchPatch: function (url, body) {
    let headers = getHeader();
    console.log(
      'url ==>>',
      BASE_URL + url,
      '\nbody==',
      body,
      '\nheaders=>>>',
      headers,
    );
    return (
      fetch(BASE_URL + url, {
        method: 'PATCH',
        headers: headers,
        body: body,
      })
        .then(response =>
          response.status == 401 ? console.log('err Found') : response.json(),
        )
        .then(responseJson => {
          console.log("PATCH Respons =>", responseJson);
          return responseJson;
        })
        .catch(error => {
          console.log('Error', error);
        })
    );
  },

  fetchPut: function (url, body) {
    let headers = getPutHeader();
    console.log(
      'url ==>>',
      BASE_URL + url,
      ' body==',
      body,
      'headers=>>>',
      headers,
    );
    return fetch(BASE_URL + url, {
      method: 'PUT',
      headers: headers,
      body: body,
    })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        console.log('Error', error);
      });
  },

  futch: function (url, opts, onProgress) {
    console.log(BASE_URL + url, opts);
    const urlToCall = BASE_URL + url;
    return new Promise((res, rej) => {
      var xhr = new XMLHttpRequest();
      xhr.open(opts.method || 'get', urlToCall);
      for (var k in opts.headers || {})
        xhr.setRequestHeader(k, opts.headers[k]);
      xhr.onload = e => res(e.target);
      xhr.onerror = rej;
      if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
      xhr.send(opts.body);
    });
  },

  fetchImagePost: function (url, body) {
    let headers = getPutHeader();
    console.log(
      'url ==>>',
      BASE_URL + url,
      ' body==',
      body,
      'headers=>>>',
      headers,
    );
    return fetch(BASE_URL + url, {
      method: 'POST',
      headers: headers,
      body: body,
    })
      .then(response => response.json())
      .then(responseJson => {
        return responseJson;
      })
      .catch(error => {
        console.log('Error', error);
      });
  },

  fetchDelete: function (url) {
    let headers = getHeader();
    console.log('url ==>>', BASE_URL + url, 'headers=>>>', headers);
    return (
      fetch(BASE_URL + url, {
        method: 'DELETE',
        headers: headers,
      })
        .then(response =>
          response.status == 401 ? ApiFetch.logoutFromApp() : response.json(),
        )
        .then(responseJson => {
          return responseJson;
        })
        .catch(error => {
          console.log('error=>>>', error);
        })
    );
  },
  fetchDeleteWithBody: function (url, body) {
    console.log(body)
    let headers = getHeader();
    console.log(
      'url ==>>',
      BASE_URL + url,
      ' body==',
      body,
      'headers=>>>',
      headers,
    );
    return (
      fetch(BASE_URL + url, {
        method: 'DELETE',
        headers: headers,
        body: body,
      })
        .then(response =>
          response.status == 401 ? console.log('err Found') : response.json(),
        )
        .then(responseJson => {
          console.log(responseJson);
          return responseJson;
        })
        .catch(error => {
          console.log('Error', error);
        })
    );
  },
};

export default ApiFetch;
