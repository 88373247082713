import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import invite from 'assets/img/icons/spot-illustrations/41.png';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import InvitePeopleModal from './InvitePeopleModal';
import { hitPostInviteFriend } from '../../../../redux/actions/ProfileActions';
import { toast } from 'react-toastify';

const InvitePeopleScreen = props => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailregex, setEmailRegex] = useState(
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/
  );

  const handleFieldChange = e => {
    setEmail(e.target.value);
  };

  const sendInviteFriend = () => {
    const emailValid = emailregex.test(email);
    if (email === '') {
      toast.error('Please Enter Email');
    } else if (!emailValid) {
      toast.error('Enter a correct email address.');
    } else {
      setLoading(true);
      props
        .hitPostInviteFriend(email)
        .then(response => {
          setLoading(false);
          if (response.code === 200) {
            setEmail('');
            toast.success('Invite Shared Sucessfully');
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Card>
        <Card.Body className="text-center py-5">
          <Row className="justify-content-center">
            <Col xs={7} md={5}>
              <img className="img-fluid" src={invite} alt="image" width="58%" />
            </Col>
          </Row>
          <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
            Invite a friend.
          </h3>
          <p>
            Invite a friend or family member to join Esobond. Help us build a
            community of people who value intentionality and leading with
            purpose. You could change someone’s future.
          </p>
          <Row className="justify-content-center">
            <Col md={7}>
              <Form as={Row} className="g-2">
                <Col sm>
                  <Form.Control
                    type="email"
                    placeholder="Email address"
                    aria-label="Recipient's username"
                    value={email}
                    name="email"
                    onChange={handleFieldChange}
                  />
                </Col>
                <Col sm="auto">
                  <Button
                    variant="primary"
                    className="d-block w-100"
                    onClick={sendInviteFriend}
                  >
                    Share
                  </Button>
                </Col>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <InvitePeopleModal show={showModal} setShow={setShowModal} />
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitPostInviteFriend
    },
    dispatch
  );
}
let InvitePeople = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitePeopleScreen);

export default InvitePeople;
