import ApiFetch from '../../apimanager/ApiFetch';
import {
  DELETE_ACCOUNT,
  DELETE_PICTURE,
  FETCHUSERDETAIL_ENDPOINT,
  GET_UPLOAD_IMAGE,
  POST_UPLOAD_IMAGE,
  PUT_USERLOCATION_ENDPOINT,
  SUBSCRIPTION_TYPE,
  UPDATE_PROFILE,
  POST_FCMTOKEN,
  GET_USER_UPLOADED_IMAGE,
  SEND_INVITE_FRIEND,
  LIST_POST,
  SEND_NOTIFICATION_FOR_CHAT
} from '../../apimanager/ApiEndpoints';
import { SAVE_USERINFO } from '../ActionType';

export const hitGetPostFeed = id => dispatch => {
  let url = '';
  if (id) {
    url = LIST_POST + `?UserId=${id}`;
  } else {
    url = LIST_POST;
  }

  return ApiFetch.fetchGet(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitUserDetailAPI Action', err);
    });
};

export const hitPostLike = (postId, isLiked) => dispatch => {
  const param = {
    IsLiked: isLiked,
    PostId: postId
  };
  return ApiFetch.fetchPost('/user/post-like', JSON.stringify(param))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitUserDetailAPI Action', err);
    });
};

export const hitPostcomment = (postId, comment) => dispatch => {
  const param = {
    Comment: comment,
    PostId: postId
  };
  return ApiFetch.fetchPost('/user/post-comment', JSON.stringify(param))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitUserDetailAPI Action', err);
    });
};

export const userAccount = (userId) => dispatch => {
  return ApiFetch.fetchGet(`/user/post-comment${userId}`,)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitUserDetailAPI Action', err);
    });
};

export const hitGetPostcommentList = (postId) => dispatch => {
  return ApiFetch.fetchGet(`/user/post-comment-list?PostId=${postId}`)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitUserDetailAPI Action', err);
    });
};

export const hitCreatePost = (description, image) => dispatch => {
  const parameters = {
    Description: description,
    Image: image
  };

  console.log(parameters, 'llppllppllpp');
  return ApiFetch.fetchPost('/user/post', parameters)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitUserDetailAPI Action', err);
    });
};

export const hitUserDetailAPI = () => dispatch => {
  return ApiFetch.fetchGet(FETCHUSERDETAIL_ENDPOINT)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitUserDetailAPI Action', err);
    });
};
export const hitPostPicturAPI = base64 => dispatch => {
  const param = {
    Pictures: base64
  };
  return ApiFetch.fetchPost(POST_UPLOAD_IMAGE, JSON.stringify(param))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitPostPicturAPI Action', err);
    });
};

export const hitPostInviteFriend = email => dispatch => {
  const param = {
    Email: email
  };
  return ApiFetch.fetchPost(SEND_INVITE_FRIEND, JSON.stringify(param))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitPostPicturAPI Action', err);
    });
};

export const hitPostFcmTokenAPI = (fcmToken, platformOS) => dispatch => {
  const param = {
    FcmToken: fcmToken,
    DeviceType: platformOS
  };
  return ApiFetch.fetchPatch(POST_FCMTOKEN, JSON.stringify(param))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitPostFcmTokenAPI Action', err);
    });
};

export const hitGetPicturesAPI = () => dispatch => {
  return ApiFetch.fetchGet(GET_UPLOAD_IMAGE)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitGetPicturesAPI Action', err);
    });
};

export const hitLocationAPI = (longitude, latitude, loc) => dispatch => {
  const parameters = {
    LocationCoordinates: [longitude, latitude],
    State: loc
  };
  return ApiFetch.fetchPut(
    PUT_USERLOCATION_ENDPOINT,
    JSON.stringify(parameters)
  )
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror putHitLocationAPI Action', err);
    });
};
export const hitUpdateProfile = editData => dispatch => {
  let parameters = '';
  if (editData.phoneNumber === 'null') {
    parameters = {
      First_Name: editData.firstName,
      Last_Name: editData.lastName,
      ProfilePic: editData.picURL,
      DateOfBirth: editData.dateOfBirth
    };
  } else {
    parameters = {
      First_Name: editData.firstName,
      Last_Name: editData.lastName,
      ProfilePic: editData.picURL,
      DateOfBirth: editData.dateOfBirth,
      CountryCode: editData.countryCode,
      PhoneNumber: editData.phoneNumber
    };
  }
  // const parameters = {
  //   First_Name: editData.firstName,
  //   Last_Name: editData.lastName,
  //   Height: editData.height,
  //   ProfilePic: editData.picURL,
  //   DateOfBirth: editData.dateOfBirth,
  //   CountryCode: editData.countryCode,
  //   PhoneNumber: editData.phoneNumber
  // };
  return ApiFetch.fetchPut(UPDATE_PROFILE, JSON.stringify(parameters))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('errror hitUpdateProfile Action', err);
    });
};

export const hitDeleteAccount = () => dispatch => {
  const parameters = {
    IsActive: 0
  };
  return ApiFetch.fetchPatch(DELETE_ACCOUNT, JSON.stringify(parameters))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitDeleteAccountAPI Action', err);
    });
};

export const hitDeletePicture = url => dispatch => {
  const parameters = {
    Picture: url
  };
  return ApiFetch.fetchDeleteWithBody(
    DELETE_PICTURE,
    JSON.stringify(parameters)
  )
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitDeletePicture Action', err);
    });
};

export const getUserDetails = id => dispatch => {
  return ApiFetch.fetchGet(FETCHUSERDETAIL_ENDPOINT + `?UserId=${id}`)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error getUserDetails Action', err);
    });
};

export const sendNotificationForChat = (NotificationTitle, NotificationMessage, UserId, DeviceType) => dispatch => {
  const parameters = {
    NotificationTitle: NotificationTitle,
    NotificationMessage : NotificationMessage,
    UserId: UserId,
    DeviceType: DeviceType
  };
  
  return ApiFetch.fetchPost(SEND_NOTIFICATION_FOR_CHAT, JSON.stringify(parameters))
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error Send Notification Action', err);
    });
};

export const saveUserInfo = value => {
  return {
    type: SAVE_USERINFO,
    payload: value
  };
};

export const subscriptionType = () => dispatch => {
  return ApiFetch.fetchGet(SUBSCRIPTION_TYPE)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error getUserDetails Action', err);
    });
};

export const hitGetUserPicturesAPI = id => dispatch => {
  return ApiFetch.fetchGet(GET_USER_UPLOADED_IMAGE + `?UserId=${id}`)
    .then(response => {
      return response;
    })
    .catch(err => {
      console.log('error hitGetPicturesAPI Action', err);
    });
};
