import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  hitSendForgotPassword,
} from "../../redux/actions/LoginActions";
import { useNavigate } from 'react-router-dom';

const ForgetPasswordScreen = (props) => {
  const navigate = useNavigate();
  // State
  const [email, setEmail] = useState('');

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    if (email) {
      localStorage.setItem('esoUserEmail', email);
      props
      .hitSendForgotPassword(email)
      .then((data) => {
        if (!data.error && data.code === 200) {
          toast.success(data.message, {
            theme: 'colored'
          });
          navigate('/verify-otp');
            }
        if (data.error || (data.statusCode === 400 || data.statusCode === 404 || data.statusCode === 403)) {
              toast.error(data.message);
                }
            else {
              toast.error(data.error);
            }
      })
      .catch((error) => {
        console.log(error);
    })
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          Send reset OTP
        </Button>
      </Form.Group>

      {/* <Link className="fs--1 text-600" to="#!">
        I can't recover my account using this page
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link> */}
    </Form>
  );
};

// ForgetPasswordForm.propTypes = {
//   layout: PropTypes.string
// };

// ForgetPasswordForm.defaultProps = { layout: 'simple' };

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitSendForgotPassword,
    },
    dispatch,
  );
}
let ForgetPasswordForm = connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordScreen);

export default ForgetPasswordForm;
