import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hitSendOTP } from '../../../../redux/actions/LoginActions';
import {
  hitUpdateProfile,
  hitUserDetailAPI
} from '../../../../redux/actions/ProfileActions';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { db } from 'utils/firebase-config';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { Constant } from 'utils/Constant';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const ProfileEdit = props => {
  const [editData, setEditData] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  // const [countryCode, setCountryCode] = useState('');
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    // height: '',
    dateOfBirth: '',
    email: '',
    phoneNumber: '',
    picURL: '',
    countryCode:''
  });

  let startdate = moment();
  startdate = startdate.subtract(18, 'years');
  startdate = startdate.format('YYYY-MM-DD');

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    props.hitUserDetailAPI().then(data => {
      if (!data.error && data.code === 200) {
        const date = new Date(Number(data.data.DateOfBirth));
        const day = date.getDate().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        });
        const month = (date.getMonth() + 1).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        });
        const year = date.getFullYear();

        const formattedDate = `${year}-${month}-${day}`;
        data.data.DateOfBirth = formattedDate;

        setFormData({
          firstName: data.data.First_Name,
          lastName: data.data.Last_Name,
          // height: data.data.Height,
          dateOfBirth: formattedDate,
          email: data.data.Email,
          phoneNumber: data.data.PhoneNumber,
          countryCode:data.data.CountryCode
        });
        setPhoneNumber(data.data.PhoneNumber);
        // setCountryCode(data.data.CountryCode);
        setUserId(data.data.UserId);
      } else {
        toast.error(data.error);
      }
    });
  };

  const handleClick = () => {
    setEditData(!editData);
  };

  const handleChange = e => {
    const currentYear = new Date().getFullYear();
    const year = e.target.value.split('-')[0];
    const age = currentYear - year;
    if (age < 18) {
      toast.error('Age must be greater than 18 Years');
      return;
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // const handlePhoneChange = e => {
  //   console.log(e)
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value
  //   });
  // };
  const handlePhoneChange = (value, data) => {
    setFormData({
      ...formData,
      phoneNumber: value,
      countryCode: '+' + data.dialCode
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    // formData.countryCode = countryCode;
    // if (phoneNumber != formData.phoneNumber) {
    //   props
    //     .hitSendOTP(userId,  formData.countryCode + formData.phoneNumber, 2)
    //     .then(data => {
    //       if (data.code === 200) {
    //         updateUserDetails(props, 'Please verify your number');
    //         navigate('/verify-otp');
    //       }
    //     })
    //     .catch(error => {
    //       toast.error(error);
    //     });
    // } else {
      if (!editData) updateUserDetails(props, 'Profile updated successfullly');
    // }
  };
  

  const addToFirebase = async data => {
    await setDoc(
      doc(collection(db, Constant.USERS), data.UserId + ''),
      {
        username: data.Name,
        profilePic: data.ProfilePic,
        id: data.UserId
      },
      { merge: true }
    )
      .then(() => {
        console.log('Document successfully written!');
      })
      .catch(error => {
        console.error('Error writing document: ', error);
      });
  };

  const imageChange = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');
      setFormData({
        ...formData,
        [e.target.name]:
          e.target.name === 'picURL' ? base64String : e.target.value
      });
    };
  };

  const updateUserDetails = (props, messages) => {
    props
      .hitUpdateProfile(formData)
      .then(data => {
        if (data.code === 200 && messages == 'Please verify your number') {
          toast.info(messages);
        } else if (
          data.code === 200 &&
          messages == 'Profile updated successfullly'
        ) {
          toast.success(messages);
          window.location.reload();
          addToFirebase(data.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Card>
      <FalconCardHeader title="Edit Feed" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="firstName">
              <Form.Label>First Name</Form.Label>
              {!editData && (
                <p className="mb-0 text-dark">{formData.firstName}</p>
              )}
              {editData && (
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={formData.firstName}
                  name="firstName"
                  onChange={handleChange}
                  required
                />
              )}
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              {!editData && (
                <p className="mb-0 text-dark">{formData.lastName}</p>
              )}
              {editData && (
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={formData.lastName}
                  name="lastName"
                  onChange={handleChange}
                  required
                />
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            {/* <Form.Group as={Col} lg={6} controlId="height">
              <Form.Label>Height (in Feet)</Form.Label>
              {!editData && <p className="mb-0 text-dark">{formData.height}</p>}
              {editData && (
                <Form.Control
                  type="number"
                  placeholder="Height"
                  value={formData.height}
                  name="height"
                  onChange={handleChange}
                  required
                />
              )}
            </Form.Group> */}
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label>Email</Form.Label>
              {!editData && <p className="mb-0 text-dark">{formData.email}</p>}
              {editData && (
                <Form.Control
                  type="email"
                  placeholder="Email"
                  disabled
                  value={formData.email}
                  name="email"
                  required
                />
              )}
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="dateOfBirth">
              <Form.Label>Date Of Birth</Form.Label>
              {!editData && (
                <p className="mb-0 text-dark">{formData.dateOfBirth}</p>
              )}
              {editData && (
                <Form.Control
                  type="date"
                  placeholder="Date Of Birth"
                  value={formData.dateOfBirth}
                  name="dateOfBirth"
                  max={startdate}
                  onChange={handleChange}
                  required
                />
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="phoneNumber">
              <Form.Label>Phone</Form.Label>
              {!editData && (
                <p className="mb-0 text-dark">{"+"+formData.phoneNumber}</p>
              )}
              {editData && (
            
                <PhoneInput
                   placeholder="Phone"
                  country={formData.countryCode}
                  value={formData.phoneNumber}
                  onChange={handlePhoneChange}
                  required
                />
              )}
            </Form.Group>
          </Row>

          {/* <Form.Control
                  type="number"
                  placeholder="Phone"
                  value={formData.phoneNumber}
                  name="phoneNumber"
                  onChange={handlePhoneChange}
                  required
                /> */}

          {editData ? (
            <Row>
              <Form.Group controlId="picURL" className="mb-3">
                <Form.Label>Edit Image</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  name="picURL"
                  onChange={imageChange}
                />
              </Form.Group>
            </Row>
          ) : null}

          <div className="text-end">
            <Button variant="primary" type="submit" onClick={handleClick}>
              {editData ? 'Update' : 'Edit'}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitUpdateProfile,
      hitUserDetailAPI,
      hitSendOTP
    },
    dispatch
  );
}
let ProfileSettings = connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);

export default ProfileSettings;
