import React from 'react';
import { Card } from 'react-bootstrap';

const RefreshError = () => (
  <Card className="text-center h-600">
    <Card.Body className="p-5">
      {/* <div className="display-1 text-300 fs-error">500</div> */}
      <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
      Your community refreshes daily. Remember, it’s about quality, not quantity!
      </p>
      <hr />
      <p>
        click here to check the plans and buy the subsciption to use the functionality,
        <a href="/pricing/pricing-default" className="ms-1">
          Buy Now
        </a>
        .
      </p>
    </Card.Body>
  </Card>
);

export default RefreshError;
