import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import { toast } from 'react-toastify';
import { hitUserDetailAPI } from 'redux/actions/ProfileActions';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const ProfileDropdownScreen = props => {
  const [imageUrl, setImageUrl] = useState('');
  const logout = () => {
    localStorage.removeItem('accessEsoToken');
    localStorage.setItem('userLoggedIn', false);
    localStorage.clear()
    toast.success('Logout successful!');
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    props
      .hitUserDetailAPI().then(data => {
        if (data.code === 200) {
          setImageUrl(data.data.ProfilePic);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={imageUrl} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/user/settings">
            Profile
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to="/authentication/card/logout"
            onClick={() => logout()}
          >
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitUserDetailAPI
    },
    dispatch
  );
}
let ProfileDropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDropdownScreen);

export default ProfileDropdown;
