import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import eyeSlash from '../../assets/logo/eye-slash-solid.svg';
import eye from '../../assets/logo/eye-solid.svg'
import {
  hitResetPassword,
} from "../../redux/actions/LoginActions";
import { useNavigate } from 'react-router-dom';

const PasswordResetScreen = (props) => {
  const navigate = useNavigate();
  // State
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [ShowConfirmPassword, setShowConfirmPassword] = useState(false)
  const [passRegex, setPassRegex] = useState(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,15}$/);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    const userLoggedIn = JSON.parse(localStorage.getItem('userLoggedIn'));
    const otpVerificationToken = localStorage.getItem('accessEsoToken');
    if (formData.password === formData.confirmPassword) {
      props
      .hitResetPassword(formData.password, otpVerificationToken)
      .then((data) => {
        if (!data.error && data.code === 200) {
          toast.success(data.message, {
            theme: 'colored'
          });
          if(userLoggedIn) {
            navigate('/user/home');
          } 
          if(!userLoggedIn) {
            navigate('/login');
          }
            }
        if (data.error) {
              toast.error(data.message);
                }
            else {
              toast.error(data.error);
            }
      })
      .catch((error) => {
        console.log(error);
    })
    }
    else 
    toast.error('Both password and confirm password should be same');
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!ShowConfirmPassword);
  };
  return (    
    <Form
      className={classNames('mt-3', { 'text-left': props.hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3" style={{display:'flex'}}>
        {props.hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!props.hasLabel ? 'New Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type={showPassword ? 'text' : 'password'
        }
        />
         <img
          onClick={handleTogglePassword}
          src={showPassword ? eye : eyeSlash}
          style={{ width: '20px', height: '20px', margin: '6px 6px'}}
        />
      </Form.Group>
      

      <Form.Group className="mb-3" style={{display:'flex'}}>
        {props.hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!props.hasLabel ? 'Confirm Password' : ''}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type={ShowConfirmPassword ? 'text' : 'password'}
        />
         <img
          onClick={handleToggleConfirmPassword}
          src={ShowConfirmPassword ? eye : eyeSlash}
          style={{ width: '20px', height: '20px', margin: '6px 6px'}}
        />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={!formData.password || !formData.confirmPassword}
      >
        Set password
      </Button>
    </Form>
  );
};

// PasswordResetForm.propTypes = {
//   hasLabel: PropTypes.bool
// };

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitResetPassword,
    },
    dispatch,
  );
}
let PasswordResetForm = connect(mapStateToProps, mapDispatchToProps)(PasswordResetScreen);
export default PasswordResetForm;
