import React from 'react';
import { useNavigate } from 'react-router-dom';
import PasswordResetForm from 'components/authentication/PasswordResetForm';
import arrow from '../../../assets/logo/arrow-left-solid.svg';

const PasswordReset = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Equivalent to history.goBack()
  };

  return (
    <div>
      <div style={{ margin: '-20px', paddingBottom: '40px' , cursor:'pointer'}} onClick={goBack}>
        <img src={arrow} style={{ width: '20px', height: '20px' }} alt="Go Back" /> Go Back
      </div>
      <div className="text-center">
        <h5>Reset New Password</h5>
        <PasswordResetForm />
      </div>
    </div>
  );
};

export default PasswordReset;
