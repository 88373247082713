import React from 'react';
import PropTypes from 'prop-types';
import AuthWizardProvider from './AuthWizardProvider';
import UnAnsweredWizardLayoutForm from './UnAnsweredQuestionaire';

const UnAnsweredWizard = ({ variant, validation, progressBar }) => {
  return (
    <AuthWizardProvider>
      <UnAnsweredWizardLayoutForm
        variant={variant}
        validation={validation}
        progressBar={progressBar}
      />
    </AuthWizardProvider>
  );
};

UnAnsweredWizard.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

export default UnAnsweredWizard;
