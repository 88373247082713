import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

export default function TextForm(props) {
  const [answer, setAnswer] = useState('');
  useEffect(() => {
    callProps();
  }, [answer]);
  const handleChange = e => {
    setAnswer(e.target.value);
  };

  const callProps = () => {
    console.log(answer, 'rea reals');
    props.addQuestion(props.data.QuestionId, answer, props.style);
  };

  console.log(answer, 'answers');

  return (
    <Form>
      <Form.Group className="mb-3" controlId="formGroupGender">
        <Form.Label>Q. {props.data.Description}</Form.Label>
        <Form.Control
          type="text"
          placeholder="Example - 25 "
          value={answer}
          name="age"
          onChange={handleChange}
          required
        />
      </Form.Group>
    </Form>
  );
}
