import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { overAllCompatibilityReport } from 'redux/actions/AssessmentActions';
import { Doughnut, Pie } from 'react-chartjs-2';
import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';

const overallResult = props => {
  const backgroundColor = ['#F67DE5', '#7DE5F6', '#fff383', '#D8E2EB', 'orange'];
  const labels =  ['Goal', 'Lifestyle', 'Future','Business','Blue']; 
  const [loader, setLoader] = useState(false);
  const [pieData, setPieData] = useState({datasets: [{data: [],backgroundColor: [] }], labels: []} );
//   const [data, setData] = useState({
//     datasets: [{
//         data: [10, 20, 30],
//         backgroundColor:['red','blue','yellow']
//     },
//   ],
//   labels: [
//       'Red',
//       'Yellow',
//       'Blue'
//   ], 
// });
  useEffect(() => {
    getOverallData(props.details.UserId);
  }, [])

  const options = {
    tooltip: chartJsDefaultTooltip(),
    rotation: -90,
    circumference: '180',
    cutout: '80%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: chartJsDefaultTooltip()
    }
  };

  const getOverallData = (loggedInUserId) => {
    // setPieData([]);
    props.overAllCompatibilityReport(loggedInUserId)
      .then(response => {
        if (response?.code === 200) {
          const dataObj = {
            datasets: [{data: response.data.slice(),backgroundColor: backgroundColor.slice(0, response.data.length)},],
            labels: labels.slice(0, response.data.length),
          };
          setPieData(dataObj);
        }
      })
    setLoader(false);
  }



  return (
    <Modal
      show={true}
      onHide={props.onClose}
      centered
      scrollable={true}
      style={{
        // position: 'absolute',
        // transform: 'translateX(-50%)',
        // maxWidth: '90%',
        // padding: '1rem',
        // maxHeight: '65%',
        // top: '15%',
        // left: '60%'
      }}
    >
      <Modal.Header
        style={{ 
          // position: 'sticky', top: '0', zIndex: '1' 
        }}

        closeButton
      >
          </Modal.Header>
         <Modal.Title className="w-100 text-center" >
          Overall Result
         
          <Modal.Body>
          <Doughnut data={pieData}  width="112px" style={{}} />
          </Modal.Body>
          </Modal.Title>
    
    </Modal>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
        overAllCompatibilityReport
    },
    dispatch
  );
}
let OverallResultModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(overallResult);

export default OverallResultModal;

