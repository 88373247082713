import React, { useContext, useState, useEffect } from 'react';
import { Card, Form, Nav, Spinner, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import AppContext, { AuthWizardContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import RadioForm from './RadioForm';
import MultiSelectForm from './MultiSelectForm';
import SingleSelectForm from './SingleSelectForm';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  updateQuestionaireAnswers,
  getQuestionaireById
} from '../../redux/actions/QuestionaireActions';
import { toast } from 'react-toastify';
import Success from './Success';
import TextForm from './TextForm';
import { useParams } from 'react-router-dom';
import AuthWizardProvider from './AuthWizardProvider';

const ReAnswerLayoutScreen = props => {
  const { questionId } = useParams();

  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    getQuestionaireById(questionId);
  }, []);

  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const {
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors
  } = useForm();

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [quizResponses, setQuizResponses] = useState([]);

  const [map, setMap] = useState(new Map());

  const [isNextDisable, setIsNextDisbale] = useState(true);

  const arr = [];

  useEffect(() => {
    checkStep();
  }, [quizResponses]);

  const filterAnswerId = answerIds => {
    answerIds.forEach(val => {
      arr.push(val.AnswerId);
    });

    return arr;
  };

  const addQuestion = (questionId, answerIds, style) => {
    if (style === 4) {
      answerIds = filterAnswerId(answerIds);
    }

    setIsNextDisbale(false);

    setMap([questionId, answerIds, style]);
  };

  const getQuestionaireById = async questionId => {
    setLoading(true);
    props.getQuestionaireById(questionId).then(data => {
      if (!data.error && data.code === 200) {
        setLoading(false);
        setQuestions([data.data]);
      } else {
        toast.error(data.error);
      }
    });
  };

  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const updateQuizResponse = (questionId, updatedResponse) => {
    let i = 0;
    quizResponses.forEach((obj, index) => {
      if (
        obj.QuestionId === questionId &&
        obj.AnswerId !== updatedResponse.AnswerId
      ) {
        i = 1;
        quizResponses[index] = updatedResponse;
      }
    });
    if (i === 0) {
      setQuizResponses(prevArray => [...prevArray, updatedResponse]);
    }
  };

  const handleClick = () => {
    let objectResponse;

    if (typeof map[1] === 'string' && map[2] === 5) {
      objectResponse = {
        QuestionId: map[0],
        OtherOptions: map[1]
      };
    } else if (typeof map[1] === 'string') {
      objectResponse = {
        QuestionId: map[0],
        AnswerId: [+map[1]]
      };
    } else {
      objectResponse = {
        QuestionId: map[0],
        AnswerId: map[1]
      };
    }
    if (quizResponses.length === 0) {
      setQuizResponses([objectResponse]);
    } else {
      updateQuizResponse(objectResponse.QuestionId, objectResponse);
    }

    setIsNextDisbale(!isNextDisable);
  };

  const checkStep = data => {
    if (step === questions.length) {
      onSubmitData();
      setStep(step + 1);
    } else if (questions.length) {
      setStep(step + 1);
    }
  };

  const onSubmitData = data => {
    props
      .updateQuestionaireAnswers(
        quizResponses[0].QuestionId,
        quizResponses[0].AnswerId
      )
      .then(data => {
        if (data.code === 200) {
        }
      })
      .catch(error => {
        toast.error(error);
      });

    setUser({ ...user, ...data });
    setModal(true);
  };

  console.log(questionId);
  return (
    <>
      <AuthWizardProvider>
        <Card
          as={Form}
          noValidate
          onSubmit={handleSubmit(onSubmitData, onError)}
          className="theme-wizard mb-5"
        >
          <Card.Body
            style={{
              width: '400px',
              height: '400px'
            }}
          >
            {loading && (
              <span style={{ display: 'block', textAlign: 'center' }}>
                <Spinner
                  animation="border"
                  role="status"
                  className="flex-center"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </span>
            )}

            {!loading &&
              !modal &&
              questions &&
              questions.map((data, index) => (
                <div key={index}>
                  {step === index + 1 && data.Style === 5 && (
                    <TextForm
                      data={data}
                      addQuestion={addQuestion}
                      style={data.Style}
                    />
                  )}
                </div>
              ))}

            {!loading &&
              !modal &&
              questions &&
              questions.map((data, index) => (
                <div key={index}>
                  {step === index + 1 && data.Style === 3 && (
                    <SingleSelectForm
                      data={data}
                      addQuestion={addQuestion}
                      style={data.Style}
                    />
                  )}
                </div>
              ))}

            {!loading &&
              !modal &&
              questions &&
              questions.map((data, index) => (
                <div key={index}>
                  {step === index + 1 && data.Style === 2 && (
                    <RadioForm
                      data={data}
                      addQuestion={addQuestion}
                      style={data.Style}
                    />
                  )}
                </div>
              ))}

            {!loading &&
              !modal &&
              questions &&
              questions.map((data, index) => (
                <div key={index}>
                  {step === index + 1 && data.Style === 1 && (
                    <RadioForm
                      data={data}
                      addQuestion={addQuestion}
                      style={data.Style}
                    />
                  )}
                </div>
              ))}

            {!loading &&
              !modal &&
              questions &&
              questions.map((data, index) => (
                <div key={index}>
                  {step === index + 1 && data.Style === 4 && (
                    <MultiSelectForm
                      data={data}
                      addQuestion={addQuestion}
                      style={data.Style}
                    />
                  )}
                </div>
              ))}

            {step === questions.length + 1 && modal && (
              <Success reset={reset} route={`/social/re-assessment`} />
            )}
          </Card.Body>

          {!loading && (
            <Card.Footer
              className={classNames('px-md-6 bg-light', {
                'd-none': step === questions.length + 1,
                ' d-flex': step < questions.length
              })}
            >
              <IconButton
                variant="primary"
                className="ms-auto px-5"
                type="submit"
                icon={isRTL ? 'chevron-left' : 'chevron-right'}
                iconAlign="right"
                transform="down-1 shrink-4"
                onClick={handleClick}
                disabled={isNextDisable}
              >
                {step === questions.length ? 'Submit' : 'Next'}
              </IconButton>
            </Card.Footer>
          )}
        </Card>
      </AuthWizardProvider>
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getQuestionaireById,
      updateQuestionaireAnswers
    },
    dispatch
  );
}

let ReAnswerForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReAnswerLayoutScreen);

export default ReAnswerForm;
