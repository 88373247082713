/**
 * Endpoints
 * example
 * LOGIN_ENDPOINT = "user/login";
 */
export const REGISTERUSER_ENDPOINT = '/user/register';
export const LOGIN_ENDPOINT = '/user/login';
export const SUPPORT_ENDPOINT='/user/ask-support'
export const FORGOT_PASSWORD_ENDPOINT = '/user/password/forgot';
export const RESET_PASSWORD_ENDPOINT = '/user/password/reset';
export const CHANGE_PASSWORD = '/user/changePassword';
export const PUT_USERLOCATION_ENDPOINT = '/user/location';
export const VERIFY_OTP_ENDPOINT = '/user/verify-otp';
export const SEND_OTP_ENDPOINT = '/user/send-otp';
export const PROFILESETUP_ENDPOINT = '/user/profile-setup';
export const FETCHUSERDETAIL_ENDPOINT = '/user/details';
export const SEND_NOTIFICATION_FOR_CHAT = '/user/send-notification'
export const POST_GOOGLEDATA_ENDPOINT = '/user/social-login/google';
export const POST_FACEBOOK_ENDPOINT = '/user/social-login/fb';
export const POST_APPLEDATA_ENDPOINT = '/user/social-login/apple';
export const DELETE_ACCOUNT = '/user/deleteUser';
export const CONTACT_US = '/user/contactUs';
export const USER_FEEDBACK = '/user/userFeedback';
export const REPORT_USER = '/user/reportUser';
export const TNC_AND_PRIVACY_POLICY = '/user/staticContent';
export const CONTACT_US_OPTIONS = '/user/contactUsReasonList';
export const QUESTIONAIRE = '/user/questionnaire';
export const POST_UPLOAD_IMAGE = '/user/upload_pictures';
export const POST_FCMTOKEN = '/user/update-fcmToken';
export const QUESTIONAIRE_POST = '/user/questionnaire/response';
export const UPDATE_PROFILE = '/user/update-profile';
export const GET_UPLOAD_IMAGE = '/user/view_pictures';
export const MATCHING_LIST = '/user/match-list';
export const ASSESSMENT_LISTING = '/user/assessment-listing';
export const ASSESSMENT_QUESTION_USER_LISTING =
  '/user/assessment-question-user-listing';
export const FAQS = '/user/faqList';
export const COMPATIBILITY_REPORT = '/user/compatibility-report';
export const DELETE_PICTURE = '/user/delete_picture';
export const QUESTIONAIRE_BY_QUESTION_ID = '/user/assessment';
export const UPDATE_QUESTIONAIRE_ANSWERES = '/user/question-response';
export const LIKE_UNLIKE_PROFILE = '/user/profileLike';
export const UNANSWERED_QUESTIONS = '/user/unanswered-questions';
export const SUBSCRIPTION_IOS = '/user/subscription/apple';
export const SUBSCRIPTION_ANDROID = '/user/subscription/google';
export const OVERALL_COMPATIBILITY_REPORT =
  '/user/compatibility-overall-report';
export const NOTIFICATION_STATUS = '/user/notification-status';
export const NOTIFICATION_LISTING = '/user/notification-listing';
export const SUBSCRIPTION_TYPE = '/user/subscription-type';
export const CLIENT_TOKEN="/user/clientToken";
export const SEND_PAYMENT_DATA="/user/chargebee-purchase"
export const GET_USER_UPLOADED_IMAGE = '/user/post-list';
export const NOTIFICATION_SEEN_STATUS = '/user/notification-seen-status';
export const CANCEL_SUBSCRIPTION="/user/chargebee-cancel-plan"
export const SEND_WAITLIST_FORM="/user/waitlist-form"
export const SEND_INVITE_FRIEND='/user/invite'
export const LIST_POST = '/user/post-list';
