import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import { isIterableArray } from 'helpers/utils';

import FalconCardHeader from 'components/common/FalconCardHeader';
import Notification from 'components/notification/Notification';
import {
  getNotifications,
  notificationSeenStatus
} from 'redux/actions/SidebarActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
const NotificationDropdownScreen = props => {
  const [newNotifications, setNewNotifications] = useState([]);
  const [earlierNotifications, setEarlierNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchNotification();
  }, [pageNo]);

  const fetchNotification = () => {
    setEarlierNotifications([]);
    setNewNotifications([]);
    props
      .getNotifications(limit, pageNo)
      .then(data => {
        if (!data.error && data.code === 200) {
          for (let i = 0; i < data.data.result.length; i++) {
            const date = new Date(Number(data.data.result[i].CreatedOn));
            const day = date.getDate().toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            });
            const month = (date.getMonth() + 1).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false
            });
            const year = date.getFullYear();
            const hours = date.getHours();
            const minutes = date.getMinutes();

            const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
            if (data.data.result[i].IsActive === 1) {
              setNewNotifications(prevArray => [
                ...prevArray,
                {
                  Id: data.data.result[i].Id,
                  Message: data.data.result[i].Message,
                  ProfilePic: data.data.result[i].ProfilePic,
                  IsActive: data.data.result[i].IsActive,
                  CreatedOn: formattedDate
                }
              ]);
            } else {
              setEarlierNotifications(prevArray => [
                ...prevArray,
                {
                  Id: data.data.result[i].Id,
                  Message: data.data.result[i].Message,
                  ProfilePic: data.data.result[i].ProfilePic,
                  IsActive: data.data.result[i].IsActive,
                  CreatedOn: formattedDate
                }
              ]);
            }
          }
          setTotalPages(Math.ceil(Number(data.data.count.count) / limit));
        } else {
          toast.error(data.error);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const chanegStatus = id => {
    props
      .notificationSeenStatus(id)
      .then(data => {
        if (!data.error && data.code === 200) {
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleStatus = id => {
    navigate('/app/chat');
    setIsOpen(!isOpen);
    chanegStatus(id);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  const handlePageChange = selectedPage => {
    setPageNo(selectedPage.selected + 1);
  };

  const markAsRead = e => {
    e.preventDefault();
    setIsAllRead(true);
  };

  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [notificationData, setNotificationData] = useState([]);

  
  useEffect(() => {
    notificationsData()
  }, [])

  const notificationsData = () => {
    props.getNotifications(limit, pageNo)
      .then(response => {
        if (response?.code === 200) {
          setNotificationData(response?.data);
        }
        // setLoader(false);
      })
  }

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 nav-link')}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
            endEl={
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={markAsRead}
              >
                {/* Mark all as read */}
              </Link>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '19rem' }}
          >
            {/* <div className="list-group-title">NEW</div>{' '} */}
           {newNotifications.length!==0? <div>
            {isIterableArray(newNotifications) &&
              newNotifications.map(notification => (
                <ListGroup.Item
                  key={notification.Id}
                  // onClick={() => handleStatus(notification.Id)}
                >
                  <Notification
                    id={notification.Id}
                    avatar={notification.ProfilePic}
                    children={notification.Message}
                    time={notification.CreatedOn}
                    unread={true}
                    flush
                  />
                </ListGroup.Item>
              ))}
              </div>:
              <div className="list-group-title">No Notification Found</div>}
            {/* <div className="list-group-title">EARLIER</div> */}
            {isIterableArray(earlierNotifications) &&
              earlierNotifications.map(notification => (
                <ListGroup.Item
                  key={notification.Id}
                  onClick={() => handleStatus(notification.Id)}
                >
                  <Notification
                    id={notification.Id}
                    avatar={notification.ProfilePic}
                    children={notification.Message}
                    time={notification.CreatedOn}
                    unread={false}
                    flush
                  />
                </ListGroup.Item>
              ))}
          </ListGroup>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <ReactPaginate
              pageCount={totalPages} // Total number of pages
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
              breakClassName="page-item disabled"
              breakLinkClassName="page-link"
              style={{
                display: 'flex',
                listStyle: 'none',
                padding: 0,
                margin: 0
              }}
            />
            <style>
              {`
          .page-link {
            font-size: 12px;
            padding: 5px 10px;
          }
        `}
            </style>
          </div> */}
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getNotifications,
      notificationSeenStatus
    },
    dispatch
  );
}

let NotificationDropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDropdownScreen);

export default NotificationDropdown;
