import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import React from 'react';
import { Col, Form, FormCheck, Row } from 'react-bootstrap';

const PricingDefaultHeader = () => {
  return (
    <Col xs={12} className="mb-3">
      <Row className="align-items-center justify-content-center justify-content-sm-between">
        <Col sm="auto" className="text-center">
          <h5 className="d-inline-block">Select Subscription</h5>
        </Col>
      </Row>
    </Col>
  );
};

export default PricingDefaultHeader;
