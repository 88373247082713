import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  hitVerifyOtpAPI,
  hitSendSignupData
} from 'redux/actions/SignupActions';
import { hitSendOTP } from 'redux/actions/LoginActions';
import { useNavigate } from 'react-router-dom';
import AuthCardLayout from 'layouts/AuthCardLayout';
import FalconCardHeader from 'components/common/FalconCardHeader';
import './YourForm.css';

const VerifyOtpScreen = props => {
  const navigate = useNavigate();
  // State
  const [otp, setOtp] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPass, setUserPass] = useState('');

  const handleResentOtp = () => {
    const userIdEmail = localStorage.getItem('esoUserEmail');
    const userId = localStorage.getItem('UserId');
    const phoneNumber = localStorage.getItem('userPhone');
    props.hitSendOTP(userId, userIdEmail, 1).then(response => {
      if (response.code === 200) {
        console.log('OtpSent');
      }
    });
  };

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    if (otp) {
      let email = localStorage.getItem('esoUserEmail');
      props
        .hitVerifyOtpAPI('', email, otp, 1)
        .then(data => {
          if (!data.error && data.code === 200) {
            localStorage.setItem('otpVerificationCode', data.data.Token);
            localStorage.setItem('accessEsoToken', data.data.Token);
            toast.success(data.message, {
              theme: 'colored'
            });
            let processStage = localStorage.getItem('esoProcess');
            if (processStage === 'registration') {
              navigate('/authentication/create-user');
            } else {
              navigate('/authentication/card/reset-password');
            }
          }
          if (
            data.error ||
            data.statusCode === 400 ||
            data.statusCode === 404 ||
            data.statusCode === 403
          ) {
            toast.error(data.message);
          } else {
            toast.error(data.error);
          }
        })
        .catch(error => {
          toast.error(error);
        });
    }
  };

  return (
    <AuthCardLayout>
      <Form className="mt-4" onSubmit={handleSubmit}>
        <FalconCardHeader title="Verify OTP" />
        <Form.Group className="mb-3">
          <Form.Control
            placeholder={'Enter the OTP'}
            value={otp}
            min="0000"
            max="9999"
            name="otp"
            onChange={({ target }) => setOtp(target.value)}
            type="number"
          />
        </Form.Group>
        <Form.Group className="w-100">
          <a
            style={{ cursor: 'pointer', textDecoration: 'none' }}
            className="resendOtpButton"
            onClick={handleResentOtp}
          >
            Resend OTP
          </a>
        </Form.Group>
        <Form.Group className="mb-3 mt-2">
          <Button className="w-100" type="submit" disabled={!otp}>
            Verify OTP
          </Button>
        </Form.Group>
      </Form>
    </AuthCardLayout>
  );
};

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hitVerifyOtpAPI,
      hitSendSignupData,
      hitSendOTP
    },
    dispatch
  );
}
let VerifyOtpForm = connect(mapStateToProps, mapDispatchToProps)(
  VerifyOtpScreen,
  hitSendSignupData
);

export default VerifyOtpForm;
